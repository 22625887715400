import Timer from "./DelayTimer";
import { cjs } from "./lib/createjs-extended";

/**
 * The MusicPlayer is designed to control the looping and playing of background music in a game
 * Play a single music file in a loop on repeat
 * Calling play with a different filename will fade out the current instance and fade in the the new
 * Shuffle option available if you have an Array of Music Files to play
 */
class MusicPlayer {
    /**
     * Constructor
     * @param {Number} volume - volume to play the sound instances at defaults to 1
     */
    constructor(volume = 1) {
        this._volume = volume;
        this._relativeVolume = 1;
        this._playlist = [];
        this._instance = null;
        this._instanceName = '';
        this._lastInstanceName = '';
        this._fadeTime = 500;
        this._timer = new Timer(this.shufflePlaylist.bind(this));
    }

    /**
     * Shuffle the music between a list of songs provided
     * @param {Array} audio
     */
    shuffle(audio) {
        // If we are passed an array and it has more then one UID
        if (audio instanceof Array && audio.length > 1) {
            // Store the playlist for later
            this._playlist = audio;

            // Start stuffle
            this.shufflePlaylist();
        } else {
            this.play(audio);
        }
    }

    /**
     * Shuffle the current playlist stored on this MusicPlayer
     */
    shufflePlaylist() {
        // Fade out the old instance if we have one
        if (this._instance) {
            // Save the previous instance
            let previousInstance = this._instance;

            // Fade out previous instance
            let tween = this.fadeOutMusic(previousInstance, this._fadeTime);

            // Call stop on the last instance
            tween.call(previousInstance.stop, null, previousInstance);
        }

        // Get a random name
        let name;

        // Get a random name that is different then the previous played sound
        let i = 0;
        do {
            name = this.random(this._playlist);
            i++;
        } while (i < 10 && name === this._lastInstanceName);

        // Store our instanceName
        this._instanceName = name;

        // Save the name for next shuffle
        this._lastInstanceName = name;

        // Start new instance at volume 0
        this._instance = cjs.Sound.play(name, { volume: 0 });

        // Make sure our timer is cleared
        this._timer.stop();

        // Get the duration of the song minus our fade in/out time
        this._timer.play(Math.ceil(this._instance.duration - this._fadeTime));

        // Fade in the new music to our volume level
        this.fadeInMusic(this._instance, this._fadeTime);
    }

    /**
     * Return a random position in the Array provided
     * @param {Array} array
     */
    random(array) {
        return array[Math.floor(Math.random() * array.length)];
    }

    /**
     * Stop the current instance and play the new audio instance
     * @param {String} audio - audio string UID name
     */
    play(audio, loop) {
        // Fade out the old instance if we have one
        if (this._instance) {
            // Save the previous instance
            let previousInstance = this._instance;

            // Fade out previous instance
            let tween = this.fadeOutMusic(previousInstance, this._fadeTime);

            // Call stop on the last instance
            tween.call(previousInstance.stop, null, previousInstance);
        }

        // Store the instance name
        this._instanceName = audio;

        // Clear out any Timeouts
        this._timer.stop();
        // Start new instance at volume 0
        if(loop){
            this._instance = cjs.Sound.play(audio, { volume: 0, loop: -1 });
        } else {
            this._instance = cjs.Sound.play(audio, { volume: 0});
        }
        

        // Fade in the new music to our volume level
        this.fadeInMusic(this._instance, this._fadeTime);

        // Return the new instance
        return this._instance;
    }

    /**
     * Pause the current Music instance
     */
    pause() {
        // Pause our music instance
        if (this._instance) {
            this._instance.paused = true;
        }

        // Pause timer
        this._timer.pause();
    }

    /**
     * Resume the current Music instance
     */
    resume() {
        // Resume our music instance
        if (this._instance) {
            this._instance.paused = false;
        }

        // Resume timer
        this._timer.resume();
    }

    /**
     * Stop the currently playing instance
     */
    stop() {
        // Stop our music instance
        if (this._instance) {
            this._instance.stop();
        }

        // Stop the timer
        this._timer.stop();
    }

    /**
     * Set the main volume for this and the current instance playing
     * @param {Number} volume - volume to set
     */
    set volume(volume) {
        this._volume = volume;
        if (this._instance) {
            this._instance.volume = volume * this._relativeVolume;
        }
    }

    /**
     * Return the current main volume level set on the VOPlayer
     */
    get volume() {
        return this._volume;
    }

    /**
     * Set the relative volume for this and the current instance playing
     * @param {Number} volume - volume to set
     */
    set relativeVolume(volume) {
        this._relativeVolume = volume;
        if (this._instance) {
            this._instance.volume = volume * this._relativeVolume;
        }
    }

    /**
     * Return the current relative volume level set on the VOPlayer
     */
    get relativeVolume() {
        return this._relativeVolume;
    }

    /**
     * Return the current instance of Music playing
     */
    get instance() {
        return this._instance;
    }

    /**
     * Return the current UID for the music of the Music playing
     */
    get instanceName() {
        return this._instanceName;
    }

    /**
     * Set the fade time between songs
     */
    set fadeTime(fadeTime) {
        this._fadeTime = fadeTime;
    }

    /**
     * Get the fade time between songs
     */
    get fadeTime() {
        return this._fadeTime;
    }

    /**
     * Fade out the sound to a volume of 0
     * @param {Number} time - milliseconds
     * @returns Tween
     */
    fadeOutMusic(instance, time) {
        return cjs.Tween.get(instance)
            .to({ volume: 0 }, time);
    }

    /**
     * Fade in the sound to a volume of the volume for this player
     * @param {Number} time - milliseconds
     * @returns Tween
     */
    fadeInMusic(instance, time) {
        return cjs.Tween.get(instance)
            .to({ volume: this._volume }, time);
    }

    /**
     * Fades relative volume to given value over given time
     * @param {Number} volume - relative volume factor
     * @param {Number} time - milliseconds
     * @returns Tween
     */
    fadeRelativeVolume(volume, time) {
        return cjs.Tween.get(this)
            .to({ relativeVolume: volume }, time);
    }
}

export default MusicPlayer;