/**
 * Public url
 *
 * @returns {string}
 */
export function publicUrl() {
  return process.env.PUBLIC_URL;
}

export function throw404() {
  throw new Response("Not Found", {status: 404});
}

export function locationClasses(path) {
  const segments = path.split('/').filter((v) => v.length);

  return segments.reduce((a, segment) => {
    a.push(`${[...a, ...[segment]].join('--')}`)
    return a;
  }, [])
      .map((segment) => `path--${segment}`).join(' ');
}

export function isAbsolute(url) {
  return !!url.match(/^https:\/\/|http:\/\//);
}

export function pageTitle(segments = []) {
  let title = 'Welcome';

  if (Array.isArray(segments) && segments.length) {
    title = segments.join(' | ');
  } else if (typeof segments === 'string' && segments.length) {
    title = segments;
  }

  document.title = title + " | Elmer's Squishtopia";
}

export const ideaUrl = 'https://www.elmers.com/blogs?fdid=Squishies';