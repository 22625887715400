import React from 'react';
import { motion } from 'framer-motion';
import styles from "./CanvasInstructions.module.scss";

import { ReactComponent as IconDragNavigate } from '../assets/components/CanvasInstructions/icon-drag-navigate.svg';
import { defaultMotionProps, modalMotionVariants } from '../utils/motion';

function CanvasInstructions() {
  return (
      <motion.div {...defaultMotionProps} variants={modalMotionVariants}  className={styles.root}>
        <div className={styles.shapeDrag}>
          <IconDragNavigate className={styles.icon} />
          <span className={styles.title}>
            Drag to Navigate
          </span>
        </div>
      </motion.div>
  )
}

export default CanvasInstructions;
