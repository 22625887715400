import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useLoaderData, useMatches, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import styles from './SquishieModal.module.scss'

import ModalBackground from '../components/ModalBackground';
import DefinitionPill from '../components/DefinitionPill';
import {
  formatSquishieBirthday,
  getBiomeByFamily,
  getFamilyById,
  isSquishieOwned, isSquishieRare
} from '../utils/collection';
import { useSite } from '../contexts/SiteContext';
import BaseCanvas from '../canvas/BaseCanvas';
import { publicUrl } from '../utils/path';
import { defaultMotionProps, modalMotionVariants } from '../utils/motion';
import { ReactComponent as IconClose } from '../assets/x.svg'
import classNames from 'classnames';
import { useWindowSize } from '@react-hook/window-size';
import { scaleContent } from '../utils/accessibility';
import SquishieCard from '../components/SquishieCard';
import { trackEvent } from '../utils/analytics';
import { playSound } from '../utils/sound';

function SquishieModal({ parentPath }) {
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const [squishie] = useState(loaderData ? loaderData.squishie : {});
  const matches = useMatches();
  const { collection } = useSite();
  const isOwned = isSquishieOwned(squishie, collection);
  const isRare = isSquishieRare(squishie);
  const [running, setRunning] = useState(false);
  const [reverse, setReverse] = useState(loaderData?.reverse ? loaderData.reverse : false);
  const [width, height] = useWindowSize();
  const modalBackgroundElement = useRef();
  const contentElement = useRef();

  useEffect(() => {
    playSound('site-card-open');
  }, []);

  const family = useMemo(() => {
    return getFamilyById(squishie.family);
  }, [squishie.family]);

  const biome = useMemo(() => {
    return getBiomeByFamily(family);
  }, [family]);

  const overrideParentPath = (parentPath) ? parentPath : matches[matches.length-2].pathname;
  const birthday = formatSquishieBirthday(squishie.birthday);

  useEffect(() => {
    trackEvent('squishie', squishie.name.toLowerCase(), (reverse) ? 'front' : 'back');
  }, [isOwned, squishie, reverse]);

  const handleClose = () => {
    navigate(overrideParentPath, { replace: true });
  };

  const handleFlipCard = async () => {
    if (running) return;

    setRunning(true);
    setReverse(!reverse);

    if (reverse) {
      playSound('site-card-flip-2');
    } else {
      playSound('site-card-flip-1');
    }
  };

  const handleTransitionEnd = () => {
    setRunning(false);
  };

  function closeElement(className = null) {
    return (
        <button className={classNames(styles.close, className)} type="button" onClick={handleClose} title="Close modal">
          <IconClose/>
        </button>
    );
  }

  function backsideTopElement() {
    return (
      <div className={styles.backSide__top}>
        <span>{squishie.score}</span>
        <img src={`${publicUrl()}/${biome.icon}`} alt={biome.name} className={styles.icon} />
      </div>
    );
  }

  // canvas references
  const canvasElement = useRef(null);
  const charCanvas = useRef(null);
  const didInitCanvas = useRef({ init: false });

  // initialize canvas drawing once
  useMemo(() => {
    if (isOwned) {
      charCanvas.current = new BaseCanvas(BaseCanvas.Types.CHAR, squishie.slug, false, "reveal");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // finish initializing canvas after initial setup
  useEffect(() => {
    if (charCanvas.current && canvasElement.current) {
      // get context
      const context = canvasElement.current.getContext("2d");
      // pass ref, context to drawing class
      charCanvas.current.setRef(canvasElement);
      charCanvas.current.setContext(context);
    }
    // cleanup
    return () => {
      if (charCanvas.current) {
        if (!didInitCanvas.current.init) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          didInitCanvas.current.init = true;
          // this has to be done this way to track when this "cleanup" function is called
        } else {
          charCanvas.current.destroy();
        }
      }
    }
  }, [])

  // flip update
  useEffect(() => {
    // determine canvas visibility by card flip
    if (charCanvas.current) {
      charCanvas.current.visibilityUpdate(reverse, false);
      if (reverse) {
        charCanvas.current.charPlayAnim("reveal");
      }
    }
  }, [reverse])

  useEffect(() => {
    scaleContent(modalBackgroundElement.current, contentElement.current);
  }, [width, height, modalBackgroundElement, contentElement]);

  return (
      <motion.div className={styles.root} variants={modalMotionVariants} {...defaultMotionProps}>
        <ModalBackground className={styles.background} ref={modalBackgroundElement} close={handleClose}>
          <div className={styles.card} ref={contentElement}>
            <div className={styles.squishie}>
              <motion.div
                className={styles.container}
                data-reverse={reverse}
                data-type="container"
                onClick={handleFlipCard}
                onTransitionEnd={handleTransitionEnd}
              >
                {/* "Data" side of card */}
                <div className={styles.bgShadow} />
                <div className={styles.frontSide} data-type="frontSide">
                  <div className={styles.frontSide__name}>{squishie.fullName}</div>
                  <div className={styles.frontSide__info}>
                    <div className={styles.frontSide__meta}>
                      <DefinitionPill label="Family" value={family.name} uppercase={true} relativeSize />
                      <DefinitionPill label="Habitat" value={biome.name} uppercase={true} relativeSize />
                      {birthday && <DefinitionPill label="Birthday" value={birthday} uppercase={true} relativeSize />}
                    </div>
                    <div className={styles.frontSide__visual} data-type={isOwned ? squishie.type : "locked"}>
                      <img src={`${publicUrl()}/${(isOwned) ? squishie.unlockedImage : squishie.lockedImage}`} alt={squishie.name} className={styles.frontSide__image}/>
                    </div>
                  </div>
                  <div className={styles.description}>
                    {squishie.description.map((p, i) => {
                      return (
                        <p key={i}>{p}</p>
                      )
                    })}
                  </div>
                </div>
                {/* "Animation" side of card */}
                <div className={styles.backSide} data-type="backSide" data-owned={isOwned} data-rare={isRare}>
                  {isOwned && (
                    <>
                      <SquishieCard theme="single" isOwned={true} squishie={squishie} image={false}>
                        {backsideTopElement()}
                        <canvas ref={canvasElement} className={styles.backSide__canvas} />
                      </SquishieCard>
                    </>
                  )}
                  {!isOwned && (
                      <SquishieCard isOwned={true} squishie={squishie} theme="single">
                        {backsideTopElement()}
                      </SquishieCard>
                  )}
                </div>
              </motion.div>
              <div className={styles.bottom}>
                <button className={styles.flip} onClick={handleFlipCard}>click/tap to flip</button>
              </div>
              {closeElement(styles['close--content'])}
            </div>
          </div>
          {closeElement(styles['close--window'])}
        </ModalBackground>
      </motion.div>
  )
}

SquishieModal.propTypes = {
  parentPath: PropTypes.string
};

SquishieModal.defaultProps = {
  parentPath: null
};

export default SquishieModal;