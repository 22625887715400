import PromptModal from './PromptModal';
import Button from './Button';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './LockedModal.module.scss';

function LockedModal({ close, message }) {

  return (
      <PromptModal
          title="Unlock this activity!"
          close={close}
          buttons={(
              <>
                <Button type="button" onClick={close} uppercase={true} className={styles.button}>Got it!</Button>
              </>
          )}
      >
        <p>{message}</p>
      </PromptModal>
  )
}

LockedModal.propTypes = {
  close: PropTypes.func.isRequired,
  message: PropTypes.string,
};

LockedModal.defaultProps = {
  message: "CREATE Elmer's Squishies through the CREATE page to unlock printable activities & games!"
};

export default LockedModal;