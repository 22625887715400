import { publicUrl } from './path';

export function loadSound(path, id, prefix = true) {
  const soundPath = (prefix === true) ? `${publicUrl()}/static/sounds/${path}` : path;

  if (window.createjs && window.createjs.Sound && window.createjs.Sound.registerSound) {
    window.createjs.Sound.registerSound(soundPath, id);
  }
}

export function playSound(id) {
  if (window.createjs && window.createjs.Sound && window.createjs.Sound.play) {
    window.createjs.Sound.play(id);
  }
}