import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

function ErrorPage() {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  // Ref is implemented to prevent infinite loops in rare development circumstances.
  // Issue should never exist in prod, but is annoying in development.
  // https://github.com/remix-run/react-router/issues/8733
  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  useEffect(() => {
    navigateRef.current("/");
  }, []);

  return null;
}

export default ErrorPage;