import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ActivityThumbnail.module.scss'
import { isAbsolute, publicUrl } from '../utils/path';
import { useSite } from '../contexts/SiteContext';
import {
  getActivityTypeNameFromActivityType,
  isActivityMethodFamily,
  isActivityMethodFree,
  isActivityMethodRare,
  isActivityUnlocked
} from '../utils/collection';
import DefinitionPill from './DefinitionPill';
import ConditionalWrapper from './ConditionalWrapper';
import React from 'react';
import { trackEvent } from '../utils/analytics';

function getPillThemeByActivityType(type) {
  if (type === 'game') {
    return 'blue';
  } else if (type === 'habitat-craft') {
    return 'blue';
  } else if (type === 'idea') {
    return 'blue';
  }

  return null;
}

function ActivityThumbnail({activity, className, unlockFamily, offsetRare, lockedMessage}) {
  const {collection, setActivityLocked} = useSite();
  let isUnlocked = false;

  if (isActivityMethodFamily(activity)) {
    isUnlocked = (unlockFamily) ? true : isActivityUnlocked(activity, collection);
  } else if (isActivityMethodRare(activity)) {
    isUnlocked = isActivityUnlocked(activity, collection, offsetRare);
  } else if (isActivityMethodFree(activity)) {
    isUnlocked = isActivityUnlocked(activity, collection);
  }

  const analyticsId = activity.name.replace(/[^a-zA-Z0-9]/, '').toLowerCase();

  const path = isAbsolute(activity.url) ? activity.url : `${publicUrl()}/${activity.url}`;

  function handleLockedClick() {
    setActivityLocked(lockedMessage);
  }

  function handleUnlockedClick() {
    trackEvent('activity', 'open', activity.name);
  }

  return (
      <div className={classNames(styles.root, className)} data-is-unlocked={isUnlocked}>
        <ConditionalWrapper
            condition={isUnlocked}
            wrapper={(children) => <a href={path} target="_blank" rel="noreferrer" id={`gtm-activity-${analyticsId}`} className={styles.link} onClick={handleUnlockedClick}>{children}</a>}
            elseWrapper={(children) => <button onClick={handleLockedClick} className={styles.link}>{children}</button>}
        >
          <div className={styles.image}>
            <div className={styles.image__wrapper}>
              <img src={`${publicUrl()}/${activity.image}`} alt={activity.name} className={styles.image__element} />
            </div>
            {!isUnlocked && <div className={styles.locked}/>}
          </div>
          <div className={styles.type}>
            <DefinitionPill
                value={getActivityTypeNameFromActivityType(activity.type)}
                theme={getPillThemeByActivityType(activity.type)}
            />
          </div>
          <div className={styles.title}>
            {activity.name}
          </div>
        </ConditionalWrapper>
        <div className={styles.description}>{activity.description}</div>
      </div>
  );
}

ActivityThumbnail.propTypes = {
  activity: PropTypes.object.isRequired,
  className: PropTypes.any,
  unlockFamily: PropTypes.bool,
  offsetRare: PropTypes.number,
  lockedMessage: PropTypes.number,
};

ActivityThumbnail.defaultProps = {
  className: null,
  unlockFamily: false,
  offsetRare: 0,
  lockedMessage: 1
};

export default React.memo(ActivityThumbnail);