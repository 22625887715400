import { cjs } from "./lib/createjs-extended";

/** wrapper class for cjs.Sound for SFX with master volume control */
export default class SFXPlayer {
    constructor() {
        this._masterVolume = 1;
        this._isMuted = false;
    }

    /** play clip with properties and get instance */
    play(clipName, props) {
        // set relative volume by master
        if (props) {
            // checking for volume property this way because 0 is a valid value for this
            if ("volume" in props) {
                props.volume *= this.getMasterVolume();
            } else {
                props.volume = this.getMasterVolume();
            }
        }
        // play clip
        return cjs.Sound.play(clipName, props);
    }

    /** get current global SFX volume, affected by mute state */
    getMasterVolume() {
        return (this.getIsMuted() ? 0 : this.getTrueMasterVolume());
    }

    /** get current global SFX volume outside of mute state */
    getTrueMasterVolume() {
        return this._masterVolume;
    }

    /** set global SFX volume */
    setMasterVolume(value) {
        this._masterVolume = Math.min(Math.max(0, value), 1);
    }

    /** get current override mute state */
    getIsMuted() {
        return this._isMuted;
    }

    /** set override mute state */
    setIsMuted(state) {
        this._isMuted = state;
    }
}