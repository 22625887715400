import React, { useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import styles from './BuildStages.module.scss';

import { useWindowSize } from '@react-hook/window-size';
import stages from '../configs/stages';
import classNames from 'classnames';

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 },
}

function BuildStages({ className, stageNumber }) {
  const mainRef = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!mainRef?.current) return;

    const maxHeight = Array
      .from(mainRef.current.children)
      .slice(1)
      .reduce((accum, current) => {
        return Math.max(accum, current.clientHeight);
      }, [0]);

    const offset = 60 + mainRef.current.firstChild.clientHeight;
    mainRef.current.style.height = `${maxHeight + offset}px`;
  }, [mainRef, windowSize]);

  return (
    <div ref={mainRef} className={classNames(styles.root, className)}>
      <div className={styles.topLabel}>CURRENT STAGE</div>
      <AnimatePresence>
        {stages.map((oneStage, idx) => {
          const key = `sub-item-${idx}-${stageNumber}`;
          const show = idx === stageNumber - 1;

          return (
            <motion.div
              {...motionProps}
              animate={{ opacity: show ? 1 : 0, visibility: show ? "visible" : "hidden" }}
              className={styles.subItem}
              key={key}
            >
              <div className={styles.title}>
                <span className={styles.stageNumber}>
                  {stageNumber}
                </span>
                <strong>
                  {oneStage.title}
                </strong>
              </div>
              <div className={styles.description}>
                <span>
                  {oneStage.description}
                </span>
              </div>
            </motion.div>
          )
        })}
      </AnimatePresence>
    </div>
  )
}

BuildStages.propTypes = {
  className: PropTypes.any,
  stageNumber: PropTypes.number.isRequired
};

BuildStages.defaultProps = {
  className: '',
};

export default React.memo(BuildStages);
