import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CreateBuildComplete.module.scss';
import CreateHeader from './CreateHeader';
import Button from './Button';
import { AnimatePresence, motion } from 'framer-motion';
import BaseCanvas from '../canvas/BaseCanvas';
import { trackEvent } from '../utils/analytics';

function CreateBuildComplete({open, reset, isOpen, squishie, family}) {
  const navigate = useNavigate();
  const canvasElement = useRef(null);
  const charCanvas = useRef(null);

  function handleOpen() {
    if (!isOpen) {
      open();

      trackEvent('create', 'open', squishie.slug);

      // start opening animation on canvas
      if (charCanvas.current) {
        charCanvas.current.boxOpen();
      }
    }
  }

  function handleSeeOnMap() {
    // run map tween on core map instance
    if (BaseCanvas.MapCanvasRef) {
      // bypass map load view if possible
      if (BaseCanvas.MapCanvasRef.showMap) {
        BaseCanvas.MapCanvasRef.showMap();
      }
      BaseCanvas.MapCanvasRef.mapTweenToChar(squishie.slug);
    }
    // tell React to go to map
    navigate('/');
  }

  useEffect(() => {
    trackEvent('create', 'complete');
  }, []);

  // finish initializing canvas after initial setup
  useEffect(() => {
    charCanvas.current = new BaseCanvas(BaseCanvas.Types.BOX, squishie.slug, false, "reveal");

    // get context
    const context = canvasElement.current.getContext("2d");
    // pass ref, context to drawing class
    charCanvas.current.setRef(canvasElement);
    charCanvas.current.setContext(context);
    // set visible
    charCanvas.current.visibilityUpdate(true, false);

    // cleanup
    return () => {
      try {
        charCanvas.current.destroy();
      } catch (e) {
        // Error silently
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // keep box open state synced
  useEffect(() => {
    if (charCanvas.current) {
      charCanvas.current.isBoxOpen = isOpen;
    }
  }, [isOpen])

  return (
      <div className={styles.root}>
        {!isOpen && <CreateHeader title="Tap the Mystery Mold to reveal your brand new Elmer's Squishie!" />}
        {isOpen && <CreateHeader title="Congratulations!">You’ve got a brand new lovable, smooshable Elmer's Squishie friend!</CreateHeader>}
        <div className={styles.reveal}>
          <div className={styles.reveal__container}>
            {!isOpen && <button onClick={handleOpen} className={styles.reveal__button} title="Reveal Squishie" id="gtm-create-complete-next"></button>}
            <AnimatePresence>
              {isOpen && <motion.div initial={{opacity: 0}} animate={{ opacity: 1}} className={styles.reveal__name}>{squishie.name}</motion.div>}
            </AnimatePresence>
            <canvas className={styles.reveal__canvas} ref={canvasElement}></canvas>
          </div>
        </div>
        {isOpen && (
            <div>
              <div className={styles.buttons}>
                <Button type="link" link={`/collect/${family.slug}`} icon="family" uppercase={true} id="gtm-create-open-family">
                  See my family
                </Button>
                <Button type="button" onClick={reset} icon="plus" uppercase={true} id="gtm-create-open-another">
                  Make another!
                </Button>
                <Button type="button" onClick={handleSeeOnMap} icon="map" uppercase={true} id="gtm-create-open-map">
                  See on the map
                </Button>
              </div>
            </div>
        )}
      </div>
  );
}

CreateBuildComplete.propTypes = {
  open: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  squishie: PropTypes.object.isRequired,
  family: PropTypes.object.isRequired
};

export default CreateBuildComplete;