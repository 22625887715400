import React, { useEffect } from "react";
import styles from './TimerConfirmationModal.module.scss';
import classNames from "classnames";
import PropTypes from 'prop-types';
import imageMix from '../assets/components/TimerConfirmationModal/image-mix.png';
import imagePour from '../assets/components/TimerConfirmationModal/image-pour.png';
import Button from './Button';
import { trackEvent } from '../utils/analytics';
import PromptModal from './PromptModal';

function TimerConfirmationModal({back, next, close}) {
  useEffect(() => {
    trackEvent('create', 'confirm');
  }, []);

  function handleNext(e) {
    next(e);
  }

  return (
      <PromptModal
          title="Have you completed these steps?"
          close={close}
          header={(
              <ul className={styles.topSection}>
                <li className={classNames(styles.blueBox, styles['blueBox--mix'])}>
                  <span>Mix</span>
                  <img alt="" src={imageMix}/>
                </li>
                <li className={classNames(styles.blueBox, styles['blueBox--pour'])}>
                  <span>Pour</span>
                  <img alt="" src={imagePour}/>
                </li>
              </ul>
          )}
          buttons={(
              <>
                <Button type="button" onClick={back} icon="back" uppercase={true}>
                  No<span className={styles.optionalButtonCopy}>, go back</span>
                </Button>
                <Button type="button" onClick={handleNext} icon="next" uppercase={true} id="gtm-create-confirm-next">
                  Yes<span className={styles.optionalButtonCopy}>, start timer</span>
                </Button>
              </>
          )}
          buttonsClassName={styles.buttons}
      >
        <p>Make sure you have followed all of the instructions in step 1 before starting the timer.</p>
      </PromptModal>
  );
}

TimerConfirmationModal.propTypes = {
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default TimerConfirmationModal;