/**
 * Get countdown
 *
 * @param {number} time
 * @param {number|null} now
 * @returns {number}
 */
export function getCountdown(time, now = null) {
  const countdown = Math.round((time) - (now || Date.now()));

  return (countdown > 0) ? countdown : 0;
}

/**
 * Get create total duration
 *
 * @return {number}
 */
export function getCreateTotalDuration() {
  return parseInt(process.env.REACT_APP_CREATE_DURATION);
}

/**
 * Convert countdown to human readable
 *
 * @param {number} time
 */
export function convertCountdownToHumanReadable(time) {
  let localHours = new Date(null);
  let timer = new Date(null);
  timer.setMilliseconds(time);
  let calcMinutes =
    (timer.getHours() - localHours.getHours()) * 60 +
    timer.getMinutes() -
    localHours.getMinutes();
  let getMinutes
  let getSeconds

  if(calcMinutes < 10) {
    getMinutes = `0${calcMinutes}`
  } else {
    getMinutes = calcMinutes
  }

  if(timer.getSeconds() < 10) {
    getSeconds = `0${timer.getSeconds()}`
  } else {
    getSeconds = timer.getSeconds()
  }
  return `${getMinutes}:${getSeconds}`;
}

/**
 * Get percent left from time left
 *
 * @param {number} timeLeft
 */
export function getPercentLeftFromTimeLeft(timeLeft) {
  const total = getCreateTotalDuration();
  return (total - timeLeft) / total;
}

/**
 * Get stage from time left
 *
 * @param {number} timeLeft
 * @return {number}
 */
export function getStageFromTimeLeft(timeLeft) {
  const percent = getPercentLeftFromTimeLeft(timeLeft);

  if (percent <= 0.2) {
    return 1;
  } else if (percent <= 0.4) {
    return 2;
  } else if (percent <= 0.6) {
    return 3;
  } else if (percent <= 0.8) {
    return 4;
  } else {
    return 5;
  }
}