import PropTypes from 'prop-types';

function ConditionalWrapper({ condition, wrapper, elseWrapper, children }) {
  return condition ? wrapper(children) : elseWrapper(children);
}

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrapper: PropTypes.func.isRequired,
  elseWrapper: PropTypes.func,
  children: PropTypes.any.isRequired
};

ConditionalWrapper.defaultProps = {
  elseWrapper: (children) => children
};

export default ConditionalWrapper;