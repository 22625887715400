import styles from './CreateHeader.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function CreateHeader({ title, children, className}) {
  return (
      <div className={classNames(className, styles.root)}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {children && <div className={styles.description}>{children}</div>}
      </div>
  )
}

CreateHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.any,
};

CreateHeader.defaultProps = {
  title: 'Create my Elmer\'s Squishie!',
  children: null,
  className: null
}

export default CreateHeader;