import styles from './ModalWindow.module.scss';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { PropTypes } from 'prop-types';
import { modalWindowMotionVariants } from '../utils/motion';
import { useRef } from 'react';

function ModalWindow({ className, children, grow, close }) {
  const props = grow ? modalWindowMotionVariants : {};
  const ref = useRef();

  function handleClick(e) {
    if (ref.current === e.target) {
      close();
    }
  }

  return (
      <motion.div className={classNames(styles.root, className)} {...props} onClick={handleClick} ref={ref}>
        {children}
      </motion.div>
  )
}

ModalWindow.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  grow: PropTypes.bool,
  close: PropTypes.func,
};

ModalWindow.defaultProps = {
  className: null,
  children: null,
  grow: false,
  close: () => {}
}

export default ModalWindow;