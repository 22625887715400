import React, { useEffect, useState } from 'react';
import styles from './GlobalCreateTimer.module.scss';
import classNames from 'classnames';
import { useSite } from '../contexts/SiteContext';
import {
  convertCountdownToHumanReadable,
  getCountdown,
  getCreateTotalDuration,
  getStageFromTimeLeft
} from '../utils/timer';
import { NavLink } from 'react-router-dom';
import GlobalCreateStages from './GlobalCreateStages';
import { ReactComponent as ReactLogoIconX } from '../assets/x.svg'
import { ReactComponent as IconCheck } from '../assets/components/GlobalCreateTimer/check.svg';
import mold from '../assets/components/GlobalCreateTimer/mold.png'
import iconBox from '../assets/components/GlobalCreateTimer/icon-box.png'
import PropTypes from 'prop-types';

function GlobalCreateTimer({sticky}) {
  const {queue, hideGlobalTimer} = useSite();
  const [countdown, setCountdown] = useState(getCreateTotalDuration());

  // Complete is handled by site context
  // However the actual countdown is handled by the component itself
  useEffect(() => {
    if (!queue.complete) {
      setCountdown(getCountdown(queue.time));

      const interval = setInterval(() => {
        setCountdown(getCountdown(queue.time));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [queue.time, queue.complete]);

  return (
      <div className={classNames(styles.root, sticky && styles['root--sticky'])}>
        {queue.complete && (
            <div className={classNames(styles.background, styles.timeDone)}>
              <button className={styles.close} onClick={hideGlobalTimer} title="Close timer">
                <ReactLogoIconX/>
              </button>
              <img src={mold} alt="Mystery mold" className={styles.mold}/>
              <NavLink to="/create" className={styles.button}>
                <IconCheck/>
                <span className={styles.button__text}>
                  See my new squishie!
                </span>
              </NavLink>
            </div>
        )}
        {!queue.complete && (
            <div className={styles.background}>
              <div className={styles.counter}>
                <img src={iconBox} alt="" className={styles.iconBox}/>
                <div className={styles.clock}>{convertCountdownToHumanReadable(countdown)}</div>
              </div>
              <div className={styles.barProgress}>
                <GlobalCreateStages stageNumber={getStageFromTimeLeft(countdown)}/>
              </div>
            </div>
        )}
      </div>
  );
}

GlobalCreateTimer.propTypes = {
  sticky: PropTypes.bool
};

GlobalCreateTimer.defaultProps = {
  sticky: true
};

export default GlobalCreateTimer;