import FlashAnimationLoader from "./FlashAnimationLoader";
import { publicUrl } from "../utils/path";
import { cjs } from "./lib/createjs-extended";

// @Chris: Place your assets in `/public/static/canvas`. You can then asynchronous load those files from there. Please
// prefix your asynchronous requests with `publicUrl()` from the `utils/path.js` file.

/** Uses CreateJS LoadQueue to in a file or manifest and stores it in a Cache object */
class AssetLoader extends cjs.LoadQueue {
	/**
	 * Constructor
	 * @param {Cache} cache - Cache class to store data once loaded
	 */
	constructor(cache, preferXHR = true) {
		super(preferXHR);

		this.cache = cache;

		// Install CreateJS Sound plugin
		this.installPlugin(cjs.Sound);

		// Register our Flash Loader
		this.registerLoader(FlashAnimationLoader);

		this.setMaxConnections(10);
	}

	/**
	 * Load a manifest and return a Promise when its completed
	 * @param {Array} manifest - Array manifest of Objects we want to load
	 * @returns Promise
	 */
	load(manifest) {
		// If our manifest is blank return an empty promise
		if (manifest.length === 0) {
			return Promise.resolve();
		}

		// Clone the manifest (saves a little memory and doesn't clutter the manifest directly)
		let clonedManifest = [];
		manifest.forEach(obj => {
			// If the sound is loaded don't try to load it again
			if (!cjs.Sound._idHash[obj.id]) {
				// copy object to modify URL path
				const objClone = {
					id: obj.id,
					src: publicUrl() + obj.src,
					type: obj.type
				};
				clonedManifest.push(objClone);
			}
		});

		// If our manifest is blank return an empty promise
		if (clonedManifest.length === 0) {
            return Promise.resolve();
        }

		// enable load callbacks
		this.setCallbacks();

		// Load the manifest
		this.loadManifest(clonedManifest);

		// Completed promise
		return new Promise((resolve) => {
			this.on("complete", resolve);
		});
	}

	/**
	 * Load single entry from manifest
	 * @param {Array} manifest - Array manifest
	 * @param {String} id - entry to load
	 * @returns Promise
	 */
	loadSingle(manifest, id) {
		// skip blank manifest
		if (manifest.length === 0) {
			return Promise.resolve();
		}
		// find in given manifest
		let found;
		for (let i = 0; i < manifest.length; ++i) {
			if (manifest[i].id === id) {
				found = manifest[i];
				break;
			}
		}
		// skip if not found
		if (!found) {
			return Promise.resolve();
		}
		// enable load callbacks
		this.setCallbacks();
		// load as single
		this.loadFile(found);
		// completed promise
		return new Promise((resolve) => {
			this.on("complete", resolve);
		});
	}

	/** enable load event callbacks */
	setCallbacks() {
		// handle single file loaded
		this.on("fileload", this.handleFileload, this);
		// handle all files loaded
		this.on("complete", this.handleLoadComplete, this);
	}

	/**
	 * Store the loading of individual files
	 * @param {Event} event
	 */
	handleFileload(event) {
		if (this.cache) {
			this.cache.add(event);
		}
	}

	/**
	 * Called from the completed event to clean up this loader
	 */
	handleLoadComplete(event) {
		let queue = event.currentTarget;
		// Clean up this loader
		queue.destroy();
		queue.removeAll();
	}
}

export default AssetLoader;