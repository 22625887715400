import React, { useEffect } from 'react';
import classNames from 'classnames';
import { motion, useAnimationControls } from "framer-motion";
import styles from './CircularCountdown.module.scss';
import PropTypes from 'prop-types';

import imageBoxOrange from '../assets/components/CircularCountDown/crate-no-number.png';
import { convertCountdownToHumanReadable, getPercentLeftFromTimeLeft } from '../utils/timer';
import { getMotionCirclesConfig } from '../utils/getMotionCirclesConfig';
import breakpoints from '../utils/breakpoints';

function CircularCountdown({ timer, stageNumber }) {
  const orangeControls = useAnimationControls();
  const circlesControls = useAnimationControls();
  const percent = getPercentLeftFromTimeLeft(timer);

  useEffect(() => {
    orangeControls.start({ 
      pathLength: percent, 
      opacity: percent > 0 ? 1 : 0 
    });
  }, [timer, orangeControls, percent]);

  useEffect(() => {
    const isMobile = window.matchMedia(breakpoints.sm).matches;
    const config = getMotionCirclesConfig(stageNumber, isMobile);
    if (!config) return;

    circlesControls.start(config);
  }, [circlesControls, stageNumber]);

  return (
    <div className={classNames(styles.root)} data-ready={percent > 0}>
      <div className={styles.container}>
        {percent > 0 && (
          <motion.svg className={styles.progressBarBlue} viewBox="0 0 400 400">
            {Array(10)
              .fill(0)
              .map((oneItem, idx) => {
                const deg = 90 - (idx) * 7.5;
                const opacity = (idx + 1) * 0.1;
                return (
                  <motion.circle
                    key={`one-circle-${oneItem}-${idx}`}
                    cx="200"
                    cy="200"
                    r="190"
                    initial={{ rotate: `-${deg}deg`, pathLength: 0.03, opacity }}
                    animate={{ rotate: `${360 - deg}deg` }} 
                    transition={{ duration: 1.8, repeat: Infinity, ease: 'linear' }}
                  />
                )
              })}
          </motion.svg>
        )}
        <motion.svg className={styles.progressBarOrange} viewBox="0 0 400 400">
          <motion.circle
            cx="200"
            cy="200"
            r="190"
            initial={{ rotate: '-90deg' }}
            animate={orangeControls}
          />
        </motion.svg>
        <motion.ul className={styles.progressBarCircles}>
          <motion.li
            animate={circlesControls}
            transition={getMotionCirclesConfig('transition')}
          />
        </motion.ul>
        <div className={styles.progressBarInner} style={{ display: 'hidden' }}>
          <img className={styles.boxOrange} alt="placeholder" src={imageBoxOrange} />
          <p className={styles.countDown}>{convertCountdownToHumanReadable(timer)}</p>
          <p className={styles.label}>TIME LEFT</p>
        </div>
      </div>
    </div>
  );
}

CircularCountdown.propTypes = {
  timer: PropTypes.number.isRequired,
  stageNumber: PropTypes.number,
};

CircularCountdown.defaultProps = {
  stageNumber: 0,
}

export default CircularCountdown;
