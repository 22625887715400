import styles from './SquishieCard.module.scss';
import { publicUrl } from '../utils/path';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isSquishieRare } from '../utils/collection';
import rareTagUnlocked from '../assets/components/SquishieThumbnail/rare-tag-unlocked.png';
import rareTagLocked from '../assets/components/SquishieThumbnail/rare-tag-locked.png';

function SquishieCard({ squishie, isOwned, className, theme, children, image}) {
  const isRare = isSquishieRare(squishie);

  function sparkles() {
    return (
      <>
        <span className={classNames(styles.sparkle, styles['sparkle--1'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--2'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--3'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--4'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--5'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--6'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--7'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--8'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--9'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--10'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--11'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--12'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--13'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--14'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--15'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--16'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--17'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--18'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--19'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--20'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--21'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--22'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--23'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--24'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--25'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--26'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--27'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--28'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--29'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--30'])}></span>
        <span className={classNames(styles.sparkle, styles['sparkle--31'])}></span>
      </>
    )
  }


  return (
      <div className={classNames(styles.card, className)} data-is-rare={isRare} data-is-owned={isOwned} data-theme={theme}>
        {isRare && <div className={styles.rare}>{(isOwned) ?
              <img src={rareTagUnlocked} alt="rare" /> : <img src={rareTagLocked} alt="rare" />}</div>}
        {isRare && isOwned && sparkles()}
        <div className={styles.bg}>
          <div className={styles.shadow} />
          {children}
          {image && <img src={`${publicUrl()}/${(isOwned) ? squishie.unlockedImage : squishie.lockedImage}`} alt={squishie.name} className={styles.image}/>}
          <h3 className={styles.name}>{squishie.name}</h3>
        </div>
      </div>
  );
}

SquishieCard.propTypes = {
  squishie: PropTypes.object.isRequired,
  isOwned: PropTypes.bool.isRequired,
  className: PropTypes.any,
  theme: PropTypes.oneOf(['single', 'list']),
  children: PropTypes.any,
  image: PropTypes.bool,
};

SquishieCard.defaultProps = {
  className: null,
  theme: 'list',
  children: null,
  image: true,
};

export default SquishieCard;