import AssetLoader from "./AssetLoader";
import Cache from "./Cache";
import config from "./config/config.json";
import manifest from "./config/manifest.json";
import { cjs } from "./lib/createjs-extended";
import MusicPlayer from "./MusicPlayer";
import SFXPlayer from "./SFXPlayer";
import { GetSprite, GetBitmap, GetMovieClip, GetShapeCircle } from "./CJSTools";
import SpriteAnimation from "./SpriteAnimation";
import ParticleStars from "./ParticleStars";
import { getSquishies/*, getBiomes, getFamilies*/ } from '../utils/collection.js';

// canvas element size for desktop character panel
const CANVAS_CARD_DESKTOP_SIZE = { x: 600, y: 750 };
// extra scale factor for mobile character panel
const CANVAS_SCALE_MOBILE_CARD = 0.837;

// canvas element size for clamped min width
const CANVAS_MAP_MIN_RENDER_WIDTH = 600;
// canvas element size for map at largest size in mobile
const CANVAS_MAP_MOBILE_MAX_WIDTH = 752;    // reported element size at 767px width
// scale factors for min/max map size on mobile
const CANVAS_SCALE_MOBILE_MAP_MAX = 1;//0.8;
const CANVAS_SCALE_MOBILE_MAP_MIN = 1;//0.6;

// target scale factor for canvas rendering (1x for "standard", 2x for "retina")
const CANVAS_SCALE_FACTOR = 1;

// additional values scaled by base canvas scale
const MAP_CHAR_DRAG_OVERRIDE_DIST = 15 * CANVAS_SCALE_FACTOR;
const MAP_FLAG_TAP_RADIUS = 35 * CANVAS_SCALE_FACTOR;
const MAP_CHAR_SCALE = 1 * CANVAS_SCALE_FACTOR;
const MAP_FLAG_SCALE = 2 * CANVAS_SCALE_FACTOR;
const MAP_STARS_SCALE = 0.8 * CANVAS_SCALE_FACTOR;
const MAP_CHAR_EDGE_BUFFER = 120 * CANVAS_SCALE_FACTOR;
const MAP_PIN_HOVER_AMOUNT = 10 * CANVAS_SCALE_FACTOR;
const MAP_ANIM_SCALE = 2.0825 * CANVAS_SCALE_FACTOR;
const ANIM_CARD_SCALE = 0.5 * CANVAS_SCALE_FACTOR;
const ANIM_REVEAL_BOX_SCALE = 1 * CANVAS_SCALE_FACTOR;
const MAP_POS_OFFSET_FACTOR = CANVAS_SCALE_FACTOR / 2;
// adjust height values if things are cut off at top/bottom
const ANIM_CHAR_OFFSET_Y = 170 * CANVAS_SCALE_FACTOR;           // card display
const ANIM_CHAR_OFFSET_Y_MOBILE = 140 * CANVAS_SCALE_FACTOR;
const ANIM_REVEAL_CHAR_OFFSET_Y = 150 * CANVAS_SCALE_FACTOR;    // reveal display
const ANIM_REVEAL_BOX_OFFSET_Y = 150 * CANVAS_SCALE_FACTOR;

const SHEET_GENERAL = "Sheet_MapGeneral";
const EVENT_TAP = "mousedown";
const EVENT_DOWN = "mousedown";
const EVENT_MOVE = "pressmove";
const EVENT_UP = "pressup";
const EVENT_HOVER = "rollover";
const EVENT_HOVEROFF = "rollout";
const MAP_SOLID_COLOR = "#eaf6f5";
const ANIM_DEFAULT_CHAR = "default";
const ANIM_TAP_CHAR = "reveal";
const MAP_HOVER_CYCLE_TIME = 3500;
const MAP_SFX_FADE_TIME = 1000;
const BOUNCE_TWEEN_TIME = 900;
const BOUNCE_Y_OFFSET = -20;
const MAP_CHAR_SCALE_OFFSET_FACTOR = 0.6;
const STARS_OFFSET = { x: 0, y: 0 };

/** canvas drawing class */
export default class BaseCanvas {
    // type of canvas to display
    static Types = {
        MAP: "map",     // explore map
        CHAR: "char",   // character display
        BOX: "box",     // character reveal box
    };
    // asset loading
    static Loader = null;
    static Cache = null;
    // audio controllers
    static MusicPlayer = null;
    static SFXPlayer = null;
    // global audio state
    static _AudioContextLikelyGood = false;
    static _IsMuted = false;
    // global SFX playback
    static PlaySFX(id) {
        if (BaseCanvas.SFXPlayer) {
            return BaseCanvas.SFXPlayer.play(id);
        } else {
            console.warn("PlaySFX called without SFX player active");
            return null;
        }
    }
    // global mute control
    static SetMute(isMuted) {
        BaseCanvas._IsMuted = isMuted;
        // handle mute state through sound handlers
        if (BaseCanvas.MusicPlayer) {
            BaseCanvas.MusicPlayer.volume = (isMuted ? 0 : 1);
        }
        if (BaseCanvas.SFXPlayer) {
            BaseCanvas.SFXPlayer.setIsMuted(isMuted);
        }
    }
    // global map canvas reference
    static MapCanvasRef = null;

    /**
     * Constructor
     * @param {BaseCanvas.Types} type - type of canvas
     * @param {String} character - character to display, only applies for character display type
     * @param {Bool} tapToAnimate - if tapping plays animation, only applies for character display type
     * @param {String} initAnim - initial animation to play, only applies for character display type
     */
    constructor(type, character = undefined, tapToAnimate = true, initAnim = ANIM_DEFAULT_CHAR) {
        // initialize base properties
        console.log("initialize BaseCanvas of type: " + type);
        this.canvasType = type;
        this.charType = character;
        this.charAnimTapEnabled = tapToAnimate;
        this.charAnimInit = initAnim;
        this.rootContainer = new cjs.Container();
        this.rootContainer.host = this;
        this.isVisible = false;
        this.isPaused = true;
        this.isMuted = true;
        this.stageWidth = 0;
        this.stageHeight = 0;
        this.loadingDone = false;
        this.touchAlreadyDisabled = false;
        this.storyWasTapped = false;
        // map variables initialized here since resize overwrites it before map setup called
        this.mapRootScale = 1;
        // events
        this.onLoadStart = null;
        this.onLoadEnd = null;
        this.onLoadPlayButton = null;
        this.onTutorialMapShow = null;
        this.onTutorialMapHide = null;
        this.onTapMapChar = null;
        this.onTapMapFlag = null;
        this.onTapMapStory = null;
        this.onTapMapCreate = null;
        this.onTapMapCollect = null;
        this.onTapMapIdeas = null;
        this.showMap = null;

        // Initialize the velocity to null
        this.velocity = null;

        // initialize base functionality
        this._initTicker();
        this._initDOMEvents();
        this._initLoader();
        this._initAudio();
    }

    /**
     * Updates React element reference to canvas object
     * @param {Ref} ref - reference to canvas element host
     */
    setRef(ref) {
        this.ref = ref;
    }

    /**
     * Updates canvas display context reference
     * @param {CanvasRenderingContext2D} context - rendering context
     */
    setContext(context) {
        if (!this.context && context) {
            this.context = context;
            this._initStage(context.canvas);
            // set up display
            switch (this.canvasType) {
                case BaseCanvas.Types.MAP:
                    this.setUpMap();
                    break;
                case BaseCanvas.Types.CHAR:
                    this.setUpChar();
                    break;
                case BaseCanvas.Types.BOX:
                    this.setUpBox();
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Updates canvas display status
     * @param {Bool} visible - if canvas is visible
     * @param {Bool} paused - if canvas should be paused
     */
    visibilityUpdate(visible, paused) {
        // check state
        // console.log("type: " + this.canvasType + ", visible: " + visible + ", paused: " + paused);
        this.isVisible = visible;
        this.isPaused = paused;
        // cancel any popup if hidden or paused
        if (this.charToPopupAfterAnim && (!this.isVisible || this.isPaused)) {
            this.charToPopupAfterAnim = null;
        }
        // if becoming visible after story, clear flag
        if (this.isVisible && !this.isPaused) {
            this.storyWasTapped = false;
        }
        // this.mapCancelTween();
        // control background music on map visibility change
        if (this.canvasType === BaseCanvas.Types.MAP && BaseCanvas.MusicPlayer) {
            // stop audio either when hidden or story button tapped
            if (!this.isVisible || this.storyWasTapped) {
                BaseCanvas.MusicPlayer.pause();
                if (this.lastAreaSFXPlay) {
                    this.lastAreaSFXPlay.stop();
                    this.lastAreaSFXPlay = null;
                }
            } else {
                BaseCanvas.MusicPlayer.resume();
            }
        }
        // fix stretching on page toggle
        this._resize();
    }

    /***************
     * MAP DISPLAY *
     ***************/

    /** draws map background shape for drag input */
    drawMapBGShape() {
        this.bgShape.graphics.beginFill(MAP_SOLID_COLOR);
        this.bgShape.graphics.drawRect(-this.stageWidth / 2, -this.stageHeight / 2, this.stageWidth, this.stageHeight);
        this.bgShape.graphics.endFill();
        this.bgShape.set({ scale: 1 / this.mapRootScale });
    }

    /** set up map canvas */
    setUpMap() {
        // enable mouse over for map
        this.stage.enableMouseOver(20);
        // additional map properties
        this.dragPoint = new cjs.Point();
        this.lastDragPoint = new cjs.Point();
        this.mapDragging = false;
        this.mapDragOverride = 0;
        this.mapChars = [];
        this.mapFlags = [];
        this.collectionState = [];
        this.mapToAddPostLoad = [];
        this.charDataRef = getSquishies();
        this.charToPopupAfterAnim = null;
        this.curMapTween = null;
        this.lastSFXArea = null;
        this.lastAreaSFXPlay = null;
        // solid background
        this.bgShape = new cjs.Shape();
        this.drawMapBGShape();
        this.rootContainer.addChild(this.bgShape);
        // main map container
        this.mapLayer = new cjs.Container();
        this.rootContainer.addChild(this.mapLayer);
        // initialize default characters
        const idArray = [];
        config.mapDefaultCharIDs.forEach(id => {
            idArray.push({ id: id });
        });
        this.mapUpdateCollection(idArray);
        // start load
        this.mapLoad();
    }

    /** map load setup */
    mapLoad() {
        BaseCanvas.Loader.load(manifest.BaseMap);
        // enable this line to quick debug any broken large animations
        // BaseCanvas.Loader.load(manifest.LargeAnim);
        this.loadComplete = BaseCanvas.Loader.on("complete", () => { this.mapLoadDone(); });
        // call load start event
        if (this.onLoadStart) {
            this.onLoadStart();
        }
    }

    /** map load finish */
    mapLoadDone() {
        BaseCanvas.Loader.off("complete", this.loadComplete);
        this.loadingDone = true;
        // call load end event
        if (this.onLoadEnd) {
            this.onLoadEnd();
        }
        // build
        this.mapBuild();
    }

    /** map build after load */
    mapBuild() {
        // enable map events
        this.mapDragStartBind = e => this.mapDragStart(e);
        this.mapDragMoveBind = e => this.mapDragMove(e);
        this.mapDragEndBind = e => this.mapDragEnd(e);
        this.bgShape.on(EVENT_DOWN, this.mapDragStartBind);
        this.bgShape.on(EVENT_MOVE, this.mapDragMoveBind);
        this.bgShape.on(EVENT_UP, this.mapDragEndBind);
        // enable map character events
        this.mapCharTapBind = e => this.mapCharTap(e);
        this.mapCharMoveBind = e => this.mapCharMove(e);
        this.mapCharTapEndBind = e => this.mapCharTapEnd(e);
        this.mapCharAnimEndBind = e => this.mapCharAnimEnd(e);
        // enable map flag events
        this.mapFlagTapBind = e => this.mapFlagTap(e);
        this.mapFlagTapEndBind = e => this.mapFlagTapEnd(e);
        this.mapFlagHoverBind = e => this.mapFlagHoverOn(e);
        this.mapFlagHoverEndBind = e => this.mapFlagHoverOff(e);
        // enable map pin events
        this.mapPinTapEndBind = e => this.mapPinTapEnd(e);
        // map background
        const mapBG = GetBitmap("Image_MapBG").center();
        this.mapLayer.addChildAt(mapBG, 0);
        this.mapBounds = mapBG.getBounds();
        // map animations
        const mapAnimLayer = new cjs.Container();
        this.mapLayer.addChildAt(mapAnimLayer, 1);
        // // west area waterfall
        // const mapAnimWaterfall1 = GetMovieClip("Anim_Waterfalls", "waterfall1");
        // mapAnimWaterfall1.set({
        //     x: -1400 * MAP_POS_OFFSET_FACTOR,
        //     y: -410 * MAP_POS_OFFSET_FACTOR,
        //     scale: MAP_ANIM_SCALE
        // });
        // // rainbow area waterfall
        // const mapAnimWaterfall2 = GetMovieClip("Anim_Waterfalls", "waterfall2");
        // mapAnimWaterfall2.set({
        //     x: 1115 * MAP_POS_OFFSET_FACTOR,
        //     y: -645 * MAP_POS_OFFSET_FACTOR,
        //     scale: MAP_ANIM_SCALE
        // });
        // mapAnimLayer.addChild(
        //     mapAnimWaterfall1,
        //     mapAnimWaterfall2
        // );
        // combined map animations
        const mapAnimGeneral = GetMovieClip("Anim_MapGeneral", "mapAnims");
        mapAnimGeneral.set({
            x: -140 * MAP_POS_OFFSET_FACTOR,
            y: 48 * MAP_POS_OFFSET_FACTOR,
            scale: MAP_ANIM_SCALE
        });
        // add to animation layer
        mapAnimLayer.addChild(mapAnimGeneral);
        // add biome flag tap areas
        let childLayer = 2;
        config.biomeFlags.forEach(flag => {
            const flagObj = new cjs.Container();
            const flagIcon = GetMovieClip("Anim_Flags", flag.clip);
            flagIcon.scale = MAP_FLAG_SCALE;
            const hitShape = GetShapeCircle(MAP_FLAG_TAP_RADIUS);
            hitShape.alpha = 0.01;
            flagObj.addChild(
                hitShape,
                flagIcon
            );
            flagObj.set({
                name: flag.ref,
                x: flag.pos.x * MAP_POS_OFFSET_FACTOR,
                y: flag.pos.y * MAP_POS_OFFSET_FACTOR
            });
            flagObj.sfxID = flag.audio;
            flagObj.clip = flagIcon;
            flagObj.on(EVENT_TAP, this.mapFlagTapBind);
            flagObj.on(EVENT_MOVE, this.mapCharMoveBind);
            flagObj.on(EVENT_UP, this.mapFlagTapEndBind);
            flagObj.on(EVENT_HOVER, this.mapFlagHoverBind);
            flagObj.on(EVENT_HOVEROFF, this.mapFlagHoverEndBind);
            this.mapLayer.addChildAt(flagObj, childLayer);
            this.mapFlags.push(flagObj);
            ++childLayer;
        });
        // add nav buttons in map to link to story, create, collect, ideas
        this.mapAddPin("pin-collect", new cjs.Point(1200 * MAP_POS_OFFSET_FACTOR, 800 * MAP_POS_OFFSET_FACTOR), () => {
            if (this.onTapMapCollect) {
                this.onTapMapCollect();
            }
        });
        this.mapAddPin("pin-create", new cjs.Point(-1200 * MAP_POS_OFFSET_FACTOR, -50 * MAP_POS_OFFSET_FACTOR), () => {
            if (this.onTapMapCreate) {
                this.onTapMapCreate();
            }
        });
        this.mapAddPin("pin-ideas", new cjs.Point(-300 * MAP_POS_OFFSET_FACTOR, -300 * MAP_POS_OFFSET_FACTOR), () => {
            if (this.onTapMapIdeas) {
                this.onTapMapIdeas();
            }
        });
        this.mapAddPin("pin-story", new cjs.Point(950 * MAP_POS_OFFSET_FACTOR, -450 * MAP_POS_OFFSET_FACTOR), () => {
            if (this.onTapMapStory) {
                this.storyWasTapped = true;
                this.onTapMapStory();
            }
        });
        // add characters from collection added during load to map
        this.mapToAddPostLoad.forEach(char => {
            this.mapAddCharToMap(char.id);
        });
        this.mapToAddPostLoad = [];
        // add particle effect
        this.spawnStars = new ParticleStars();
        this.spawnStars.build();
        this.spawnStars.set({ scale: MAP_STARS_SCALE });
        this.mapLayer.addChild(this.spawnStars);
        // focus on welcome point
        // this.mapLayer.set({
        //     x: -(config.mapStartFocus.x - this.mapBounds.width / 2),
        //     y: -(config.mapStartFocus.y - this.mapBounds.height / 2)
        // });
        this.mapEdgeClamp();
        // remove previous instance if set up twice, just comment this block out if it somehow breaks on live
        if (BaseCanvas.MapCanvasRef) {
            console.warn("map canvas somehow initialized twice, removing old instance");
            BaseCanvas.MapCanvasRef.destroy();
        }
        // register this as map
        BaseCanvas.MapCanvasRef = this;
    }

    /**
     * Display map when done building or on command
     * @param {Bool} showTutorial - if showing tutorial for dragging map, default true
     */
    mapShow(showTutorial = true) {
        if (!this.loadingDone) {
            console.warn("attempting to call mapShow before loading complete");
        }
        // start background music (this may not start immediately due to certain autoplay requirements)
        if (!this.IntroMusic) {
            this.IntroMusic = BaseCanvas.MusicPlayer.play("Music_Map", true);
            // this.IntroMusic.on("complete", ()=>{
            //     this.IntroMusic.stop();
            //     this.IntroMusic.removeEventListener("complete");
            //     BaseCanvas.MusicPlayer.play("Ambient-Music", true);
            // });
        }
        // ensure scale is correct
        this._resize();
        // call event for when play button on loader pressed
        if (this.onLoadPlayButton) {
            this.onLoadPlayButton();
        }
        // call event for showing map tutorial, possibly delay this for transitions
        if (showTutorial && this.onTutorialMapShow) {
            this.onTutorialMapShow();
        }
    }

    /**
     * Add pin to map with position and function
     * @param {String} spriteName - sprite ID name
     * @param {Point} pos - position to place
     * @param {Function} func - function to run on pin tap
     * @returns 
     */
    mapAddPin(spriteName, pos, func) {
        // make sprite
        const pinSprite = GetSprite(SHEET_GENERAL, spriteName).center();
        // add events
        pinSprite.on(EVENT_TAP, this.mapFlagTapBind);
        pinSprite.on(EVENT_MOVE, this.mapCharMoveBind);
        pinSprite.on(EVENT_UP, this.mapPinTapEndBind);
        pinSprite.doOnTap = func;
        // add to map
        pinSprite.set({ x: pos.x, y: pos.y, scale: MAP_POS_OFFSET_FACTOR });
        this.mapLayer.addChild(pinSprite);
        // add hover tween
        const tween = cjs.Tween.get(pinSprite, { loop: -1 });
        tween.to({ y: pos.y - MAP_PIN_HOVER_AMOUNT }, MAP_HOVER_CYCLE_TIME / 2, cjs.Ease.sineInOut);
        tween.to({ y: pos.y }, MAP_HOVER_CYCLE_TIME / 2, cjs.Ease.sineInOut);
        // random cycle offset
        tween.setPosition(Math.floor(Math.random() * MAP_HOVER_CYCLE_TIME));
        // done
        return pinSprite;
    }

    /**
     * Creates and adds character animation to map
     * @param {String} name - name ID
     * @returns SpriteAnimation
     */
    mapAddChar(name) {
        let animDef = config.mapAnimations[name];
        let regName = name;
        if (!animDef) {
            // fallback placeholder
            console.log("map character animation " + name + " not found");
            animDef = config.mapAnimations["norbert"];
            regName = "norbert";
        }
        const animSprite = new SpriteAnimation(config.animations[animDef.idle]);
        animSprite.def = animDef;
        animSprite.name = name;
        animSprite.isLooping = false;
        animSprite.lastPlayedAnim = animDef.idle;
        // attempt to center origin point with manual coordinates
        const posRef = config.mapCharPlaces[regName];
        const posFactor = MAP_CHAR_SCALE_OFFSET_FACTOR;
        animSprite.setCustomRegs(new cjs.Point(posRef.regX * posFactor, posRef.regY * posFactor + BOUNCE_Y_OFFSET));
        animSprite.posRef = posRef;
        // add to map
        this.mapLayer.addChild(animSprite);
        this.mapChars.push(animSprite);
        return animSprite;
    }

    /**
     * Creates and adds character animation with position and events to map
     * @param {Number} id - character ID
     * @param {Bool} tweenTo - if immediately tweening to character
     * @returns SpriteAnimation
     */
    mapAddCharToMap(id, tweenTo = false) {
        // look up ID in character data to get name
        let charName;
        for (let i = 0; i < this.charDataRef.length; ++i) {
            if (id === this.charDataRef[i].id) {
                charName = this.charDataRef[i].slug;
                break;
            }
        }
        // make sprite
        const animSprite = this.mapAddChar(charName);
        animSprite.on(EVENT_TAP, this.mapCharTapBind);
        animSprite.on(EVENT_MOVE, this.mapCharMoveBind);
        animSprite.on(EVENT_UP, this.mapCharTapEndBind);
        animSprite.onAnimationFinish = this.mapCharAnimEndBind;
        // offset initial idle animation randomly so everything isn't synced
        animSprite.setFramePercent(Math.random());
        // specific placement offset by map size
        const mapPos = config.mapCharPlaces[charName];
        if (mapPos) {
            animSprite.set({
                x: (mapPos.x * MAP_POS_OFFSET_FACTOR) - (this.mapBounds.width / 2),
                y: ((mapPos.y + mapPos.fixY) * MAP_POS_OFFSET_FACTOR) - (this.mapBounds.height / 2)
            });
        } else {
            // otherwise random placement if not defined
            const limitX = 1200;
            const limitY = 600;
            animSprite.set({
                x: (Math.random() * limitX * 2) - limitX,
                y: (Math.random() * limitY * 2) - limitY
            });
        }
        animSprite.set({ scale: MAP_CHAR_SCALE });
        // save placed location
        animSprite.homePoint = new cjs.Point(animSprite.x, animSprite.y);
        // save ID for later reference
        animSprite.charID = id;
        // move to character if set
        if (tweenTo) {
            this.mapTweenToChar(animSprite.name);
        }
        return animSprite;
    }

    /**
     * Plays specified animation on map character
     * @param {SpriteAnimation} animator - sprite animation to set
     * @param {String} name - name of animation defined in config
     */
    mapCharPlayAnim(animator, name) {
        animator.lastPlayedAnim = name;
        animator.playAnimation(config.animations[name]);
    }

    /**
     * Plays idle animation on map character
     * @param {SpriteAnimation} animator - sprite animation to idle
     */
    mapCharPlayIdle(animator) {
        // randomly play blink or reveal if normal idle recently played
        if (animator.lastPlayedAnim === animator.def.idle && Math.random() < 0.2) {
            if (Math.random() <= 0.5) {
                this.mapCharPlayAnim(animator, animator.def.idleBlink);
            } else {
                this.mapCharPlayAnim(animator, animator.def.tap);
            }
        } else {
            this.mapCharPlayAnim(animator, animator.def.idle);
        }
    }

    /**
     * Updates characters on map with current collection
     * @param {Array} newCollection - array of character collection IDs
     */
    mapUpdateCollection(newCollection) {
        // compare differences between collection states
        const newChars = [];
        const isFirstLoad = (this.collectionState.length === 0);
        for (let i = 0; i < newCollection.length; ++i) {
            const curChar = newCollection[i];
            let alreadyHad = false;
            for (let j = 0; j < this.collectionState.length; ++j) {
                // check if already existing in map collection (assuming one of each character max)
                if (this.collectionState[j].id === curChar.id) {
                    alreadyHad = true;
                    break;
                }
            }
            if (!alreadyHad) {
                // confirm that character is not on map itself
                for (let j = 0; j < this.mapChars.length; ++j) {
                    if (this.mapChars[j].charID === curChar.id) {
                        alreadyHad = true;
                        break;
                    }
                }
            }
            if (!alreadyHad) {
                // verify not already in list to add
                for (let j = 0; j < newChars.length; ++j) {
                    if (newChars[j].id === curChar.id) {
                        alreadyHad = true;
                        break;
                    }
                }
            }
            // passed all tests
            if (!alreadyHad) {
                newChars.push(curChar);
            }
        }
        let newSpawns = [];
        if (newChars.length > 0) {
            // add any new characters to map
            if (!this.loadingDone) {
                // add characters to be added after load finishes
                newChars.forEach(char => {
                    this.mapToAddPostLoad.push(char);
                });
            } else {
                // add characters immediately if already loaded
                newChars.forEach(char => {
                    newSpawns.push(this.mapAddCharToMap(char.id));
                });
            }
            // save new collection state
            this.collectionState = newCollection;
        }
        // handle particles for newest spawn
        if (!isFirstLoad && newSpawns.length > 0) {
            // these should be getting added one at a time so this should work to highlight newest
            const target = newSpawns[newSpawns.length - 1];
            this.spawnStars.set({ x: target.x + STARS_OFFSET.x, y: target.y + STARS_OFFSET.y });
            this.mapLayer.setChildIndex(this.spawnStars, this.mapLayer.children.length - 1);
            this.spawnStars.play();
            this.mapCharPlayAnim(target, target.def.tap);
        }
    }

    /** updates current map position to not scroll outside of current viewport */
    mapEdgeClamp() {
        if (this.mapBounds) {
            // keep map layer from scrolling completely off past background
            const scaleX = (this.mapRootScale < 1 ? 0.875 : 1);
            const scaleY = (this.mapRootScale < 1 ? 0.85 : 1);
            const maxX = Math.floor((this.mapBounds.width - this.stageWidth) / 2) * scaleX;
            const maxY = Math.floor((this.mapBounds.height - this.stageHeight) / 2) * scaleY;
            // check horizontal
            if (maxX < 0) {
                // center horizontal if canvas is larger
                this.mapLayer.x = 0;
            } else {
                // clamp horizontal
                if (this.mapLayer.x < -maxX) {
                    this.mapLayer.x = -maxX;
                } else if (this.mapLayer.x > maxX) {
                    this.mapLayer.x = maxX;
                }
            }
            // check vertical
            if (maxY < 0) {
                // center vertical if canvas is larger
                this.mapLayer.y = 0;
            } else {
                // clamp vertical
                if (this.mapLayer.y < -maxY) {
                    this.mapLayer.y = -maxY;
                } else if (this.mapLayer.y > maxY) {
                    this.mapLayer.y = maxY;
                }
            }
        }
    }

    /**
     * Move map to character position centered on screen
     * @param {String} name - name ID of character
     */
    mapTweenToChar(name) {
        // find character if on map
        let found;
        for (let i = 0; i < this.mapChars.length; ++i) {
            if (this.mapChars[i].name === name) {
                found = this.mapChars[i];
                break;
            }
        }
        if (found) {
            // stop any existing tween
            this.mapCancelTween();
            // set up tween to pan to character position
            const tween = cjs.Tween.get(this.mapLayer, { onChange: () => this.mapEdgeClamp() });
            tween.to({ x: -found.x, y: -found.y }, 1000, cjs.Ease.sineInOut);
            tween.call(() => {
                this.curMapTween = null;
                // play SFX for area on arrival
                if (this.isVisible) {
                    this.lastSFXArea = null;
                    this.checkNearestSFXArea();
                }
            });
            this.curMapTween = tween;
            // hide drag instructions if still showing
            if (this.onTutorialMapHide) {
                this.onTutorialMapHide();
            }
        } else {
            console.log("unable to locate character", name, "on map")
        }
    }

    /** end any active tweens on map motion */
    mapCancelTween() {
        if (this.curMapTween) {
            cjs.Tween.removeTweens(this.mapLayer);
            this.curMapTween = null;
        }
    }

    /** test function to scroll tween to random map character */
    mapTweenToRandomChar() {
        if (this.mapChars.length > 0) {
            this.mapTweenToChar(this.mapChars[Math.floor(Math.random() * this.mapChars.length)].name);
        }
    }

    /**
     * Starts map drag input
     * @param {Event} event - mouse event
     */
    mapDragStart(event) {
        if (this.isVisible && !this.isPaused) {
            // initialize drag points
            this.mapDragPointUpdate(event);
            // start drag
            this.mapDragging = true;
            // cancel any popup or tween
            this.charToPopupAfterAnim = null;
            this.mapCancelTween();
            // fix music
            this.fixAudioContext();
            // call event for hiding map tutorial
            if (this.onTutorialMapHide) {
                this.onTutorialMapHide();
            }
        }
    }

    /**
     * Continues map drag input
     * @param {Event} event - mouse event
     */
    mapDragMove(event) {
       if (this.isVisible && !this.isPaused) {
            if (this.mapDragging) {
                // update drag points
                this.mapDragPointUpdate(event);
                // move map by delta
                const delta = new cjs.Point(
                    this.dragPoint.x - this.lastDragPoint.x,
                    this.dragPoint.y - this.lastDragPoint.y
                );
                // calculate velocity based on the delta
                this.velocity = new cjs.Point(delta.x / 2, delta.y / 2);
                // update map position using velocity
                this.mapLayer.set({
                    x: this.mapLayer.x + this.velocity.x,
                    y: this.mapLayer.y + this.velocity.y
                });
                this.mapLayer.set({
                    x: this.mapLayer.x + delta.x,
                    y: this.mapLayer.y + delta.y
                });
                // keep map in bounds
                this.mapEdgeClamp();
                // play SFX for areas on approach
                this.checkNearestSFXArea();
            }
        }
    }

    /**
     * Ends map drag input
     * @param {Event} event - mouse event
     */
    mapDragEnd(event) {
        // end drag
        this.mapDragging = false;
    }

    /**
     * Updates drag point positions with stage space
     * @param {Event} event - mouse event
     */
    mapDragPointUpdate(event) {
        this.lastDragPoint.x = this.dragPoint.x;
        this.lastDragPoint.y = this.dragPoint.y;
        this.dragPoint.x = event.stageX - this.rootContainer.x;
        this.dragPoint.y = event.stageY - this.rootContainer.y;
    }

    /**
     * Character on map tap event
     * @param {Event} event - mouse event
     */
    mapCharTap(event) {
        BaseCanvas.PlaySFX("SFX_ClickChar");
        const target = event.currentTarget;
        // this.mapCharPlayAnim(target, target.def.tap);
        // prepare to pop up for character
        this.charToPopupAfterAnim = target;
        // tween character scale
        this.mapCharBounceTween(target, MAP_CHAR_SCALE);
        // initialize drag check
        this.mapDragPointUpdate(event);
        this.mapDragOverride = 0;
        // cancel any tween
        this.mapCancelTween();
        // fix music
        this.fixAudioContext();
        // call event for hiding map tutorial
        if (this.onTutorialMapHide) {
            this.onTutorialMapHide();
        }
    }

    /**
     * Animate bounce tween for object
     * @param {DisplayObject} obj - sprite to animate
     * @param {Number} baseScale - base scale of sprite
     */
    mapCharBounceTween(obj, baseScale = 1) {
        // reset to normal
        cjs.Tween.removeTweens(obj);
        obj.set({ y: obj.homePoint.y, scaleX: baseScale, scaleY: baseScale });
        // start animation
        const tween = cjs.Tween.get(obj);
        tween.to({
            // offset for squash to keep at ground position
            y: obj.homePoint.y + (obj.posRef.height - obj.posRef.regY) * MAP_POS_OFFSET_FACTOR,
            // squash scale
            scaleX: 1.85 * baseScale,
            scaleY: 0.3 * baseScale
        }, BOUNCE_TWEEN_TIME * 0.15, cjs.Ease.sineInOut);
        tween.to({
            // back to normal scale and position with bounce
            y: obj.homePoint.y,
            scaleX: baseScale,
            scaleY: baseScale
        }, BOUNCE_TWEEN_TIME * 0.85, cjs.Ease.elasticOut);
    }

    /**
     * Character on map tap motion
     * @param {Event} event - mouse event
     */
    mapCharMove(event) {
        if (!this.mapDragging) {
            // check drag motion
            this.mapDragPointUpdate(event);
            const delta = new cjs.Point(
                this.dragPoint.x - this.lastDragPoint.x,
                this.dragPoint.y - this.lastDragPoint.y
            );
            this.mapDragOverride += (Math.abs(delta.x) + Math.abs(delta.y)) / 2;
            // if motion past threshold, start passing through to map drag
            if (this.mapDragOverride >= MAP_CHAR_DRAG_OVERRIDE_DIST) {
                this.mapDragging = true;
                // cancel any popup
                this.charToPopupAfterAnim = null;
            }
        } else {
            // pass through to map drag
            this.mapDragMove(event);
        }
    }

    /**
     * Character on map tap end
     * @param {Event} event - mouse event
     */
    mapCharTapEnd(event) {
        // only pop up if not dragging
        if (!this.mapDragging && this.charToPopupAfterAnim) {
            if (this.onTapMapChar) {
                this.onTapMapChar(this.charToPopupAfterAnim.name);
            }
        }
        // stop any map drag and reset popup
        this.mapDragging = false;
        this.charToPopupAfterAnim = null;
    }

    /**
     * Sprite animation end completion
     * @param {SpriteAnimation} animator - sprite animation ending
     */
    mapCharAnimEnd(animator) {
        this.mapCharPlayIdle(animator);
        // // if tap event has been triggered to pop up window
        // if (this.charToPopupAfterAnim === animator) {
        //     if (this.onTapMapChar) {
        //         this.onTapMapChar(animator.name);
        //     }
        //     this.charToPopupAfterAnim = null;
        // }
    }

    /**
     * Flag on map tap event
     * @param {Event} event - mouse event
     */
    mapFlagTap(event) {
        BaseCanvas.PlaySFX("SFX_Click");
        // initialize drag check
        this.mapDragPointUpdate(event);
        this.mapDragOverride = 0;
    }

    /**
     * Flag on map tap end
     * @param {Event} event - mouse event
     */
    mapFlagTapEnd(event) {
        if (!this.mapDragging) {
            // do on tap if not dragging
            if (this.onTapMapFlag) {
                this.onTapMapFlag(event.currentTarget.name);
            }
        }
        // stop any map drag
        this.mapDragging = false;
    }

    /**
     * Flag on map hover event
     * @param {Event} event - mouse event
     */
    mapFlagHoverOn(event) {
        // select movie clip of object
        let target = event.currentTarget;
        if (target.clip) {
            target = target.clip;
        }
        // change animation to hover synced
        if (target.currentFrame >= config.flagAnimSync.idle[0] && target.currentFrame <= config.flagAnimSync.idle[1]) {
            target.gotoAndPlay(target.currentFrame + config.flagAnimSync.hover[0]);
        }
    }

    /**
     * Flag on map hover off event
     * @param {Event} event - mouse event
     */
    mapFlagHoverOff(event) {
        // select movie clip of object
        let target = event.currentTarget;
        if (target.clip) {
            target = target.clip;
        }
        // change animation to idle synced
        if (target.currentFrame >= config.flagAnimSync.hover[0] && target.currentFrame <= config.flagAnimSync.hover[1]) {
            target.gotoAndPlay(target.currentFrame - config.flagAnimSync.hover[0]);
        }
    }

    /**
     * Pin on map tap end
     * @param {Event} event - mouse event
     */
    mapPinTapEnd(event) {
        if (!this.mapDragging) {
            // do on tap if not dragging
            if (event.currentTarget.doOnTap) {
                event.currentTarget.doOnTap();
            }
        }
        // stop any map drag
        this.mapDragging = false;
    }

    /** play SFX from nearest area flag */
    checkNearestSFXArea() {
        let nearest, closestDist;
        this.mapFlags.forEach(flag => {
            const dx = -this.mapLayer.x - flag.x;
            const dy = -this.mapLayer.y - flag.y;
            const newDist = (dx * dx) + (dy * dy);
            if (typeof closestDist === "undefined" || closestDist > newDist) {
                closestDist = newDist;
                nearest = flag;
            }
        });
        if (nearest !== this.lastSFXArea) {
            // set new SFX area
            this.lastSFXArea = nearest;
            // fade out any previous SFX
            if (this.lastAreaSFXPlay) {
                const oldSFX = this.lastAreaSFXPlay;
                cjs.Tween.get(oldSFX).to({ volume: 0 }, MAP_SFX_FADE_TIME).call(() => { oldSFX.stop(); });
            }
            // play new SFX
            this.lastAreaSFXPlay = BaseCanvas.PlaySFX(this.lastSFXArea.sfxID);
        }
    }

    /** attempt to fix audio context playback on any input following autoplay blocking */
    fixAudioContext() {
        if (!BaseCanvas._AudioContextLikelyGood && BaseCanvas.MusicPlayer) {
            // toggle it on and off
            BaseCanvas.MusicPlayer.pause();
            BaseCanvas.MusicPlayer.resume();
            // probably fine now
            BaseCanvas._AudioContextLikelyGood = true;
        }
    }

    /*********************
     * CHARACTER DISPLAY *
     *********************/

    /**
     * Set up large character animation canvas
     * @param {Object} additionalManifest - extra data to load with character if defined
     */
    setUpChar(additionalManifest) {
        // start load
        this.charLoad(additionalManifest);
    }

    /**
     * Character load setup
     * @param {Object} additionalManifest - extra data to load with character if defined
     */
    charLoad(additionalManifest) {
        // load specific character
        this.clipNames = config.movieClips[this.charType];
        if (!this.clipNames) {
            // fallback placeholder
            console.log("character animation " + this.charType + " not found");
            this.clipNames = config.movieClips["norbert"];
        }
        if (additionalManifest) {
            // if something else to load, load these together
            let charManifest;
            for (let i = 0; i < manifest.LargeAnim.length; ++i) {
                if (manifest.LargeAnim[i].id === this.clipNames.id) {
                    charManifest = manifest.LargeAnim[i];
                    break;
                }
            }
            BaseCanvas.Loader.load([additionalManifest, charManifest]);
        } else {
            // otherwise load this individually
            BaseCanvas.Loader.loadSingle(manifest.LargeAnim, this.clipNames.id);
        }
        this.loadComplete = BaseCanvas.Loader.on("complete", () => { this.charLoadDone(); });
        // call load start event
        if (this.onLoadStart) {
            this.onLoadStart();
        }
    }

    /** character load finish */
    charLoadDone() {
        BaseCanvas.Loader.off("complete", this.loadComplete);
        this.loadingDone = true;
        // call load end event
        if (this.onLoadEnd) {
            this.onLoadEnd();
        }
        // show loaded character
        this.charClip = GetMovieClip(this.clipNames.id, this.clipNames.ref);
        // offset to fit in canvas, default for reveal
        const charOffset = ANIM_REVEAL_CHAR_OFFSET_Y;
        this.charClip.set({ y: charOffset, scale: ANIM_CARD_SCALE });        
        this.rootContainer.addChild(this.charClip);
        // add tap event
        if (this.charAnimTapEnabled) {
            this.charClipTap = () => this.charPlayTapAnim();
            this.charClip.on(EVENT_TAP, this.charClipTap);
        }
        // if box, defer initial animation until box opens
        if (this.canvasType === BaseCanvas.Types.BOX) {
            this.boxLoadDone();
        } else {
            this.charPlayInitAnim();
        }
        // resize to fix offsets that may be incorrect for card
        this._resize();
    }

    /**
     * Character play given animation name
     * @param {String} animName - name of animation
     */
    charPlayAnim(animName) {
        if (this.charClip) {
            this.charClip.gotoAndPlay(animName);
        } else {
            console.warn("charClip not defined for playing animation");
        }
    }

    /** character play initial animation if set */
    charPlayInitAnim() {
        if (this.charAnimInit) {
            this.charPlayAnim(this.charAnimInit);
        }
    }

    /** character tap event */
    charPlayTapAnim() {
        this.charPlayAnim(ANIM_TAP_CHAR);
    }

    /******************
     * REVEAL DISPLAY *
     ******************/

    /** set up large character reveal canvas */
    setUpBox() {
        // additional manifest location for box animation
        let boxManifest;
        for (let i = 0; i < manifest.LargeAnim.length; ++i) {
            if (manifest.LargeAnim[i].id === "Anim_BoxOpen") {
                boxManifest = manifest.LargeAnim[i];
                break;
            }
        }
        // load everything else for character display
        this.setUpChar(boxManifest);
    }

    /** reveal load finish */
    boxLoadDone() {
        // add box movie clip
        this.boxClip = GetMovieClip("Anim_BoxOpen", "moldopeningAnim");
        this.boxClip.set({ y: ANIM_REVEAL_BOX_OFFSET_Y, scale: ANIM_REVEAL_BOX_SCALE });
        this.rootContainer.addChild(this.boxClip);
        // hide character
        this.charClip.set({ visible: false, alpha: 0 });
        // assign SFX events to idle and open animations
        this.boxClip.onWiggle1 = () => BaseCanvas.PlaySFX("SFX_BoxWiggle");
        this.boxClip.onWiggle2 = () => BaseCanvas.PlaySFX("SFX_BoxWiggle2");
        this.boxClip.onRevealStart = () => BaseCanvas.PlaySFX("SFX_BoxOpen");
        // assign open animation event to box to show character and animate after
        this.boxClip.onReveal = this.boxCharOpen.bind(this);
        // check if box was opened before page refresh
        if (this.checkIfBoxOpenOnRefresh()) {
            this.boxClip.visible = false;
            this.boxCharOpen();
        } else {
            // idle box
            this.boxClip.gotoAndPlay("idle");
        }
    }

    /** show character when box opened */
    boxCharOpen() {
        this.charClip.visible = true;
        cjs.Tween.get(this.charClip).to({ alpha: 1 }, 350).call(() => {
            this.charPlayInitAnim();
        });
        //localStorage.setItem("isBoxOpen", true);
        if (cjs.Touch.isSupported()) {
            this.touchAlreadyDisabled = true;
            cjs.Touch.disable(this.stage);
        }
    }

    /** check for box being open if page refreshed */
    checkIfBoxOpenOnRefresh() {
        // seems to work if refreshed through browser, but possibly not if React refreshes for "hot reload" code update in dev?
        // const isBoxOpen = JSON.parse(localStorage.getItem("isBoxOpen"));
        // if(isBoxOpen === null) {
        //     localStorage.setItem("isBoxOpen", false);
        //     return false;
        // } else {
            return this.isBoxOpen;
        // }
    }

    /** box opening event */
    boxOpen() {
        // start box opening animation
        if (this.boxClip) {
            this.boxClip.gotoAndPlay("onClick");
        } else {
            console.warn("boxClip not defined for playing animation");
            this.boxCharOpen();
        }
    }

    /**********
     * SYSTEM *
     **********/

    /** unload canvas and events */
    destroy() {
        console.log("destroy: " + this.canvasType);
        // base events
        this._removeTicker();
        this._removeDOMEvents();
        // map display events
        if (this.bgShape) {
            if (this.mapDragStartBind) {
                this.bgShape.off(EVENT_DOWN, this.mapDragStartBind);
                this.bgShape.off(EVENT_MOVE, this.mapDragMoveBind);
                this.bgShape.off(EVENT_UP, this.mapDragEndBind);
            }
            if (this.mapChars) {
                this.mapChars.forEach(char => {
                    char.off(EVENT_TAP, this.mapCharTapBind);
                    char.off(EVENT_MOVE, this.mapCharMoveBind);
                    char.off(EVENT_UP, this.mapCharTapEndBind);
                    char.onAnimationFinish = null;
                });
            }
            if (this.mapFlags) {
                this.mapFlags.forEach(flag => {
                    flag.on(EVENT_TAP, this.mapFlagTapBind);
                    flag.on(EVENT_MOVE, this.mapCharMoveBind);
                    flag.on(EVENT_UP, this.mapFlagTapEndBind);
                });
            }
        }
        // character display events
        if (this.charClip) {
            if (this.charClipTap) {
                this.charClip.off(EVENT_TAP, this.charClipTap);
            }
        }
        // box display events
        // if (this.boxClip) {
        //     localStorage.setItem("isBoxOpen", false);
        // }
        // unmount stage
        this.rootContainer.host = null;
        if (this.stage) {
            if(!this.touchAlreadyDisabled){
                cjs.Touch.disable(this.stage);
            }
            
            this.stage.removeChild(this.rootContainer);
        }
        // remove reference if this is current reference
        if (this === BaseCanvas.MapCanvasRef) {
            BaseCanvas.MapCanvasRef = null;
        }
    }

    /** initialize core asset loader */
    _initLoader() {
        if (!BaseCanvas.Loader) {
            BaseCanvas.Cache = new Cache();
            BaseCanvas.Loader = new AssetLoader(BaseCanvas.Cache);
        }
    }

    /** initialize core audio players */
    _initAudio() {
        if (!BaseCanvas.MusicPlayer) {
            BaseCanvas.MusicPlayer = new MusicPlayer(BaseCanvas._IsMuted ? 0 : 1);
        }
        if (!BaseCanvas.SFXPlayer) {
            BaseCanvas.SFXPlayer = new SFXPlayer();
            BaseCanvas.SFXPlayer.setIsMuted(BaseCanvas._IsMuted);
        }
    }

    /** initialize and bind tick */
    _initTicker() {
        cjs.Ticker.timingMode = cjs.Ticker.RAF;
        this.tickBind = e => this._tick(e);
        cjs.Ticker.on("tick", this.tickBind);
    }

    /** unbind tick */
    _removeTicker() {
        if (this.tickBind) {
            cjs.Ticker.off("tick", this.tickBind);
        }
    }

    /**
     * Initialize stage for this instance
     * @param {HTMLCanvasElement} canvas - canvas object
     */
    _initStage(canvas) {
        if (!this.stage) {
            this.stage = new cjs.Stage(canvas);
            this.stage.snapToPixelEnabled = true;
            this.stage.addChild(this.rootContainer);
            cjs.Touch.enable(this.stage);
            this._resize();
        }
    }

    /** bind page events */
    _initDOMEvents() {
        this.resizeBind = () => this._resize();
        window.addEventListener("resize", this.resizeBind);
    }

    /** unbind page events */
    _removeDOMEvents() {
        if (this.resizeBind) {
            window.removeEventListener("resize", this.resizeBind);
        }
    }

    /**
     * Update stage with ticker
     * @param {Event} event - tick event
     */
    _tick(event) {
        // ignore massive lag updates
        if (event.delta > 1000) {
            return;
        }
        // update stage when active
        if (this.stage) {
            if (this.isVisible && !this.isPaused) {
                // render
                this.stage.update(event);
                // map inertia
                if (!this.mapDragging && this.velocity) {
                    // if map is not being dragged and velocity is set
                    // update map position using velocity with a friction factor
                    this.mapLayer.set({
                        x: this.mapLayer.x + this.velocity.x * 0.95,
                        y: this.mapLayer.y + this.velocity.y * 0.95
                    });
                    // decrease velocity over time
                    this.velocity = new cjs.Point(this.velocity.x * 0.95, this.velocity.y * 0.95);
                    // stop updating position if velocity is too small
                    if (Math.abs(this.velocity.x) < 0.1 && Math.abs(this.velocity.y) < 0.1) {
                        this.velocity = null;
                    }
                    // keep map in bounds
                    this.mapEdgeClamp();
                }
                // check animated sprite culling
                if (this.mapChars) {
                    // adjust culling for map scale
                    const edgeH = ((this.stageWidth / 2) + MAP_CHAR_EDGE_BUFFER) / this.mapRootScale;
                    const edgeV = ((this.stageHeight / 2) + MAP_CHAR_EDGE_BUFFER) / this.mapRootScale;
                    // test all sprite positions
                    let testPt = new cjs.Point();
                    this.mapChars.forEach(mapChar => {
                        this.mapLayer.localToLocal(mapChar.x, mapChar.y, this.rootContainer, testPt);
                        mapChar.isPlaying = (Math.abs(testPt.x) < edgeH) && (Math.abs(testPt.y) < edgeV);
                        mapChar.visible = mapChar.isPlaying;
                    });
                }
            } else {
                // stop any active drag/velocity
                this.mapDragging = false;
                this.velocity = null;
            }
        }
    }

    /** resize stage with event */
    _resize() {
        if (this.ref && this.ref.current && this.stage) {
            const dim = { x: this.ref.current.offsetWidth, y: this.ref.current.offsetHeight };
            // console.log(this.canvasType + " resize:", dim, "with factor of", CANVAS_SCALE_FACTOR);
            let newSize = { x: dim.x * CANVAS_SCALE_FACTOR, y: dim.y * CANVAS_SCALE_FACTOR };
            // adjust size to clamp minimum width for map type
            if (this.canvasType === BaseCanvas.Types.MAP) {
                if (newSize.x < CANVAS_MAP_MIN_RENDER_WIDTH) {
                    const scaleFactor = CANVAS_MAP_MIN_RENDER_WIDTH / newSize.x;
                    newSize.y *= scaleFactor;
                    newSize.x = CANVAS_MAP_MIN_RENDER_WIDTH;
                }
            }
            // update only if valid size returned
            if (newSize.x <= 0 || newSize.y <= 0) {
                return;
            }
            // set new size
            this.stage.canvas.width = newSize.x;
            this.stage.canvas.height = newSize.y;
            // update stage size
            this.stageWidth = this.stage.canvas.width;
            this.stageHeight = this.stage.canvas.height;
            // console.log(this.canvasType, "canvas resize:", this.stageWidth, this.stageHeight);
            // reposition root container to center
            this.rootContainer.set({ x: this.stageWidth / 2, y: this.stageHeight / 2 });
            // scale character card/reveal between mobile and desktop/tablet
            if (this.canvasType === BaseCanvas.Types.CHAR) {
                let scaleFactor = 1;
                let charOffset = ANIM_CHAR_OFFSET_Y;
                if (dim.x < CANVAS_CARD_DESKTOP_SIZE.x && dim.y < CANVAS_CARD_DESKTOP_SIZE.y) {
                    scaleFactor = CANVAS_SCALE_MOBILE_CARD;
                    charOffset = ANIM_CHAR_OFFSET_Y_MOBILE;
                }
                this.rootContainer.scale = scaleFactor;
                // adjust character offset between card sizes
                if (this.charClip) {
                    this.charClip.y = charOffset;
                }
            } else if (this.canvasType === BaseCanvas.Types.BOX) {
                let scaleFactor = 1;
                if (dim.x < CANVAS_CARD_DESKTOP_SIZE.x || dim.y < CANVAS_CARD_DESKTOP_SIZE.y) {
                    scaleFactor = Math.min(dim.x / CANVAS_CARD_DESKTOP_SIZE.x, dim.y / CANVAS_CARD_DESKTOP_SIZE.y);
                    // reduce scale further for reveal on mobile to keep everything in bounds
                    scaleFactor *= 0.7;
                }
                this.rootContainer.scale = scaleFactor;
            } else {
                // scale map to fit on mobile
                let scaleFactor = 1;
                if (dim.x <= CANVAS_MAP_MOBILE_MAX_WIDTH) {
                    scaleFactor = (dim.x / CANVAS_MAP_MOBILE_MAX_WIDTH) * CANVAS_SCALE_MOBILE_MAP_MAX;
                    scaleFactor = Math.max(scaleFactor, CANVAS_SCALE_MOBILE_MAP_MIN);
                }
                this.rootContainer.scale = scaleFactor;
                this.mapRootScale = scaleFactor;
            }
            // call resize on all applicable child objects
            this._resizeChildren(this.rootContainer, this.stageWidth, this.stageHeight);
            // update solid background
            if (this.bgShape) {
                this.bgShape.graphics.clear();
                this.drawMapBGShape();
            }
            // fix map position
            if (this.mapLayer) {
                this.mapEdgeClamp();
            }
            // redraw, need to pass delta 0 to avoid breaking animations (ideally any objects aren't dividing by delta unchecked)
            this.stage.update({ delta: 0 });
            // stop active drag since positions have changed
            this.mapDragging = false;
            // this.mapCancelTween();
        }
    }

    /**
     * Recursive call to children on resize event
     * @param {DisplayObject} obj - parent to affect children of
     * @param {Number} width - display width
     * @param {Number} height - display height
     */
    _resizeChildren(obj, width, height) {
        if (obj && obj.children) {
            obj.children.forEach(child => {
                if (child._resize) {
                    child._resize();
                    this._resizeChildren(child, width, height);
                }
            });
        }
    }
}
