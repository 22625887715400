import React, { useCallback, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import styles from './App.module.scss';
import { Outlet, useLocation, useMatches } from 'react-router-dom';
import Header from './components/Header';
import WelcomeModal from './components/WelcomeModal';
import Map from './components/Map';
import GlobalCreateTimer from './components/GlobalCreateTimer';
import { useSite } from './contexts/SiteContext';
import Footer from './components/Footer';
import LeavingModal from './components/LeavingModal';
import HeaderElmers from './components/HeaderElmers';
import { useWindowSize } from '@react-hook/window-size';
import { scrollToTop } from './utils/accessibility';
import EnteringModal from './components/EnteringModal';
import { locationClasses, pageTitle } from './utils/path';
import classNames from 'classnames';
import LockedModal from './components/LockedModal';
import preload1 from './assets/components/SquishieCard/bg-yellow.png';
import preload2 from './assets/components/SquishieCard/bg-orange.png';
import preload3 from './assets/routes/squishie-modal/bg-blue-white.png';
import { loadSound } from './utils/sound';

function App() {
  const {
    isMapVisible,
    queue,
    clearQueue,
    user,
    setUser,
    isMapPaused,
    leavingSite,
    setLeavingSite,
    isAllowed,
    setIsAllowed,
    activityLocked,
    setActivityLocked,
  } = useSite();
  const location = useLocation();
  const matches = useMatches();
  const [width, height] = useWindowSize();
  const [showGlobalCreateTimer, setShowGlobalCreateTimer] = useState(false);
  const [stickyGlobalCreateTimer, setStickyGlobalCreateTimer] = useState(true);

  // Pass state method to window, to allow index.js to trigger leaving modal in a uniform manner.
  window.triggerLeavingSite = setLeavingSite;

  // Page title
  useEffect(() => {
    const match = matches[matches.length-1];

    if (match.data && match.data.pageTitle) {
      pageTitle(match.data.pageTitle);
    } else {
      pageTitle();
    }
  }, [matches]); // eslint-disable-line
  // Intentionally disabling previous line as the tracking event cannot be called multiple times

  // Slightly hacky, but looks for a path change to clear the squishie after it has been opened
  useEffect(() => {
    if (queue.open && location.pathname !== '/create') {
      clearQueue();
    }
  }, [location.pathname, queue.open, clearQueue]);

  // Use Javascript to set 100vh height as CSS variable to circumvent iOS issues.
  useEffect(() => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }, [width, height]);

  // By default, Osano will display in GDPR format. Hiding with Javascript does not work in GPDR format.
  // Per Osano recommendation, hiding Osano banner with CSS until the user has confirmed they are over 13.
  useEffect(() => {
    if (isAllowed) {
      const element = document.querySelector('body');
      element.setAttribute('data-osano', true);
    }
  }, [isAllowed]);

  // Show/hide global timer. Enable/disable sticky positioning of global timer.
  useEffect(() => {
    setShowGlobalCreateTimer(
        location.pathname !== '/create' &&
        !(!queue || !queue.time || queue.opened || queue.hideGlobalTimer === true)
    );

    setStickyGlobalCreateTimer(location.pathname !== '/');
  }, [location.pathname, queue]);

  // Preload a small number of images to prevent awkward rendering
  useEffect(() => {
    [preload1, preload2, preload3].forEach((url) => {
      const img = new Image();
      img.src = url;
    });

    loadSound('card-flip-1.mp3', 'site-card-flip-1');
    loadSound('card-flip-2.mp3', 'site-card-flip-2');
    loadSound('card-open.mp3', 'site-card-open');
    loadSound('click-1.mp3', 'site-click-1');
    loadSound('click-2.mp3', 'site-click-2');
    loadSound('click-menu.mp3', 'site-click-menu');
    loadSound('creature-navigation.mp3', 'site-creature-navigation');
    loadSound('hover-1.mp3', 'site-hover-1');
    loadSound('hover-2.mp3', 'site-hover-2');
    loadSound('families-navigate.mp3', 'site-families-navigate');
    loadSound('timer-start.mp3', 'site-timer-start');
  }, []);

  const handleEnter = useCallback(() => {
    scrollToTop();
    setUser();
  }, [setUser]);

  function handleEnteringModal() {
    setIsAllowed(true);
  }

  const handleLeavingBack = function() {
    setLeavingSite(null);
  }

  function handleLockedClose() {
    setActivityLocked(false);
  }

  function lockedMessage() {
    switch (activityLocked) {
      case 1:
          return "CREATE Elmer's Squishies through the CREATE page to unlock printable activities & games!";
      case 2:
          return "CREATE more Elmer's Squishies to unlock more printable activities & games!"
      default:
          return null;
    }
  }

  return (
      <div className={classNames(styles.root, 'app-root', locationClasses(location.pathname))}>
        <div className={styles.fold}>
          <HeaderElmers className={styles.headerElmers} leavingCallback={setLeavingSite} />
          <div className={classNames('app-content', styles.content)}>
            <Header />
            {!user && <WelcomeModal enter={handleEnter} />}
            {user && (
              <div className={styles.outlet}>
                <Outlet />
              </div>
            )}
            <AnimatePresence>
              {!isAllowed && <EnteringModal next={handleEnteringModal} />}
            </AnimatePresence>
            <AnimatePresence>
              {leavingSite && <LeavingModal data={leavingSite} back={handleLeavingBack} /> }
            </AnimatePresence>
            <AnimatePresence>
              {activityLocked && <LockedModal message={lockedMessage()} close={handleLockedClose} />}
            </AnimatePresence>
            <Map visible={isMapVisible && !!user} paused={isMapPaused} leavingCallback={setLeavingSite} showLogo={!showGlobalCreateTimer} />
            {showGlobalCreateTimer && <GlobalCreateTimer sticky={stickyGlobalCreateTimer} />}
          </div>
        </div>
        <Footer />
      </div>
  );
}

export default App;
