import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLoaderData, useOutletContext, useOutlet } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCreative } from 'swiper';
import styles from './CollectionFamily.module.scss';
import {
  getActivitiesByFamily,
  getBiomeByFamily,
  getFamilyById,
  getSquishiesByFamilyId
} from '../utils/collection';
import ContentContainer from '../components/ContentContainer';
import { useSite } from '../contexts/SiteContext';
import { filterSquishiesByOwned } from '../utils/collection';
import ActivityList from '../components/ActivityList';
import { publicUrl } from '../utils/path';
import Tooltip from '../components/Tooltip';
import SquishieList from '../components/SquishieList';
import DefinitionPill from '../components/DefinitionPill';
import UnlockPromotion from '../components/UnlockPromotion';
import SquishieListEmpty from '../components/SquishieListEmpty';
import { motion, AnimatePresence } from 'framer-motion';
import { modalMotionVariants, defaultMotionProps } from '../utils/motion';

const pagination = {
  bulletClass: styles.neighbors__carouselBullet,
  bulletActiveClass: styles.neighbors__carouselBulletActive,
  horizontalClass: styles.neighbors__carouselBullets
}

const swiperModules = [
  Pagination,
  EffectCreative
];

function CollectionFamily() {
  const loaderData = useLoaderData();
  const [family] = useState((loaderData) ? loaderData.family : null);
  const { showOwned } = useOutletContext();
  const { collection } = useSite();
  const swiper = useRef();
  const element = useOutlet();

  const biome = useMemo(() => {
    return getBiomeByFamily(family);
  }, [family]);

  const neighbors = biome.families.filter((neighbor) => {
    return (neighbor.id !== family.id);
  })

  const squishies = useMemo(() => {
    const squishies = getSquishiesByFamilyId(family.id);
    return (showOwned) ? filterSquishiesByOwned(squishies, collection) : squishies;
  }, [collection, showOwned, family.id]);

  const activities = useMemo(() => {
    return getActivitiesByFamily(family);
  }, [family]);

  useEffect(() => {
    swiper.current.slideTo(0, 0);
  }, [family.id]);

  function handlePrevNeighbor() {
    if (swiper.current.isBeginning) {
      swiper.current.slideTo(swiper.current.slides.length - 1);
    } else {
      swiper.current.slidePrev();
    }
  }

  function handleNextNeighbor() {
    // This hack is necessary because the loop function doesn't work well with React
    if (swiper.current.isEnd) {
      swiper.current.slideTo(0);
    } else {
      swiper.current.slideNext();
    }
  }

  const handleInitNeighbor = useCallback((instance) => {
    swiper.current = instance;
  }, []);

  const creativeEffect = useMemo(() => {
    return {
      prev: {
        scale: 0.5,
        opacity: 0
      },
      next: {
        scale: 0.5,
        opacity: 0
      }
    };
  }, []);

  const neighborsSlides = useMemo(() => {
    return neighbors.map((neighborId) => {
      const neighbor = getFamilyById(neighborId);

      if (neighborId === family.id) {
        return null;
      }

      return (
          <SwiperSlide className={styles.neighbors__swiperSlide} key={`${neighborId}|${family.id}`}>
            <Tooltip className={styles.neighbors__tooltip}>{neighbor.name}</Tooltip>
            <img src={`${publicUrl()}/${neighbor.image}`} alt={neighbor.name} className={styles.neighbors__image}/>
          </SwiperSlide>
      );
    });
  }, [neighbors, family.id]);

  return (
      <motion.div className={styles.root} variants={modalMotionVariants} {...defaultMotionProps}>
        <ContentContainer type="wide">
          <div className={styles.squishies}>
            <AnimatePresence mode="wait">
              {squishies.length > 0 && <SquishieList squishies={squishies} className={styles.squishies__list}/>}
              {squishies.length === 0 && <SquishieListEmpty/>}
            </AnimatePresence>
          </div>
        </ContentContainer>
        <ContentContainer type="max">
          <div className={styles.metaNeighbors}>
            <div className={styles.meta}>
              <div className={styles.meta__header}>
                <h2 className={styles.meta__title}>{family.name}</h2>
                <DefinitionPill label="Habitat" value={biome.name} uppercase={true} icon={`${publicUrl()}/${biome.icon}`}/>
              </div>
              <div className={styles.meta__description}>
                <p>{family.description}</p>
              </div>
            </div>
            <div className={styles.neighbors}>
              <div className={styles.neighbors__header}>
                <h3 className={styles.neighbors__title}>Neighbors</h3>
              </div>
              <div className={styles.neighbors__carousel}>
                <div className={styles.neighbors__carouselInner}>
                  {neighbors.length > 2 && <button className={styles.neighbors__prev} onClick={handlePrevNeighbor} title="Previous neighbor" />}
                  <div className={styles.neighbors__swiperInner}>
                    <Swiper
                        modules={swiperModules}
                        ref={swiper}
                        className={styles.neighbors__swiper}
                        onBeforeInit={handleInitNeighbor}
                        // Note loop is not enabled due to a Swiper/React bug
                        pagination={pagination}
                        effect="creative"
                        followFinger={false}
                        shortSwipes={false}
                        longSwipes={false}
                        creativeEffect={creativeEffect}
                    >
                      {neighborsSlides}
                    </Swiper>
                  </div>
                  {neighbors.length > 2 && <button className={styles.neighbors__next} onClick={handleNextNeighbor} title="Next neighbor" />}
                </div>
              </div>
            </div>
          </div>
        </ContentContainer>
        <div className={styles.activities}>
          <div className={styles.activities__header}>
            <h3 className={styles.activities__headline}>Activities!</h3>
          </div>
          <div className={styles.activities__background}>
            <ContentContainer type="compact">
              <ActivityList activities={activities} className={styles.activities__list}/>
            </ContentContainer>
            <ContentContainer type="max">
              <UnlockPromotion className={styles.activities__promotion}/>
            </ContentContainer>
          </div>
        </div>

        <AnimatePresence mode="wait">
          {element && React.cloneElement(element, { key: family.id })}
        </AnimatePresence>
      </motion.div>
  )
}

export default CollectionFamily;