import { useOutlet, useLocation, useMatches } from 'react-router-dom';
import { useSite } from '../contexts/SiteContext';
import { useEffect } from 'react';
import styles from './Home.module.scss';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import ScrollToTop from '../components/ScrollToTop';

function Home() {
  const { user, showMap, hideMap, pauseMap, resumeMap } = useSite();
  const match = useMatches();
  const { pathname } = useLocation();
  const element = useOutlet();

  function handleFooterClick() {
    let element = document.getElementById('squishtopia-footer');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  // Toggle map on the home route
  useEffect(() => {
    if (user) {
      showMap(true);
    }

    return () => {
      hideMap(false);
    }
  }, [user, showMap, hideMap]);

  // Determine if there is an overlay on top of the map
  useEffect(() => {
    if (match.length > 2) {
      pauseMap(true);
    } else {
      resumeMap(false);
    }
  }, [match, pauseMap, resumeMap]);

  return (
      <div className={styles.root}>
        <ScrollToTop />
        <AnimatePresence mode="wait">
          {element && React.cloneElement(element, { key: pathname })}
        </AnimatePresence>
        <button title="Scroll to footer" className={styles.footerButton} onClick={handleFooterClick} />
      </div>
  )
}

export default Home;
