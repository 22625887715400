import { publicUrl } from '../utils/path';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import VideoIntroWelcome from '../components/VideoIntroWelcome';
import FullModal from '../components/FullModal';

function StoryModal() {
  const navigate = useNavigate();

  function handleClose() {
    navigate('/', {replace: true});
  }

  return (
      <FullModal close={handleClose}>
        <VideoIntroWelcome
            src={publicUrl() + '/static/routes/story/video.mp4'}
            poster={publicUrl() + '/static/routes/story/video-poster.jpg'}
        />
      </FullModal>
  )
}

export default StoryModal;