import PropTypes from 'prop-types';
import styles from './Tooltip.module.scss';
import classNames from 'classnames';

function Tooltip({ children, className }) {
  return (
      <div className={classNames(styles.root, className)}>
        <div className={styles.inner}>
          {children}
        </div>
      </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.any,
};

Tooltip.defaultProps = {
  className: null
};

export default Tooltip;