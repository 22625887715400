import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './CreateBuildStart.module.scss';
import CircularCountdown from './CircularCountdown';
import CreateHeader from './CreateHeader';
import Button from './Button';
import { trackEvent } from '../utils/analytics';
import { playSound } from '../utils/sound';

function CreateBuildStart({ next, timer }) {
  useEffect(() => {
    trackEvent('create', 'start');
  }, []);

  function handleNext() {
    playSound('site-click-1');
    next();
  }

  return (
    <div className={styles.root}>
      <CreateHeader>
        <strong>Step 3/3:</strong> Start the timer and explore fun activities while your Elmer's Squishie is being formed!
      </CreateHeader>
      <CircularCountdown timer={timer} />
      <Button className={styles.button} type="button" onClick={handleNext} icon="timer" uppercase={true} id="gtm-create-start-next">
        Start timer
      </Button>
    </div>
  );
}

CreateBuildStart.propTypes = {
  next: PropTypes.func.isRequired,
  timer: PropTypes.number.isRequired
}

export default CreateBuildStart;