import React from 'react';
import styles from './Footer.module.scss';

import { publicUrl } from '../utils/path';
import FooterElmers from './FooterElmers';


function Footer() {
  return (
      <footer className={styles.root} id="squishtopia-footer">
        <div className={styles.inner}>
          <FooterElmers />
          <div className={styles.copyright}>
            <div className={styles.copyright__container}>
              <a href={process.env.REACT_APP_ELMERS_URL} className={styles.copyright__logo}>
                <img src={`${publicUrl()}/static/elmers-logo.svg`} alt="Elmer's home"/>
              </a>
              <div className={styles.copyright__copy}>
                &copy; {new Date().getFullYear()} Newell Office Brands. The Glue with the Orange Cap&reg;
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
}

export default Footer;
