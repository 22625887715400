/**
 * A simple Timer function that calls a specific callback function after a setTimeout
 * Functionality to Play/Stop/Pause/Resume
 */
class DelayTimer {
    constructor(callback) {
        this.callback = callback;
        this.pauseTime = null;
        this.endTime = null;
        this.timerId = null;
        this.playing = false;
    }

    /**
     * Pause the timer
     */
    pause() {
        // Only pause if we are playing
        if (this.playing) {
            // Stop our timer
            this.stop();

            // Get the difference between when the Timeout SHOULD have ended and now
            this.pauseTime = Math.ceil(this.endTime - Date.now());
        }
    }

    /**
     * Resume if we are paused
     */
    resume() {
        // If we are paused then play again with the new delay
        if (this.pauseTime) {
            this.play(this.pauseTime);
        }
    }

    /**
     * Start the Timer Timeout with a specific delay time
     * @param {Number} delay - how long to delay the Timeout
     */
    play(delay) {
        // Get the time when the timer should end in case the Timer is paused
        this.endTime = Date.now() + delay;

        // Clear the pause time
        this.pauseTime = null;

        // We are currently playing
        this.playing = true;

        // Set a Timer ending calls the callback
        this.timerId = setTimeout(() => {
            this.stop();
            this.callback();
        }, delay);
    }

    /**
     * Stop the timer by clearing the Timeout
     */
    stop() {
        // Clear the timeout
        clearTimeout(this.timerId);

        // No longer playing
        this.playing = false;
    }
}

export default DelayTimer;