import PropTypes from 'prop-types';
import styles from './ActivityList.module.scss';
import ActivityThumbnail from './ActivityThumbnail';
import classNames from 'classnames';
import React from 'react';

function ActivityList({activities, className, unlockFamily, offsetRare, lockedMessage}) {
  return (
      <div className={classNames(styles.root, className)}>
        {activities.map((activity) => {
          return (
              <ActivityThumbnail
                  activity={activity}
                  className={styles.thumbnail}
                  key={activity.id}
                  unlockFamily={unlockFamily}
                  offsetRare={offsetRare}
                  lockedMessage={lockedMessage}
              />
          );
        })}
      </div>
  )
}

ActivityList.propTypes = {
  activities: PropTypes.array.isRequired,
  className: PropTypes.any,
  unlockFamily: PropTypes.bool,
  offsetRare: PropTypes.number,
  lockMessage: PropTypes.number,
};

ActivityList.defaultProps = {
  className: null,
  unlockFamily: false,
  offsetRare: 0,
  lockedMessage: 1,
};

export default React.memo(ActivityList);