import { isDev } from './core';
import { CustomEvent } from '@piwikpro/react-piwik-pro'

export function trackEvent(category, action, name = null, value = null) {
  let params = [category, action];

  if (name) {
    params.push(name);
  }

  if (value) {
    params.push(value);
  }

  if (window.Piwik && window.Piwik.initialized === true) {
    CustomEvent.trackEvent.apply(null, params);
  } else {
    if (isDev()) {
      console.log(`'trackEvent :: ${category} : ${action} : ${name} : ${value}`);
    }
  }
}