import { cjs } from "./lib/createjs-extended";
import { GetSprite } from "./CJSTools";

export default class ParticleStars extends cjs.Container {
    /** constructor */
    constructor() {
        super();
        // properties
        this.particles = [];
        this.particleCount = 30;
        // ranges per particle
        this.minForce = 20;
        this.maxForce = 80;
        this.minLife = 1.25;
        this.maxLife = 1.75;
        this.minSpin = -90;
        this.maxSpin = 90;
        // applied to all particles
        this.downForce = 50;
        this.fadeOutStart = 0.25;
    }

    /** builds particles */
    build() {
        // clear any existing
        this.removeAllChildren();
        this.particles = [];
        // spawn new particles
        for (let i = 0; i < this.particleCount; ++i) {
            const newParticle = this._makeParticle();
            // set properties
            newParticle.lifetime = 0;
            newParticle.velocity = new cjs.Point();
            newParticle.rotSpeed = 0;
            // hide initially
            newParticle.visible = false;
            // add to usable
            this.addChild(newParticle);
            this.particles.push(newParticle);
        }
    }

    /** run particle effect */
    play() {
        this.particles.forEach(particle => {
            // reset properties
            particle.set({x: 0, y: 0, rotation: 0, alpha: 1, visible: true});
            // initialize random lifetime
            particle.lifetime = this._rangeVal(this.minLife, this.maxLife);
            // initialize random velocity
            const randVel = this._rangeVal(this.minForce, this.maxForce);
            const randRad = Math.random() * (2 * Math.PI);
            particle.velocity.x = Math.cos(randRad) * randVel;
            particle.velocity.y = Math.sin(randRad) * randVel;
            // initialize random rotation speed
            particle.rotSpeed = this._rangeVal(this.minSpin, this.maxSpin);
        });
    }

    /**
     * Generates base particle object
     * @returns DisplayObject
     */
    _makeParticle() {
        return GetSprite("Sheet_MapGeneral", "particle-star").center();
    }

    /**
     * Get random number within range
     * @param {Number} min - smallest value
     * @param {Number} max - largest value
     * @returns Number
     */
    _rangeVal(min, max) {
        return min + (Math.random() * (max - min));
    }

    /**
     * Update particles with tick event
     * @param {Event} event - tick event
     */
    _tick(event) {
        // reject tick events without functional delta
        if (!event.delta) return;
        // update particles
        const dt = event.delta / 1000;
        this.particles.forEach(particle => {
            if (particle.visible) {
                // advance by time
                particle.lifetime -= dt;
                if (particle.lifetime <= 0) {
                    // hide when done
                    particle.visible = false;
                } else {
                    // update visual
                    let newAlpha = 1;
                    if (particle.lifetime < this.fadeOutStart) {
                        newAlpha = 1 - ((this.fadeOutStart - particle.lifetime) / this.fadeOutStart);
                    }
                    particle.set({
                        x: particle.x + (particle.velocity.x * dt),
                        y: particle.y + (particle.velocity.y * dt),
                        rotation: particle.rotation + (particle.rotSpeed * dt),
                        alpha: newAlpha
                    });
                    // update forces
                    particle.velocity.y += this.downForce * dt;
                }
            }
        });
    }
}