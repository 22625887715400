import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './CanvasLoader.module.scss';
import PropTypes from 'prop-types';

import { defaultMotionProps, modalMotionVariants } from '../utils/motion';
import Button from './Button';
import Welcome from '../assets/components/CanvasLoader/welcome.png'

function CanvasLoader({ button, enter }) {
  return (
      <motion.div {...defaultMotionProps} variants={modalMotionVariants} className={styles.root}>
        <div className={styles.inner}>
          <AnimatePresence mode="wait">
            {!button 
              ? (
                <motion.div
                  key="shape-loader-key"
                  className={styles.shapeLoader}
                  {...defaultMotionProps}
                  variants={modalMotionVariants}
                >
                  <motion.span
                    className={styles.boxBlue} 
                    initial={{ rotate: 0 }}
                    animate={{ rotate: 360 }}
                    transition={{ repeat: Infinity, ease: 'linear', duration: 3 }}
                  />
                  <span className={styles.title}>
                    {`Squishtopia\nis being formed.`}
                  </span>
                </motion.div>
              ) : (
                <motion.div
                  key="shape-button-key"
                  className={styles.shapeButton}
                  {...defaultMotionProps}
                  variants={modalMotionVariants}
                >
                  <img src={Welcome} alt="Welcome to Squishtopia" className={styles.welcome} />
                  <Button type="button" icon="next" uppercase onClick={enter}>
                    Let's go
                  </Button>
                </motion.div>
              )}
          </AnimatePresence>
        </div>
      </motion.div>
  )
}

CanvasLoader.propTypes = {
  button: PropTypes.bool,
  close: PropTypes.func,
};

CanvasLoader.defaultProps = {
  button: false,
  close: () => {},
};

export default CanvasLoader;