import React, { useState } from 'react';
import styles from './FooterElmers.module.scss';
import privacyOptions from '../assets/components/FooterElmers/privacy-options.png';
import { ReactComponent as IconArrowDown } from '../assets/routes/home/icon-arrow-down.svg';
import classNames from 'classnames';

function FooterElmers() {
  const [expanded, setExpanded] = useState(0);

  function handleYourPrivacyChoices(e) {
    e.preventDefault();

    if (window.Osano) {
      window.Osano.cm.showDoNotSell();
    }
  }

  return (
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.group}>
            <button className={styles.button} onClick={() => setExpanded(expanded !== 1 ? 1 : 0)}>
              <h3 className={styles.title}>Elmer's</h3>
              <IconArrowDown data-expanded={expanded === 1} />
            </button>
            <ul className={styles.buttonLinks} data-expanded={expanded === 1}>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/creative-activities/">
                  Creative Activities
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/slime-glue/">
                  Slime & Glue
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/blogs?fdid=blog">
                  Projects & Inspiration
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/where-to-buy.html">
                  Where to Buy
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/sitemap.html">
                  Sitemap
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.group}>
            <button className={styles.button} onClick={() => setExpanded(expanded !== 2 ? 2 : 0)}>
              <h3 className={styles.title}>Legal</h3>
              <IconArrowDown data-expanded={expanded === 2} />
            </button>
            <ul className={styles.buttonLinks} data-expanded={expanded === 2}>
              <li className={styles.buttonLink}>
                <a href="https://privacy.newellbrands.com/index_en.html">
                  Privacy Statement
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://privacy.newellbrands.com/squishtopia-privacy-statement">
                  Children's Squishtopia Privacy Notice
                </a>
              </li>
              <li className={classNames(styles.yourPrivacyChoices, styles.buttonLink)}>
                {/* Intentionally exempting eslint from snippet as it is comes from Newell / Osano */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={handleYourPrivacyChoices}>
                  Your Privacy Choices
                </a>
                <span className={styles.yourPrivacyChoices__icon}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#" onClick={handleYourPrivacyChoices}>
                    <img src={privacyOptions} alt="" className={styles.yourPrivacyChoices__image} />
                  </a>
                </span>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://privacy.newellbrands.com/cookie_en.html">
                  Cookie Policy
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://www.newellbrands.com/terms-of-use/">
                  Terms of Use
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/legal/california-transparency-supply-chains-act.html">
                  California Transparency in Supply Chains Act
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/legal/elmers-social-responsibility-statement.html">
                  Corporate Responsibility
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.group}>
            <button className={styles.button} onClick={() => setExpanded(expanded !== 3 ? 3 : 0)}>
              <h3 className={styles.title}>Support</h3>
              <IconArrowDown data-expanded={expanded === 3} />
            </button>
            <ul className={styles.buttonLinks} data-expanded={expanded === 3}>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/support/contact-us">
                  Support
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/support?cfid=spray-it">
                  FAQs
                </a>
              </li>
              <li className={styles.buttonLink}>
                <a href="https://www.elmers.com/elmers/media-contacts.html">
                  Media Contacts
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  );
}

export default FooterElmers;