import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from './Button';
import PromptModal from './PromptModal';

function EnteringModal({next}) {
  const [denied, setDenied] = useState(false);

  function handleNo() {
    setDenied(true);
  }

  if(denied) {
    return (
        <PromptModal title="Please ask your parent’s permission to access this site. Then refresh the page to gain access." />
    );
  }

  return (
    <PromptModal
        title="Hey Kids! If you are under 13, do you have your parents’ permission to access the Squishtopia site?"
        buttons={(
            <>
              <Button type="button" onClick={handleNo} uppercase={true}>
                No
              </Button>
              <Button type="button" onClick={next} uppercase={true}>
                Yes
              </Button>
            </>
        )}
    >
      <p>Before we can allow you to proceed, we need to confirm that your parent or guardian has reviewed our <a
          href="https://privacy.newellbrands.com/index_en.html" data-ignore-leaving="true" target="_blank" rel="noreferrer">Privacy Statement</a> which
        includes an explanation of our data collection & sharing practices, use of third party analytics & tracking
        technologies, & consent to our collection & use of your personal information.</p>
      <p>If you are under the age of 13 & your parent or guardian has not consented to our collection, use, & disclosure
        of your personal information as described in our Privacy Statement, please do not access this site.</p>
    </PromptModal>
  );
}

EnteringModal.propTypes = {
  next: PropTypes.func.isRequired,
};

export default EnteringModal;