import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import styles from './CreateBuildTimer.module.scss';

import { getStageFromTimeLeft, getPercentLeftFromTimeLeft } from '../utils/timer';
import CircularCountdown from './CircularCountdown';
import BuildStages from './BuildStages';
import CreateHeader from './CreateHeader';
import Button from './Button';
import { trackEvent } from '../utils/analytics';

function CreateBuildTimer({ back, finish, timer }) {
  const percent = getPercentLeftFromTimeLeft(timer);
  const percentFraction = parseInt(percent / (1/6)) + 1;
  const stageNumber = (percentFraction < 6 ? percentFraction : 6) || getStageFromTimeLeft(timer);

  useEffect(() => {
    trackEvent('create', 'timer');
  }, []);

  return (
    <div className={styles.root}>
      <CreateHeader>
        <strong>Step 3/3:</strong> Start the timer and explore fun activities while your Elmer's Squishie is being formed!
      </CreateHeader>
      <div className={styles.middleSection}>
        <div className={styles.circularCountdown}>
          <CircularCountdown timer={timer} stageNumber={stageNumber} />
          <div className={styles.buttons}>
            <Button type="button" onClick={back} icon="skip-left" />
            <Button type="button" onClick={finish} icon="skip-right" id="gtm-create-timer-skip" />
          </div>
        </div>
        <BuildStages className={styles.buildStages} stageNumber={stageNumber} />
        <motion.div
          className={styles.description}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className={styles.description__title}>Get ready for your Elmer's Squishie!</div>
          <div className={styles.description__copy}>
            <p>While your Elmer's Squishie is being formed in the Mystery Mold, you can start to prepare their habitat, play games, and learn more about Squishtopia and the Elmer's Squishies that live there!</p>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

CreateBuildTimer.propTypes = {
  back: PropTypes.func.isRequired,
  finish: PropTypes.func.isRequired,
  timer: PropTypes.number.isRequired,
};

export default CreateBuildTimer;