export const defaultMotionProps = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit'
};

export const fadeMotionVariants = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'easeOut'
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut'
    }
  }
}

export const modalMotionVariants = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'easeOut'
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut'
    }
  }
};

export const modalWindowMotionVariants = {
  initial: { 
    scale: 0.85 
  },
  animate: { 
    scale: 1 
  },
  transition: { 
    duration: 0.3,
    ease: [0.34, 1.56, 0.64, 1],
  },
}
