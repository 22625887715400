import React, { useMemo } from 'react';
import styles from './GlobalCreateStages.module.scss'
import stages from '../configs/stages.json';
import classNames from 'classnames';

function GlobalCreateStages({ stageNumber }) {
  const stage = useMemo(() => {
    return stages[stageNumber-1];
  }, [stageNumber]);

  if (stageNumber < 0 || stageNumber > 5) {
    return null
  }

  return (
      <div className={classNames(styles.root)}>
        <div className={styles[`progress__${stageNumber}`]}/>
        <div className={styles.textContent}>
          <span className={styles.stage}>STAGE {stageNumber}</span>
          : <span className={styles.title}>{stage.title}</span>
        </div>
      </div>
  )
}

export default React.memo(GlobalCreateStages);