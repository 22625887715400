import React, { useCallback, useContext, useEffect, useState, useReducer, useMemo } from 'react';
import { getCountdown } from '../utils/timer';

const SiteContext = React.createContext();

const userLocalStorageKey = "squishieUser";
const queueLocalStorageKey = "squishieQueue";
const collectionLocalStorageKey = "squishieCollection";
const allowedLocalStorageKey = "squishieIsAllowed";

// Site context handles storing presistent data in localStorage and temporary data (cleared on refresh)
// Data is easily distributed to routes and components

export function useSite() {
  return useContext(SiteContext);
}

/**
 * User reducer
 *
 * @param {Object|null} state
 * @param {Object} action
 * @returns {*}
 */
function userReducer(state, action) {
  let user;

  switch (action.type) {
    case 'merge':
      user = Object.assign((state) ? state : {schema: 1}, action.user);

      setUserToLocalStorage(user);

      return user;
    case 'overwrite':
      user = action.user;

      setUserToLocalStorage(user);

      return user;
    default:
      return state;
  }
}

/**
 * Get user from local storage
 *
 * @return {Object|null}
 */
function getUserFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem(userLocalStorageKey));
  } catch (e) {
    // Error silently. Local storage is either not available or JSON is not parseable.
    return null;
  }
}

/**
 * Set user to local storage
 *
 * @param {Object} user
 */
function setUserToLocalStorage(user) {
  try {
    localStorage.setItem(userLocalStorageKey, JSON.stringify(user));
  } catch (e) {
    // Error silently. Local storage is not available.
  }
}

/**
 * Queue reducer
 *
 * @param {Object|null} state
 * @param {Object} action
 * @returns {*}
 */
function queueReducer(state, action) {
  let queue;

  switch (action.type) {
    case 'init':
      queue = {
        id: parseInt(action.queue.id),
        complete: false,
        hideGlobalTimer: false,
        open: false,
      }
      setQueueToLocalStorage(queue);
      return queue;

    case 'start':
      queue = {...state, ...{time: action.queue.time}};
      setQueueToLocalStorage(queue);
      return queue;

    case 'complete':
      queue = {...state, ...{complete: true}};
      setQueueToLocalStorage(queue);
      return queue;

    case 'open':
      queue = {...state, ...{open: true}};
      setQueueToLocalStorage(queue);
      return queue;

    case 'clear':
      queue = {};
      setQueueToLocalStorage(queue);
      return queue;

    case 'hideGlobalTimer':
      queue = {...state, ...{hideGlobalTimer: true}};
      setQueueToLocalStorage(queue);
      return queue;

    default:
      return state;
  }
}

/**
 * Get queue from local storage
 *
 * @return {Object|null}
 */
function getQueueFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem(queueLocalStorageKey)) || {};
  } catch (e) {
    // Error silently. Local storage is either not available or JSON is not parseable.
    return {};
  }
}

/**
 * Set queue to local storage
 *
 * @param {Object} user
 */
function setQueueToLocalStorage(queue) {
  try {
    localStorage.setItem(queueLocalStorageKey, JSON.stringify(queue));
  } catch (e) {
    // Error silently. Local storage is not available.
  }
}

/**
 * Collection reducer
 *
 * @param {Array|null} state
 * @param {Object} action
 * @return {*}
 */
function collectionReducer(state, action) {
  let collection;

  switch (action.type) {
    case 'add':
      collection = [...state, ...action.collection];
      setCollectionToLocalStorage(collection);
      return collection;
    default:
      return state;
  }
}

/**
 * Get collection from local storage
 *
 * @return {Object|null}
 */
function getCollectionFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem(collectionLocalStorageKey)) || [];
  } catch (e) {
    // Error silently. Local storage is either not available or JSON is not parseable.
    return [];
  }
}

/**
 * Set collection to local storage
 *
 * @param {Object} user
 */
function setCollectionToLocalStorage(queue) {
  try {
    localStorage.setItem(collectionLocalStorageKey, JSON.stringify(queue));
  } catch (e) {
    // Error silently. Local storage is not available.
  }
}

function getAllowedFromLocalStorage() {
  try {
    return localStorage.getItem(allowedLocalStorageKey);
  } catch (e) {
    // Error silently. Local storage is either not available or JSON is not parseable.
    return false;
  }
}

/**
 * Set allowed to local storage
 *
 * @param {Boolean} allowed
 */
function setAllowedToLocalStorage(allowed) {
  try {
    localStorage.setItem(allowedLocalStorageKey, allowed);
  } catch (e) {
    // Error silently. Local storage is not available.
  }
}

export function SiteProvider({children}) {
  const [queue, dispatchQueue] = useReducer(queueReducer, getQueueFromLocalStorage());
  const [user, dispatchUser] = useReducer(userReducer, getUserFromLocalStorage());
  const [collection, dispatchCollection] = useReducer(collectionReducer, getCollectionFromLocalStorage());
  const [isAllowed, _setIsAllowed] = useState(getAllowedFromLocalStorage());
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [isMapPaused, setIsMapPaused] = useState(false);
  const [leavingSite, setLeavingSite] = useState(null);
  const [activityLocked, setActivityLocked] = useState(false);

  /**
   * Set is allowed
   *
   * @param {Boolean}
   */
  function setIsAllowed(value) {
    _setIsAllowed(value);
    setAllowedToLocalStorage(value);
  }

  /**
   * Set user
   *
   * @param {Object} user
   */
  function setUser(user = {}) {
    dispatchUser({
      type: 'merge',
      user
    });
  }

  /**
   * Start queue
   *
   * @param {number} id
   * @param {number} time
   */
  function initQueue(id) {
    dispatchQueue({
      type: 'init',
      queue: {
        id
      }
    });
  }

  /**
   * Complete queue
   */
  const completeQueue = useCallback(() => {
    dispatchQueue({
      type: 'complete',
    });
  }, [dispatchQueue]);

  /**
   * Clear queue
   */
  function clearQueue() {
    dispatchQueue({
      type: 'clear'
    });
  }

  /**
   * Start queue
   */
  function startQueue(time) {
    dispatchQueue({
      type: 'start',
      queue: {
        time
      }
    });
  }

  /**
   * Open queue
   */
  const openQueue = useCallback(() => {
    dispatchQueue({
      type: 'open'
    });

    addCollection(queue.id);
  }, [queue.id]);

  /**
   * Add collection
   *
   * @param {number} id
   */
  function addCollection(id) {
    dispatchCollection({
      type: 'add',
      collection: [{id}]
    })
  }

  /**
   * Show map
   */
  function showMap() {
    setIsMapVisible(true);
  }

  /**
   * Hide map
   */
  function hideMap() {
    setIsMapVisible(false);
  }

  /**
   * Pause map
   */
  function pauseMap() {
    setIsMapPaused(true);
  }

  /**
   * Resume map
   */
  function resumeMap() {
    setIsMapPaused(false);
  }

  /**
   * Hide global timer
   */
  function hideGlobalTimer() {
    dispatchQueue({
      type: 'hideGlobalTimer'
    });
  }

  // Monitor queue countdown and trigger complete
  useEffect(() => {
    if (!queue.complete && queue.time) {
      const interval = setInterval(() => {
        const countdown = getCountdown(queue.time);

        if (!countdown) {
          completeQueue();
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [queue.complete, queue.time, completeQueue]);

  const value = useMemo(() => {
    return {
      queue,
      initQueue,
      completeQueue,
      clearQueue,
      startQueue,
      openQueue,
      hideGlobalTimer,
      addCollection,
      collection,
      setUser,
      user,
      showMap,
      hideMap,
      isMapVisible,
      pauseMap,
      resumeMap,
      isMapPaused,
      leavingSite,
      setLeavingSite,
      isAllowed,
      setIsAllowed,
      activityLocked,
      setActivityLocked
    }
  }, [queue, completeQueue, collection, isAllowed, isMapPaused, isMapVisible, leavingSite, openQueue, user, activityLocked]);

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
}