import React, { useState } from "react";
import classNames from "classnames";
import styles from "./HeaderElmers.module.scss";
import { ReactComponent as IconSupport } from '../assets/components/Header/icon-support.svg';
import { ReactComponent as IconSearch } from '../assets/components/Header/icon-search.svg';
import { ReactComponent as IconClose } from '../assets/x.svg';
import PropTypes from 'prop-types';

function HeaderElmers({ className, leavingCallback }) {
  const [showFormSearch, setShowFormSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const searchValue = ev.target?.[1].value;
    const url = `${process.env.REACT_APP_ELMERS_URL}/search?isSupportSearch=false&q=${searchValue}&search-button=&lang=null`;
    leavingCallback({ url });
  };

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.topWhite}>
        <button
          type="button"
          className={styles.buttonMenu}
          onClick={() => setShowMenu(true)}
          title="Toggle menu"
        >
          <span>Menu</span>
        </button>
        <a href={process.env.REACT_APP_ELMERS_URL} className={styles.iconMain}>
          <img alt="Elmer's home" src="/static/elmers-logo.svg" />
        </a>
        <form className={styles.formSearch} onSubmit={handleSubmit} data-show={showFormSearch} >
          <button
            type="button"
            className={styles.buttonClose}
            onClick={() => setShowFormSearch(false)}
          >
            <IconClose />
          </button>
          <input type="search" />
          <button type="submit" className={styles.submitSearch}>
            <IconSearch />
          </button>
        </form>
        <button
          type="button"
          className={styles.buttonSearch}
          onClick={() => setShowFormSearch(true)}
        >
          <IconSearch />
        </button>
        <a href="https://www.elmers.com/support?cfid=faqs" className={styles.iconSupport} rel="noopener noreferrer">
          <IconSupport />
          <span>Support</span>
        </a>
      </div>
      <div className={styles.bottomBlue} data-show={showMenu}>
        <a className={styles.buttonLink} href="https://www.elmers.com/creative-activities/" rel="noopener noreferrer">
          Creative Activities
        </a>
        <a className={styles.buttonLink} href="https://www.elmers.com/slime-glue/" rel="noopener noreferrer">
          Slime & Glue
        </a>
        <a className={styles.buttonLink} href="https://www.elmers.com/blogs?fdid=blog" rel="noopener noreferrer">
          Projects & Inspiration
        </a>
        <a className={styles.buttonLink} href="https://www.elmers.com/where-to-buy.html" rel="noopener noreferrer">
          Where to Buy
        </a>
        <a className={styles.buttonLink} data-name="support" href="https://www.elmers.com/support?cfid=faqs" rel="noopener noreferrer">
          Support
        </a>
        <button
          type="button"
          className={styles.buttonClose}
          onClick={() => setShowMenu(false)}
        >
          <IconClose />
        </button>
      </div>
    </div>
  );
}

HeaderElmers.propTypes = {
  className: PropTypes.string,
  leavingCallback: PropTypes.func,
};

HeaderElmers.defaultProps = {
  className: null,
  leavingCallback: () => {}
};

export default React.memo(HeaderElmers);
