import React, { useCallback, useRef, useState, useEffect } from 'react';
import styles from './Collection.module.scss'
import classNames from 'classnames';
import { getFamilies } from '../utils/collection';
import ContentContainer from '../components/ContentContainer';
import { NavLink, useOutlet, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import breakpoints from '../utils/breakpoints';
import { useWindowSize } from '@react-hook/window-size';
import { AnimatePresence } from 'framer-motion';
import ScrollToTop from '../components/ScrollToTop';
import { playSound } from '../utils/sound';

const families = getFamilies();

function Collection() {
  const [showOwned, setShowOwned] = useState(false);
  const [swiperSpaceBetween, setSwiperSpaceBetween] = useState(0);
  const { familySlug: rawFamilySlug } = useParams();
  const windowSize = useWindowSize();
  const swiper = useRef();
  const familySlug = (rawFamilySlug) ?? 'all';
  const element = useOutlet({ showOwned });

  function handleShowOwned(e) {
    setShowOwned(!!e.target.checked);
  }

  const handlePrevSlide = useCallback(() => {
    swiper.current.slidePrev();
    playSound('site-families-navigate');
  }, []);

  const handleNextSlide = useCallback(() => {
    swiper.current.slideNext();
    playSound('site-families-navigate');
  }, []);

  const handleInitSlides = useCallback((instance) => {
    swiper.current = instance;
  }, []);

  useEffect(() => {
    if (window.matchMedia(breakpoints.sm).matches) {
      setSwiperSpaceBetween(8);
    } else {
      setSwiperSpaceBetween(14);
    }
  }, [windowSize]);

  useEffect(() => {
    let index;
    families.forEach((family, i) => {
      if (family.slug === familySlug) {
        index = i;
      }
    });

    if (index >= 0 && swiper.current) {
      swiper.current.slideTo(index);
    }

  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  // Intentionally disabling line above because it should only occur when component is loaded.

  function handleOnMouseEnter() {
    playSound('site-hover-2');
  }

  function handleOnClick() {
    playSound('site-click-1');
  }

  return (
      <div className={classNames([styles.root, 'header-offset-fill-padding'])}>
        <ScrollToTop />
        <div className={styles.inner}>
          <ContentContainer type="max">
            <div className={styles.header}>
              <h1 className={styles.header__title}>Elmer's Squishiedex</h1>
              <p className={styles.header__copy}>Explore and keep track of your Elmer's Squishies!</p>
            </div>

            <div className={styles.nav}>
              <NavLink to={`/collect/all`} replace={true} className={classNames(styles.nav__swiperSlideLink, styles.nav__swiperSlideAll, (familySlug === 'all') ? styles.nav__swiperSlideLinkActive : styles.nav__swiperSlideLinkInactive)} onMouseEnter={handleOnMouseEnter} onClick={handleOnClick}>
                <span>All</span>
              </NavLink>
              <button className={styles.nav__prev} onClick={handlePrevSlide} title="Scroll to previous families"></button>
              <div className={styles.nav__carousel}>
                <Swiper
                    ref={swiper}
                    spaceBetween={swiperSpaceBetween}
                    slidesPerView="auto"
                    className={styles.nav__swiper}
                    onBeforeInit={handleInitSlides}
                    centeredSlides={true}
                    centeredSlidesBounds={true}
                    slideToClickedSlide={true}
                >
                  {families.map((family) => <SwiperSlide className={styles.nav__swiperSlide} key={family.id}>
                    <NavLink to={`/collect/${family.slug}`} replace={true} onMouseEnter={handleOnMouseEnter} onClick={handleOnClick}
                             className={classNames(styles.nav__swiperSlideLink, (familySlug === family.slug) ? styles.nav__swiperSlideLinkActive : styles.nav__swiperSlideLinkInactive)}><span>{family.name}</span></NavLink>
                  </SwiperSlide>)}
                </Swiper>
              </div>
              <button className={styles.nav__next} onClick={handleNextSlide} title="Scroll to next families"></button>
            </div>

            <div className={styles.filter}>
              <div className={styles.filter__owned}>
                <label><input type="checkbox" onClick={handleShowOwned} /> <span className={styles.filter__ownedLabelSpan}>Show Owned</span></label>
              </div>
            </div>
          </ContentContainer>

          <AnimatePresence mode="wait">
            {element && React.cloneElement(element, { key: familySlug })}
          </AnimatePresence>
        </div>
      </div>
  );
}

export default Collection;
