import React from "react";
import PropTypes from "prop-types";
import styles from "./PlayButton.module.scss";
import classNames from 'classnames';

function PlayButton({onClick, className, title}) {
  return (
      <div className={classNames(styles.root, className)}>
        <button
          type="button"
          onClick={onClick}
          className={styles.button}
          title={title}
        />
      </div>
  );
}

PlayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.any,
  title: PropTypes.string,
};

PlayButton.defaultProps = {
  className: null,
  title: "Play video"
};

export default React.memo(PlayButton);
