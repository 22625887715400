import React from "react";
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Button.module.scss';
import PropTypes from 'prop-types';

function Button({ className, type, onClick, children, icon, link, uppercase, ...props }) {

  if (type === 'link') {
    return (
      <NavLink to={link} className={classNames(styles.link, styles.root, className)} data-icon={icon} data-uppercase={uppercase} {...props}>
        {children && <span>{children}</span>}
      </NavLink>
    );
  }

  if (type === 'href') {
    return (
      <a href={link} onClick={onClick} className={classNames(styles.href, styles.root, className)} data-icon={icon} data-uppercase={uppercase} {...props}>
        {children && <span>{children}</span>}
      </a>
    );
  }

  if (type === 'button') {
    return (
      <button type="button" onClick={onClick} className={classNames(styles.button, styles.root, className)} data-icon={icon} data-uppercase={uppercase} {...props}>
        {children && <span>{children}</span>}
      </button>
    );
  }

  if (type === 'submit') {
    return (
      <button type="submit" className={classNames(styles.submit, styles.root, className)} data-icon={icon} data-uppercase={uppercase} {...props}>
        {children && <span>{children}</span>}
      </button>
    );
  }

  return null;
}

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.any,
  type: PropTypes.oneOf(['href', 'button', 'submit', 'link']).isRequired,
  children: PropTypes.any,
  icon: PropTypes.oneOf(['back', 'next', 'next-ml', 'timer', 'skip-left', 'skip-right', 'family', 'map', 'plus']),
  link: PropTypes.any,
  uppercase: PropTypes.bool,
};

Button.defaultProps = {
  onClick: () => {},
  className: '',
  children: null,
  icon: null,
  link: '',
  uppercase: false,
};

export default Button;
