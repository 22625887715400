import styles from './CreateBuild.module.scss';
import PropTypes from 'prop-types';
import { getFamilyById, getSquishieById } from '../utils/collection';
import { useEffect, useMemo, useState } from 'react';
import {
  getCountdown,
  getCreateTotalDuration,
} from '../utils/timer';
import TimerConfirmationModal from './TimerConfirmationModal';
import CreateBuildStart from './CreateBuildStart';
import CreateBuildTimer from './CreateBuildTimer';
import CreateBuildComplete from './CreateBuildComplete';
import ContentContainer from './ContentContainer';
import { AnimatePresence } from 'framer-motion';
import ScrollToTop from './ScrollToTop';

function CreateBuild({start, back, reset, finish, open, queue}) {
  const [timer, setTimer] = useState(getCreateTotalDuration());
  const [modal, setModal] = useState(false);

  const squishie = useMemo(() => {
    return getSquishieById(queue.id);
  }, [queue.id]);

  const family = useMemo(() => {
    return getFamilyById(squishie.family);
  }, [squishie.family]);

  // Complete is handled by site context
  // However the actual countdown is handled by the component itself
  useEffect(() => {
    if (queue.time && !queue.complete) {
      setTimer(getCountdown(queue.time));

      const interval = setInterval(() => {
        setTimer(getCountdown(queue.time));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [queue.time, queue.complete]);

  function startNext() {
    setModal(true)
  }

  function handleModalNext() {
    start();
    setModal(false);
  }

  function handleModalClose() {
    setModal(false);
  }

  return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <ScrollToTop />
          <ContentContainer type="max">
            <div className={styles.pages}>
              {/* Prompt user to start timer */}
              {!queue.time && <CreateBuildStart next={startNext} timer={timer} />}
              {/* Timer is counting down */}
              {queue.time && !queue.complete && <CreateBuildTimer back={back} finish={finish} timer={timer} />}
              {/* Timer is complete; crate may or may not be open */}
              {queue.complete && <CreateBuildComplete open={open} reset={reset} isOpen={queue.open} squishie={squishie} family={family} />}
              {/* Modal for timer confirmation */}
            </div>
            <AnimatePresence>
              {modal && <TimerConfirmationModal back={back} next={handleModalNext} close={handleModalClose} />}
            </AnimatePresence>
          </ContentContainer>
        </div>
      </div>
  )
}

CreateBuild.propTypes = {
  start: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  finish: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
};

export default CreateBuild;