import config from '../configs/collection.json';

/**
 * Get families
 *
 * @returns {Array}
 */
export function getFamilies() {
  return config.families;
}

/**
 * Get squishies
 *
 * @returns {Array}
 */
export function getSquishies() {
  return config.squishies;
}

/**
 * Get biomes
 *
 * @returns {Array}
 */
export function getBiomes() {
  return config.biomes;
}

/**
 * Get activities
 */
export function getActivities() {
  return config.activities;
}


/**
 * Get family by id
 *
 * @param {number} id
 * @returns {Object|undefined}
 */
export function getFamilyById(id) {
  return getFamilies().find((family) => family.id === id);
}

/**
 * Get family by slug
 *
 * @param {string} id
 * @returns {Object|undefined}
 */
export function getFamilyBySlug(slug) {
  return getFamilies().find((family) => family.slug === slug);
}

/**
 * Get squishies by family id
 *
 * @param {number} id
 * @returns {Array}
 */
export function getSquishiesByFamilyId(id) {
  return getSquishies().filter((squishie) => squishie.family === id);
}

/**
 * Get squishie by id
 *
 * @param {number} id
 * @returns {Object|undefined}
 */
export function getSquishieById(id) {
  return getSquishies().find((squishie) => squishie.id === id);
}

/**
 * Get squishie by slug
 *
 * @param {string} slug
 * @returns {Object|undefined}
 */
export function getSquishieBySlug(slug) {
  return getSquishies().find((squishie) => squishie.slug === slug);
}

/**
 * Get squishie by crateId
 *
 * @param {number} crateId
 * @returns {Object|undefined}
 */
export function getSquishieByCrateId(crateId) {
  return getSquishies().find((squishie) => squishie.crateId === crateId);
}

/**
 * Get biome by id
 *
 * @param {number} id
 * @returns {Object|undefined}
 */
export function getBiomeById(id) {
  return getBiomes().find((biome) => biome.id === id);
}

/**
 * Get biome by slug
 *
 * @param {string} slug
 * @returns {Object|undefined}
 */
export function getBiomeBySlug(slug) {
  return getBiomes().find((biome) => biome.slug === slug);
}

/**
 * is squishie owned
 *
 * @param {Object} squishie
 * @param {Array} collection
 */
export function isSquishieOwned(squishie, collection) {
  return collection.find((s) => s.id === squishie.id) !== undefined;
}

/**
 * Get activity type name from activity type
 *
 * @param {String} type
 * @return {String}
 */
export function getActivityTypeNameFromActivityType(type) {
  if (type === 'habitat-craft') {
    return 'Habitat Craft';
  } else if (type === 'idea') {
    return 'Ideas';
  } else if (type === 'game') {
    return 'Game';
  }

  return null;
}

/**
 * Is activity method family
 *
 * @param {activity}
 * @return {Boolean}
 */
export function isActivityMethodFamily(activity) {
  return activity.method === 'family';
}

/**
 * Is activity method rare
 *
 * @param {activity}
 * @return {Boolean}
 */
export function isActivityMethodRare(activity) {
  return activity.method === 'rare';
}

/**
 * Is activity method free
 *
 * @param {activity}
 * @return {Boolean}
 */
export function isActivityMethodFree(activity) {
  return activity.method === 'free';
}

/**
 * Get biome by family
 *
 * @param {Object} family
 * @param {Object|null}
 */
export function getBiomeByFamily(family) {
  return getBiomes().find((biome) => {
    return biome.families.indexOf(family.id) >= 0;
  });
}

/**
 * Is squishie rare
 *
 * @param {Object} squishie
 */
export function isSquishieRare(squishie) {
  return squishie.type === 'rare';
}

/**
 * Is squishie rare
 *
 * @param {Object} squishie
 */
export function isSquishieCommon(squishie) {
  return squishie.type === 'common';
}

/**
 * Filter squishies by owned
 *
 * @param {Array} squishies
 * @param {Array} owned
 */
export function filterSquishiesByOwned(squishies, owned) {
  return squishies.filter((squishie) => {
    return owned.find((c) => {
      return c.id === squishie.id;
    });
  });
}

/**
 * Filter squishies by rare owned
 *
 * @param {Array} families
 * @param {Array} owned
 * @return {Array}
 */
export function filterSquishiesByRareOwned(squishies, owned) {
  return squishies.filter((squishie) => {
    return owned.find((c) => {
      return c.id === squishie.id && isSquishieRare(squishie);
    })
  })
}

/**
 * Filter familes by owned
 *
 * @param {Array} families
 * @param {Array} owned
 * @return {Array}
 */
export function filterFamiliesByOwned(families, owned) {
  return families.filter((family) => {
    return filterSquishiesByOwned(getSquishiesByFamilyId(family.id), owned).length > 0;
  });
}

/**
 * Get activity by id
 *
 * @param {Number} id
 * @return {Object}
 */
export function getActivityById(id) {
  return getActivities().find((activity) => activity.id === id);
}

/**
 * Get familes by activity
 *
 * @param {Object} activity
 * @return {Array}
 */
export function getFamiliesByActivity(activity) {
  if (activity.method !== 'family') {
    return [];
  }

  return getFamilies().filter((family) => {
    return family.activities.indexOf(activity.id) >= 0;
  });
}

/**
 * Get rare activities
 */
export function getActivitiesRare() {
  return getActivities().filter((activity) => {
    return isActivityMethodRare(activity);
  });
}

/**
 * Get free activities
 */
export function getActivitiesFree() {
  return getActivities().filter((activity) => {
    return isActivityMethodFree(activity);
  });
}

/**
 * Filter owned by unqiue squishie
 *
 * @param {Array} owned
 * @return {Array}
 */
export function filterOwnedByUniqueSquishie(owned) {
  const output = [];

  owned.forEach((squishie) => {
    if (!output.find(s => s.id === squishie.id)) {
      output.push(squishie);
    }
  });

  return output;
}

/**
 * Is activity unlocked
 *
 * @param {Object} activity
 * @param {Array} owned
 * @param {Number} priorityOffset
 *
 * PriorityOffset only applies to rare activities. In the case of unlocking a rare Squishie, you need to potentially
 * display the *next* available rare activity as unlocked. This is a way to offset that.
 */
export function isActivityUnlocked(activity, owned, priorityOffset = 0) {
  if (isActivityMethodFree(activity)) {
    return true;
  } else if (isActivityMethodFamily(activity)) {
    return filterFamiliesByOwned(getFamiliesByActivity(activity), owned).length > 0;
  } else if (isActivityMethodRare(activity)) {
    return filterSquishiesByRareOwned(getSquishies(), filterOwnedByUniqueSquishie(owned)).length >=
        activity.priority - priorityOffset;
  }

  return false;
}

/**
 * Get activities by family
 *
 * @param {Object} family
 * @param {Boolean} prependRareActivities
 * @return {Array}
 */
export function getActivitiesByFamily(family, prependRareActivities = true) {
  const activities = family.activities.map((activityId) => getActivityById(activityId));

  return (prependRareActivities) ? [...getActivitiesRare(), ...activities, ...getActivitiesFree()] : activities;
}

/**
 * Format squishie birthday
 */
export function formatSquishieBirthday(birthday) {
  const date = new Date(`${birthday}T12:00:00`);

  if (!isFinite(date.getTime())) {
    return false;
  }

  return `${date.getMonth()+1}\\${date.getDate()}`;
}