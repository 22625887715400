import React, { useCallback, useEffect, useState } from "react";
import { useWindowSize } from "@react-hook/window-size";
import styles from "./Nav.module.scss";
import PropTypes from "prop-types";
import ButtonNav from "./ButtonNav";
import { ReactComponent as IconMap } from '../assets/components/Nav/icon-map.svg'
import { ReactComponent as IconAdd } from '../assets/components/Nav/icon-add.svg'
import { ReactComponent as IconCollect } from '../assets/components/Nav/icon-collect.svg'
import { ReactComponent as IconPaint } from '../assets/components/Nav/icon-paint.svg'
import breakpoints from '../utils/breakpoints';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../assets/components/Header/logo.png';
import { ideaUrl } from '../utils/path';
import { playSound } from '../utils/sound';
import { trackEvent } from '../utils/analytics';

function Nav({ isOpen, setIsOpen, className }) {
  const [lgView, isLgView] = useState(false)
  const windowSize = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    if (window.matchMedia(breakpoints.lg).matches) {
      setIsOpen(false)
      isLgView(true)
    } else {
      isLgView(false)
    }
  }, [setIsOpen, lgView, windowSize]);

  const homeIsActiveOverride = useCallback((isActive) => {
    return (isActive) ? isActive : location.pathname.match(/^\/squishies\/|\/habitat\/|\/story/) !== null;
  }, [location.pathname]);

  function handleOnMouseEnter() {
    playSound('site-hover-1')
  }

  function handleOnClick(id) {
    playSound('site-click-menu');

    trackEvent('nav', 'click', id);
  }

  return (
    <nav className={classNames(styles.root, className)}>
      <div className={styles.navContent} data-show={isOpen}>
        <NavLink to="/" className={styles.logo} data-show={isOpen}>
          <img src={logo} alt="Elmer's Squishies logo" />
        </NavLink>
        <ul className={styles.ulLinks} id="gtm-primary-nav" data-gtm="primary-nav">
          <li className={styles.liLink}>
            <ButtonNav to="/" Icon={IconMap} isActiveOverride={homeIsActiveOverride} id="gtm-primary-nav-explore" onMouseEnter={handleOnMouseEnter} onClick={() => handleOnClick('explore')}>Explore</ButtonNav>
          </li>
          <li className={styles.liLink}>
            <ButtonNav to="/create" Icon={IconAdd} id="gtm-primary-nav-create" onMouseEnter={handleOnMouseEnter} onClick={() => handleOnClick('create')}>Create</ButtonNav>
          </li>
          <li className={styles.liLink}>
            <ButtonNav to="/collect" Icon={IconCollect} id="gtm-primary-nav-collect" onMouseEnter={handleOnMouseEnter} onClick={() => handleOnClick('collect')}>Collect</ButtonNav>
          </li>
          <li className={styles.liLink}>
            <ButtonNav to={ideaUrl} Icon={IconPaint} id="gtm-primary-nav-ideas" onMouseEnter={handleOnMouseEnter} onClick={() => handleOnClick('ideas')}>Ideas</ButtonNav>
          </li>
        </ul>
      </div>
      <button
        className={styles.burgerButton}
        data-show={isOpen}
        onClick={() => {
          setIsOpen(!isOpen);
          playSound('site-click-1');
        }}
        title="Toggle menu"
      >
        <span className={styles.line__top} />
        <span className={styles.line__middle} />
        <span className={styles.line__bottom} />
      </button>
    </nav>
  );
}

Nav.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  className: PropTypes.any,
};

Nav.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  className: null
};

export default Nav;
