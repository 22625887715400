import React, { useState, useRef } from "react";
import classNames from 'classnames';
import styles from './VideoIntroWelcome.module.scss';
import PropTypes from 'prop-types';
import PlayButton from './PlayButton';
import { trackEvent } from '../utils/analytics';
import { AnimatePresence } from 'framer-motion';
import { defaultMotionProps, fadeMotionVariants } from '../utils/motion';
import { motion } from 'framer-motion';

function VideoIntroWelcome({ className, src, poster, analyticsId }) {
  const [trackPlay, setTrackPlay] = useState(true);
  const [trackComplete, setTrackComplete] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleBtnClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  function handleVideoPlay() {
    setIsPlaying(true);

    if (trackPlay && analyticsId) {
      setTrackPlay(false);

      trackEvent('video', 'start', analyticsId);
    }
  }

  function handleVideoEnded() {
    if (trackComplete && analyticsId) {
      setTrackComplete(false);
      trackEvent('video', 'complete', analyticsId);
    }
  }

  function handleVideoPause() {
    if (!videoRef.current.seeking) {
      setIsPlaying(false);
    }
  }

  if (!src) {
    return null;
  }

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.container}>
        <AnimatePresence>
          {!isPlaying && (
              <motion.div variants={fadeMotionVariants} {...defaultMotionProps}>
                <PlayButton onClick={handleBtnClick} className={styles.button} />
              </motion.div>
          )}
        </AnimatePresence>
        <video
          controls
          ref={videoRef}
          onPlaying={handleVideoPlay}
          onPause={handleVideoPause}
          onEnded={handleVideoEnded}
          className={styles.video}
          poster={poster}
        >
          <source src={src} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

VideoIntroWelcome.propTypes = {
  className: PropTypes.any,
  src: PropTypes.string.isRequired,
  analyticsId: PropTypes.string,
  poster: PropTypes.string.isRequired,
};

VideoIntroWelcome.defaultProps = {
  className: null,
  analyticsId: null
}

export default VideoIntroWelcome;