import { cjs } from "./lib/createjs-extended";
import BaseCanvas from "./BaseCanvas";

const CLIP_SNAPTOPIXEL_FIX_ENABLED = true;
const MAX_CLIP_FIX_INSTANCES = 100;
const MAX_CLIP_FIX_LAYERS = 10;

/**
 * Get bitmap by ID
 * @param {String} id
 * @returns bitmap
 */
export function GetBitmap(id) {
    return new cjs.Bitmap(BaseCanvas.Cache.get(id));
}

/**
 * Get sprite from spritesheet
 * @param {String} sheetID
 * @param {String} spriteID
 * @returns sprite
 */
export function GetSprite(sheetID, spriteID) {
    return new cjs.Sprite(BaseCanvas.Cache.get(sheetID), spriteID);
}

/**
 * Sets frame of existing sprite
 * @param {Object} sprite
 * @param {Value} frameID
 * @param {Boolean} autoCenter
 */
export function SetFrame(sprite, frameID, autoCenter = true) {
    sprite.gotoAndStop(frameID);
    if (autoCenter) {
        sprite.center();
    }
}

/**
 * Creates generic rectangle shape
 * @param {Number} w - width
 * @param {Number} h - height
 * @param {Number} x - optional drawn X offset, default 0
 * @param {Number} y - optional drawn Y offset, default 0
 * @param {String} color - optional color, default white
 * @returns shape
 */
export function GetShapeRect(w, h, x = 0, y = 0, color = "#fff") {
    let g = new cjs.Graphics().f(color).r(x, y, w, h);
    return new cjs.Shape(g);
}

/**
 * Creates generic circle shape
 * @param {Number} r - radius
 * @param {Number} x - optional drawn X offset, default 0
 * @param {Number} y - optional drawn Y offset, default 0
 * @param {String} color - optional color, default white
 * @returns shape
 */
export function GetShapeCircle(r, x = 0, y = 0, color = "#fff") {
    let g = new cjs.Graphics().f(color).dc(x, y, r);
    return new cjs.Shape(g);
}

/**
 * Gets movie clip from exported asset
 * @param {String} cacheName - name/ID in cache manifest
 * @param {String} libName - name of clip in file, usually source filename minus spaces if accessing root timeline
 * @param {Bool} regCenter - if registration point should be centered for this clip
 * @returns MovieClip
 */
export function GetMovieClip(cacheName, libName, regCenter = false) {
    // verify a string is passed, not a cache entry
    if (typeof cacheName === "string" || cacheName instanceof String) {
        let cached = BaseCanvas.Cache.get(cacheName);
        let clip = new cached[libName]();
        if (regCenter) {
            clip.set({ regX: cached.properties.width * 0.5, regY: cached.properties.height * 0.5 });
        }
        // save framerate property for later
        clip._defaultFramerate = cached.properties.fps;
        clip.set({ framerate: clip._defaultFramerate });
        // fix snap to pixel issues if set
        if (CLIP_SNAPTOPIXEL_FIX_ENABLED) {
            clipRecursiveSnapToPixelFix(clip);
        }
        return clip;
    } else {
        console.warn("GetMovieClip first parameter " + cacheName + " is not a string, must be a string to load from cache internally!");
        return null;
    }
}

/**
 * Set playback speed of clip based on default framerate factor
 * @param {Object} targetClip - MovieClip
 * @param {Number} value - defaults to 1x
 */
export function SetClipPlaybackSpeed(targetClip, value = 1) {
    targetClip.set({ framerate: targetClip._defaultFramerate * value });
}

/**
 * Checks if movie clip is within given frame ranges
 * @param {Object} clip - MovieClip
 * @param {Array} rangeSet - array of arrays with 2 numbers each representing start and end frames
 * @returns Bool
 */
export function GetIsClipInRangeSet(clip, rangeSet) {
    let inSet = false;
    for (let i = 0; i < rangeSet.length; ++i) {
        let range = rangeSet[i];
        if (clip.currentFrame >= range[0] && clip.currentFrame <= range[1]) {
            inSet = true;
            break;
        }
    }
    return inSet;
}

/**
 * Gets parallel frame related to alternate animation frame set
 * @param {Number} curFrame - index of current animation frame
 * @param {Array} rangeSrc - array of 2 numbers representing start and end frames of current animation
 * @param {Array} rangeDest - array of 2 numbers representing start and end frames of target animation
 * @returns Number - relative frame in target animation, or current frame if not in source range
 */
export function GetParallelFrame(curFrame, rangeSrc, rangeDest) {
    if (curFrame < rangeSrc[0] || curFrame > rangeSrc[1]) {
        return curFrame;
    }
    // get percentage along source animation
    let percentFrame = (curFrame - rangeSrc[0]) / (rangeSrc[1] - rangeSrc[0]);
    // project percentage onto destination range
    let resultFrame = Math.floor(percentFrame * (rangeDest[1] - rangeDest[0])) + rangeDest[0];
    // ensure result is still in range
    return Math.min(resultFrame, rangeDest[1]);
}

/**
 * Helper function to recursively run through movie clip and disable snap to pixel to unbreak animation tweening
 * @param {Object} clip - MovieClip
 * @param {Number} layerDepth - how deep in clip tree this is running, leave as default to start call
 */
function clipRecursiveSnapToPixelFix(clip, layerDepth = 0) {
    // disable snap to pixel on this
    clip.snapToPixel = false;
    // check layer depth
    ++layerDepth;
    if (layerDepth >= MAX_CLIP_FIX_LAYERS) {
        return;
    }
    // check instance child presence
    if (clip.instance) {
        clipRecursiveSnapToPixelFix(clip.instance);
        // check additional child presence
        for (let i = 1; i < MAX_CLIP_FIX_INSTANCES; ++i) {
            if (!clip["instance_" + i]) {
                break;
            }
            clipRecursiveSnapToPixelFix(clip["instance_" + i]);
        }
    }
}