export const getMotionCirclesConfig = (variant, mobile = false) => {
  const scale = [1, 5, 7, 1];
  const opacity = [0, 0.5, 0, 0];

  switch (variant) {
    case 'transition':
      return {
        times: [0, 0.7, 0.95, 1],
        duration: 0.75,
        ease: 'linear',
      };
    case 2:
      return {
        scale, opacity,
        top: mobile ? ['73px', '73px', '73px', '227px'] : ['93px', '93px', '93px', '286px'],
        left: mobile ? ['280px', '280px', '280px', '280px'] : ['354px', '354px', '354px', '354px'],
      };
    case 3:
      return {
        scale, opacity,
        top: mobile ? ['227px', '227px', '227px', '302px'] : ['286px', '286px', '286px', '379px'],
        left: mobile ? ['280px', '280px', '280px', '150px'] : ['354px', '354px', '354px', '190px'],
      };
    case 4:
      return {
        scale, opacity,
        top: mobile ? ['302px', '302px', '302px', '226px'] : ['379px', '379px', '379px', '286px'],
        left: mobile ? ['150px', '150px', '150px', '19px'] : ['190px', '190px', '190px', '26px'],
      };
    case 5:
      return {
        scale, opacity,
        top: mobile ? ['226px', '226px', '226px', '73px'] : ['286px', '286px', '286px', '93px'],
        left: mobile ? ['19px', '19px', '19px', '19px'] : ['26px', '26px', '26px', '26px'],
      };
    case 6:
      return {
        scale, opacity,
        top: mobile ? ['73px', '73px', '73px', '73px'] : ['93px', '93px', '93px', '93px'],
        left: mobile ? ['19px', '19px', '19px', '280px'] : ['26px', '26px', '26px', '354px'],
      };
    default:
      return {};
  };
};
