import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './SquishieList.module.scss';
import SquishieThumbnail from './SquishieThumbnail';

function SquishieList({ squishies, all, className }) {
  return (
    <div className={classNames(styles.root, className)}>
      {squishies.map((squishie) => {
        return <SquishieThumbnail squishie={squishie} className={styles.thumbnail} all={all} key={squishie.id} />
      })}
    </div>
  )
}

SquishieList.propTypes = {
  squishies: PropTypes.array.isRequired,
  all: PropTypes.bool,
  className: PropTypes.any,
};

SquishieList.defaultProps = {
  className: null,
  all: false
}

export default React.memo(SquishieList);
