import breakpoints from './breakpoints';

export function scaleContent(availableElement, contentElement) {
  const availableComputedStyle = window.getComputedStyle(availableElement);
  const availableWidth = availableElement.offsetWidth - parseInt(availableComputedStyle.paddingLeft) - parseInt(availableComputedStyle.paddingRight);
  const availableHeight = availableElement.offsetHeight - parseInt(availableComputedStyle.paddingTop) - parseInt(availableComputedStyle.paddingBottom);
  const contentWidth = contentElement.offsetWidth;
  const contentHeight = contentElement.offsetHeight;
  const availableAr = availableWidth / availableHeight;
  const contentAr = contentWidth / contentHeight;

  if (contentAr >= availableAr) {
    contentElement.style.transform = `scale(${Math.min(1, availableWidth / contentWidth)})`;
  } else {
    contentElement.style.transform = `scale(${Math.min(1, availableHeight / contentHeight)})`;
  }
}

export function scrollToTop() {
  const desktopHeaderHeight = 106;
  const mobileHeaderHeight = 40;

  const headerHeight = (window.matchMedia(breakpoints.sm).matches) ? mobileHeaderHeight : desktopHeaderHeight;
    const y = window.scrollY;

    if (y > headerHeight) {
      window.scrollTo({
        top: 0
      });
    }
}