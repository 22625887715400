import React, { useEffect, useRef, useState } from 'react';
import styles from './Map.module.scss';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSite } from '../contexts/SiteContext';
import BaseCanvas from '../canvas/BaseCanvas';
import CanvasLoader from './CanvasLoader';
import CanvasInstructions from './CanvasInstructions';
import { AnimatePresence } from 'framer-motion';
// import { cjs } from '../canvas/lib/createjs-extended';
import { ideaUrl } from '../utils/path';
import Logo from '../assets/components/Map/logo.png';

function Map({ visible, paused, leavingCallback, showLogo }) {
  // site/collection state
  const { collection } = useSite();
  const navigate = useNavigate();
  // reference to canvas from React element
  const canvasElement = useRef(null);
  // reference to BaseCanvas instance
  const mapCanvas = useRef(null);
  const [showCanvasLoader, setShowCanvasLoader] = useState(true);
  const [showCanvasLoaderButton, setShowCanvasLoaderButton] = useState(false);
  const [showCanvasInstructions, setShowCanvasInstructions] = useState(false);
  const [init, setInit] = useState(true);

  // initialize canvas drawing once
  useEffect(() => {
    if (init) {
      // initialize canvas
      mapCanvas.current = new BaseCanvas(BaseCanvas.Types.MAP);
      // add tap navigation for map characters
      mapCanvas.current.onTapMapChar = e => navigateToSquishie(e);
      mapCanvas.current.onTapMapFlag = e => navigateToHabitat(e);
      mapCanvas.current.onTapMapStory = () => navigateToStory();
      mapCanvas.current.onTapMapCreate = () => navigateToCreate();
      mapCanvas.current.onTapMapCollect = () => navigateToCollect();
      mapCanvas.current.onTapMapIdeas = () => navigateToIdeas();
      // add loader/tutorial events
      mapCanvas.current.onLoadStart = () => showLoader(true, false);
      mapCanvas.current.onLoadEnd = () => showLoader(true, true);
      mapCanvas.current.showMap = () => handleEnter();
      mapCanvas.current.onTutorialMapShow = () => showInstructions(true);
      mapCanvas.current.onTutorialMapHide = () => showInstructions(false);

      // get context
      const context = canvasElement.current.getContext("2d");
      // pass ref, context to drawing class
      mapCanvas.current.setRef(canvasElement);
      mapCanvas.current.setContext(context);
      // track resize more directly to fix sudden resize breaking aspect ratios
      const resizeObserver = new ResizeObserver(() => {
        mapCanvas.current._resize();
      });
      resizeObserver.observe(canvasElement.current);

      setInit(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  // update on visibility toggle
  useEffect(() => {
    // pass visible, paused to drawing class
    if (visible) {
      window.requestAnimationFrame(() => {
        mapCanvas.current.visibilityUpdate(visible, paused);
      });
    } else {
      mapCanvas.current.visibilityUpdate(visible, paused);
    }
    // visible === true: Idle is when the map is still visible, but is covered by a transparent overlay/modal
    // visible === false: Map is disabled when a user visits the collect or create section. Map code is still active
    //                    in order to restore the users previous position if they return, but is not changing.
    // paused === true: Map is visible behind an overlay on the Home page
    // paused === false: Map is visible with no overlays
  }, [visible, paused]);

  // update on collection update
  useEffect(() => {
    mapCanvas.current.mapUpdateCollection(collection);
  }, [collection]);

  // Use this method to open a Squishie overlay
  function navigateToSquishie(slug) {
    navigate(`/squishies/${slug}`, {
      replace: true
    });
  }

  // Use this method to open a habitat/biome overlay
  function navigateToHabitat(slug) {
    navigate(`/habitat/${slug}`, {
      replace: true
    });
  }

  // Use this method to open Story page
  function navigateToStory() {
    navigate('/story', {
      replace: true
    });
  }

  // Use this method to go to Create page
  function navigateToCreate() {
    navigate('/create', {
      replace: true
    });
  }
  
  // Use this method to go to Collect page
  function navigateToCollect() {
    navigate('/collect', {
      replace: true
    });
  }

  // Use this method to go to Ideas external page
  function navigateToIdeas() {
    leavingCallback({ url: ideaUrl, target: '_blank' });
  }

  // show/hide loader
  function showLoader(show, button = false) {
    // const audioContext = cjs.Sound.activePlugin && cjs.Sound.activePlugin.context;
    // if (!audioContext) {
	  //   return;
    // }
    // const contextState = audioContext && audioContext.state;

    // if(contextState !== "running"){
      setShowCanvasLoaderButton(button);
      setShowCanvasLoader(show);
    // } else {
    //   setShowCanvasLoader(false);
    //   // canvas should exist by now
    //   mapCanvas.current.mapShow();
    // }
  }

  // @Chris: show/hide instructions
  function showInstructions(show) {
    setShowCanvasInstructions(show);
  }

  // enter button on finishing load
  function handleEnter() {
    setShowCanvasLoader(false);
    // canvas should exist by now
    mapCanvas.current.mapShow();
  }

  return (
      <div className={styles.root} data-show={visible}>
        <AnimatePresence initial={false}>
          {showCanvasLoader && <CanvasLoader key="canvas-loader-key" enter={handleEnter} button={showCanvasLoaderButton} /> }
          {showCanvasInstructions && <CanvasInstructions key="canvas-instructions-key" />}
        </AnimatePresence>
        <canvas ref={canvasElement} className={styles.canvas} />
        {showLogo && (
            <div className={styles.logo}>
              <img src={Logo} alt="Welcome to Squishtopia" />
            </div>
        )}
      </div>
  );
}

Map.propTypes = {
  visible: PropTypes.bool,
  paused: PropTypes.bool,
  leavingCallback: PropTypes.func.isRequired,
  showLogo: PropTypes.bool,
};

Map.defaultProps = {
  visible: null,
  paused: null,
  showLogo: false
};

export default Map;