import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import {
  createHashRouter,
  RouterProvider
} from 'react-router-dom';
import { routes } from './router';
import { SiteProvider } from './contexts/SiteContext';
import ErrorPage from './routes/ErrorPage';
import PiwikPro from '@piwikpro/react-piwik-pro';

if (process.env.REACT_APP_PIWIK_ID && process.env.REACT_APP_PIWIK_DOMAIN) {
  PiwikPro.initialize(process.env.REACT_APP_PIWIK_ID, process.env.REACT_APP_PIWIK_DOMAIN);
}

// Router used simply as a consistent method to change views
const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: routes, // See router.js
    ...(process.env.NODE_ENV === 'production') ? { errorElement: <ErrorPage /> } : {},
  }
]);

const root = ReactDOM.createRoot(document.getElementById('squishtopia-root'));
root.render(
    <React.StrictMode>
      <SiteProvider>
        <RouterProvider router={router} />
      </SiteProvider>
    </React.StrictMode>
);
