import React from 'react';
import Home from './routes/Home';
import Collection from './routes/Collection';
import SquishieModal from './routes/SquishieModal';
import CollectionFamily from './routes/CollectionFamily';
import Create from './routes/Create';
import { redirect } from 'react-router-dom';
import {
  getBiomeBySlug,
  getFamilies,
  getFamilyBySlug,
  getSquishieBySlug,
} from './utils/collection';
import StoryModal from './routes/StoryModal';
import BiomeModal from './routes/BiomeModal';
import CollectionAll from './routes/CollectionAll';
import { throw404 } from './utils/path';

export const routes = [
  {
    path: "/",
    element: <Home/>,
    loader: () => {
      return {
        pageTitle: "Explore"
      }
    },
    children: [
      {
        path: "/story",
        element: <StoryModal />,
        loader: () => {
          return {
            pageTitle: ['Story', 'Explore'],
          }
        }
      },
      {
        path: "/squishies/:squishieSlug",
        element: <SquishieModal parentPath="/" />,
        loader: ({ params }) => {
          const squishie = getSquishieBySlug(params.squishieSlug);

          if(!squishie) {
            throw404();
          }

          return {
            squishie,
            reverse: true,
            pageTitle: [squishie.name, 'Explore']
          };
        }
      },
      {
        path: "/habitat/:biomeSlug",
        element: <BiomeModal parentPath="/" />,
        loader: ({ params }) => {
          const biome = getBiomeBySlug(params.biomeSlug);

          if(!biome) {
            throw404()
          }

          return {
            biome,
            pageTitle: [biome.name, 'Explore']
          };
        }
      }
    ]
  },
  {
    path: "/collect",
    element: <Collection/>,
    loader: () => {
      return {
        pageTitle: ['Collect']
      }
    },
    children: [
      {
        path: "/collect",
        loader: () => {
          return redirect(`/collect/${getFamilies()[0].slug}`);
        },
        element: <CollectionFamily/>,
      },
      {
        path: "/collect/all",
        element: <CollectionAll />,
        loader: () => {
          return {
            pageTitle: ['All', 'Collect']
          }
        },
        children: [
          {
            path: "/collect/all/:squishieSlug",
            element: <SquishieModal/>,
            loader: ({ params }) => {
              const squishie = getSquishieBySlug(params.squishieSlug);

              if(!squishie) {
                throw404();
              }

              return {
                squishie,
                pageTitle: [squishie.name, 'All', 'Collect']
              };
            }
          }
        ]
      },
      {
        path: "/collect/:familySlug",
        element: <CollectionFamily/>,
        loader: ({ params }) => {
          const family = getFamilyBySlug(params.familySlug);

          if(!family) {
            throw404();
          }

          return {
            family,
            pageTitle: [family.name, 'Collect']
          }
        },
        children: [
          {
            path: "/collect/:familySlug/:squishieSlug",
            element: <SquishieModal/>,
            loader: ({ params }) => {
              const squishie = getSquishieBySlug(params.squishieSlug);
              const family = getFamilyBySlug(params.familySlug);

              if(!squishie || squishie.family !== family.id) {
                throw404();
              }

              return {
                squishie,
                pageTitle: [squishie.name, family.name, 'Collect']
              };
            }
          }
        ]
      }
    ]
  },
  {
    path: "/create",
    element: <Create/>,
    loader: ({ params }) => {
      return {
        pageTitle: ['Create']
      }
    }
  }
];
