import React, { useEffect, useRef } from 'react';
import styles from './CreateIntro.module.scss';
import { publicUrl } from '../utils/path';
import ContentContainer from './ContentContainer';
import VideoIntroWelcome from './VideoIntroWelcome';
import Button from './Button';
import CreateHeader from './CreateHeader';
import ScrollToTop from './ScrollToTop';
import { trackEvent } from '../utils/analytics';

function CreateIntro({ next, setRootPadding }) {
  const videoElement = useRef();
  const contentElement = useRef();

  const handleClick = () => {
    next();
  }

  useEffect(() => {
    trackEvent('create', 'intro');
  }, []);

  useEffect(() => {
    function resize() {
      const padding = (videoElement.current.offsetHeight / 2) + 80;
      contentElement.current.style.marginBottom = `-${padding}px`;
      setRootPadding(padding);
    }

    resize();
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
      setRootPadding(0);
    }
  }, [setRootPadding]);

  return (
    <div className={styles.root}>
      <ScrollToTop />
      <ContentContainer type="max">
        <div className={styles.container}>
          <CreateHeader>
            <strong>Step 1/3:</strong> Watch the video for instructions on how to get started!
          </CreateHeader>
          <div className={styles.content} ref={contentElement}>
            <div ref={videoElement}>
              <VideoIntroWelcome
                className={styles.video}
                src={publicUrl() + '/static/routes/create/how-to.mp4'}
                poster={publicUrl() + '/static/routes/create/how-to-poster.jpg'}
                analyticsId="instructions"
              />
            </div>
            <div className={styles.buttons}>
              <Button type="button" onClick={handleClick} icon="next" uppercase={true} id="gtm-create-intro-next">Next step</Button>
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
}

export default React.memo(CreateIntro);