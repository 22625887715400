import styles from './SquishieListEmpty.module.scss';

function SquishieListEmpty() {
  return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <div className={styles.headline}>Oops! you don’t own any of these Squishies yet :(</div>
          <div className={styles.copy}>Go to the “Create” page to add a Squishie to the Squishiedex. Once you have added one from this family they will show up as “Owned”.</div>
        </div>
      </div>
  );
}

export default SquishieListEmpty;