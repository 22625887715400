import React, { Fragment, useState, useRef } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectCreative } from "swiper";
import classNames from "classnames";
import styles from "./BiomeModal.module.scss";
import PropTypes from "prop-types";
import Tooltip from "../components/Tooltip";
import { getFamilyById } from "../utils/collection";
import { publicUrl } from "../utils/path";
import FullModal from '../components/FullModal';
import { playSound } from '../utils/sound';
import { trackEvent } from '../utils/analytics';

const creativeEffect = {
  prev: {
    scale: 0.7,
    opacity: 0,
    translate: ['-50%', 0, 0],
  },
  next: {
    scale: 0.7,
    opacity: 0,
    translate: ['50%', 0, 0],
  },
};

const swiperModules = [Pagination, EffectCreative];

const pagination = {
  horizontalClass: styles.nav__swiperPaginationHorizontal,
  bulletClass: styles.nav__swiperBullet,
  bulletActiveClass: styles.nav__swiperBulletActive,
};

function BiomeModal({ parentPath }) {
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const [biome] = useState((loaderData) ? loaderData.biome : null);
  const [familyActive, setFamilyActive] = useState({});
  const [running, setRunning] = useState(false);
  const swiper = useRef();
  const families = biome.families.map((id) => {
    return getFamilyById(id);
  });
  const [isEnd, setIsEnd] = useState(false);
  const [isStart, setIsStart] = useState(true);

  function handlePrevFamily() {
    swiper.current.slidePrev();
    playSound('site-creature-navigation');

    trackEvent('habitat', 'prev family', biome.slug);
  }

  function handleNextFamily() {
    swiper.current.slideNext();
    playSound('site-creature-navigation');

    trackEvent('habitat', 'next family', biome.slug);
  }

  function handleInitSwiper(instance) {
    swiper.current = instance;
    setFamilyActive(families[0]);
  }

  function handleStartTransition(ev) {
    const familyId = ev?.visibleSlides?.[0]?.getAttribute('data-family-id') || 0;
    const familyFound = families.find(({ id }) => id === Number(familyId));

    setIsStart(ev.isBeginning);
    setIsEnd(ev.isEnd);

    setRunning(true);
    setTimeout(() => {
      setRunning(false);
      setFamilyActive(familyFound);
    }, 400);
  }

  function handleClose() {
      navigate(parentPath, { replace: true });
      playSound('site-click-2');
  }

  // useEffect(() => {
  //   scaleContent(modalBackgroundElement.current, contentElement.current);
  // }, [width, height, contentElement, modalBackgroundElement]);

  return (
      <FullModal close={handleClose}>
        <div className={styles.inner}>
          <Tooltip className={classNames(styles.toolTipBiome, running && styles.toolTipBiomeHide)}>
            {familyActive?.name || ''}
          </Tooltip>
          <div className={styles.biomeCarousel} style={{backgroundImage: `url(${publicUrl()}/${biome.bg.image})`, backgroundColor: biome.bg.color}}>
            <button disabled={isEnd} className={styles.nav__next} onClick={handleNextFamily} title="Next family" id="gtm-habitat-next-family" />
            <Swiper
              modules={swiperModules}
              ref={swiper}
              className={styles.nav__swiper}
              onBeforeInit={handleInitSwiper}
              pagination={pagination}
              loop={true}
              effect="creative"
              followFinger={false}
              shortSwipes={false}
              longSwipes={false}
              speed={500}
              creativeEffect={creativeEffect}
              onSlideChange={handleStartTransition}
            >
              {families.map((family) => {
                return (
                  <Fragment key={`container-${family.id}`}>
                    <SwiperSlide
                      className={styles.nav__swiperSlide}
                      data-family-id={family.id}
                      key={family.id}
                    >
                      <div className={styles.wrapperImage}>
                        <div className={styles.responsiveImage}>
                          <img
                            src={`${publicUrl()}/${family.image}`}
                            alt={family.name}
                            className={styles.imageHabitat}
                            />
                          </div>
                      </div>
                    </SwiperSlide>
                  </Fragment>
                );
              })}
            </Swiper>
            <button disabled={isStart} className={styles.nav__prev} onClick={handlePrevFamily} title="Previous family" id="gtm-habitat-prev-family" />
          </div>
          <div className={styles.textContent}>
            <h1 className={styles.biomeName}>{biome.name}</h1>
            {biome.description.map((p, i) => {
              return (
                <p className={styles.biomeDescription} key={`${biome.id}|${i}`}>
                  {p}
                </p>
              );
            })}
          </div>
        </div>
      </FullModal>
  );
}

BiomeModal.propTypes = {
  parentPath: PropTypes.string.isRequired,
};

export default BiomeModal;
