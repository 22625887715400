import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CreateForm.module.scss';
import ContentContainer from './ContentContainer';
import imageBoxOrange from '../assets/components/CreateForm/image-box-orange.png';
import { getSquishieByCrateId } from '../utils/collection';
import Button from './Button';
import CreateHeader from './CreateHeader';
import ScrollToTop from './ScrollToTop';
import { trackEvent } from '../utils/analytics';
import { playSound } from '../utils/sound';

function CreateForm({ back, next }) {
  const numberField = createRef();
  const [crateId, setCrateId] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    trackEvent('create', 'form');
  }, []);

  function handleBack() {
    back();
    playSound('site-click-1');
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    const squishie = getSquishieByCrateId(crateId);
    playSound('site-click-1');

    if (squishie) {
      next(squishie);
    }
  }

  function handleNumberChange(e) {
    const value = parseInt(String(e.target.value).substring(0, 2));
    const id = parseInt(getSquishieByCrateId(value)?.id);

    setCrateId(value);
    setError(id ? null : "Hmmm we can't find that one! Make sure you enter the number on the side of your Mystery Mold!");
  }

  return (
    <div className={styles.root}>
      <ScrollToTop />
      <ContentContainer type="max">
        <CreateHeader>
          <strong>Step 2/3:</strong> Enter the number from the side of your Mystery Mold.
        </CreateHeader>
        <form onSubmit={handleFormSubmit}>
          <div className={styles.topRow}>
            <img className={styles.boxOrange} alt="Mystery mold" src={imageBoxOrange} />
            <div className={styles.boxBlue}>
              <div className={styles.boxBlueInner}>
                <label className={styles.label} htmlFor="number">Crate ID</label>
                <input className={styles.numberField} type="number" autoComplete="off" min={1} max={99} name="number" ref={numberField} onChange={handleNumberChange} placeholder="00" formNoValidate />
              </div>
            </div>
          </div>
          <div className={styles.bottomRow}>
            <Button className={styles.btnBack} type="button" onClick={handleBack} icon="back" uppercase={true}>Back</Button>
            <Button className={styles.btnNext} type="submit" icon="next" uppercase={true} disabled={!crateId || error} id="gtm-create-form-next">Next step</Button>
          </div>
          <div className={styles.error}>{error}</div>
        </form>
      </ContentContainer>
    </div>
  );
}

CreateForm.propTypes = {
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired
};

export default CreateForm;