import React from "react";
import PropTypes from 'prop-types';
import Button from './Button';
import PromptModal from './PromptModal';

function LeavingModal({data, back}) {
  const isElmers = data.url.match(/^https:\/\/www\.elmers\.com|https:\/\/elmers\.com/) !== null;

  function handleContinue() {
    back();
  }

  return (
      <PromptModal
          title={isElmers ? "You are entering a part of Elmers.com that is for Grown-ups."
              : "You are entering a site that is for Grown-ups."}
          buttons={(
              <>
                <Button type="button" onClick={back} icon="back" uppercase={true}>
                  Back
                </Button>
                <Button type="href" link={data.url} data-ignore-leaving={true} onClick={handleContinue} target={data.target} icon="next"
                        uppercase={true}>
                  Continue
                </Button>
              </>
          )}
          close={back}
          zIndex={10000}
      >
        <p>Not all web sites are safe places for kids! Anytime you aren’t sure about a website, stay safe by going to get a grown-up to help you or clicking the back button.</p>
      </PromptModal>
  )
}

LeavingModal.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
  }).isRequired,
  back: PropTypes.func.isRequired,
};

export default LeavingModal;