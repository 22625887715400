import styles from './SquishieThumbnail.module.scss';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { useSite } from '../contexts/SiteContext';
import PropTypes from 'prop-types';
import { getFamilyById, isSquishieOwned, isSquishieRare } from '../utils/collection';
import classNames from 'classnames';
import unlockedCheck from '../assets/components/SquishieThumbnail/unlocked-check.png';
import SquishieCard from './SquishieCard';

function SquishieThumbnail({ squishie, all, className }) {
  const { collection } = useSite();
  const family = getFamilyById(squishie.family);
  const isRare = isSquishieRare(squishie);
  const isOwned = isSquishieOwned(squishie, collection);

  return (
      <div className={classNames(styles.root, className)} key={squishie.id} data-is-rare={isRare} data-is-owned={isOwned}>
        <NavLink to={`/collect/${(all) ? 'all' : family.slug}/${squishie.slug}`} replace={true}>
            <SquishieCard isOwned={isOwned} squishie={squishie} />
          {isOwned && <div className={styles.unlocked}><img src={unlockedCheck} alt="unlocked" /></div>}
        </NavLink>
      </div>
  )
}

SquishieThumbnail.propTypes = {
  squishie: PropTypes.object.isRequired,
  all: PropTypes.bool,
  className: PropTypes.any
};

SquishieThumbnail.defaultProps = {
  all: false,
  className: []
};

export default React.memo(SquishieThumbnail);
