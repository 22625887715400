import React from "react";
import styles from './WelcomeModal.module.scss';
import logoSquishiesLarge1 from "../assets/components/WelcomeModal/squishies-logo.png";
import VideoIntroWelcome from '../components/VideoIntroWelcome';
import Button from '../components/Button';
import { publicUrl } from '../utils/path';
import PropTypes from 'prop-types';
import mimi from '../assets/components/WelcomeModal/mimi.png';
import thea from '../assets/components/WelcomeModal/thea.png';
import bennett from '../assets/components/WelcomeModal/bennett.png';
import crate from '../assets/components/WelcomeModal/crate.png';
import classNames from 'classnames';

function characters(className) {
  return (
    <div className={classNames(styles.charactersWrapper, className)}>
      <div className={styles.characters}>
        <img src={mimi} className={styles.mimi} alt="Mimi standing on a Mystery Mold"/>
        <img src={thea} className={styles.thea} alt="Thea"/>
        <img src={bennett} className={styles.bennett} alt="Bennett"/>
        <img src={crate} className={styles.crate} alt="Mystery Mold"/>
      </div>
    </div>
  );
}

function WelcomeModal({ enter }) {
  return (
      <>
        <section className={styles.root}>
          <div className={styles.inner}>
            <div className={styles.container}>
              <div className={styles.videoContainer}>
                <div className={styles.video}>
                  <div className={styles.drip} />
                  <img alt="Elmer's Squishies" src={logoSquishiesLarge1} className={styles.logo}/>
                  {characters(styles['charactersWrapper--around'])}
                  <VideoIntroWelcome
                      className={styles.videoPlayer}
                      analyticsId="welcome"
                      src={publicUrl() + '/static/routes/story/video.mp4'}
                      poster={publicUrl() + '/static/routes/story/video-poster.jpg'}
                  />
                </div>
              </div>
              <div className={styles.content}>
                <p className={styles.copy}>
                Step into the world of Squishtopia and explore the amazing collection of Elmer's Squishies! By unlocking the full collection, you'll discover tons of fun games, crafts, and activities!
                </p>
                <Button className={styles.button} type="button" onClick={enter} icon="next-ml">
                  Enter Squishtopia
                </Button>
              </div>
              {characters(styles['charactersWrapper--below'])}
            </div>
          </div>
        </section>
      </>
  )
}

WelcomeModal.propTypes = {
  enter: PropTypes.func.isRequired
}

export default React.memo(WelcomeModal);