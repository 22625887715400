import React, { useEffect, useMemo, useState } from "react";
import Nav from "./Nav";
import styles from "./Header.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import logo from '../assets/components/Header/logo.png';

function Header() {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false)
  },[location.pathname]);
  
  return useMemo(() => {
    return (
      <>
        <NavLink to="/" className={styles.logo} data-show={isOpen}>
          <img src={logo} alt="Elmer's Squishies logo" />
        </NavLink>
        <header className={styles.header} data-open={isOpen}>
          <Nav isOpen={isOpen} setIsOpen={setIsOpen} className={styles.nav} />
        </header>
      </>
    );
  }, [isOpen]);
}

export default Header;
