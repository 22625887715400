import styles from './UnlockPromotion.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function UnlockPromotion({ className }) {
  return (
      <div className={classNames(styles.root, className)}>
        <div className={styles.inner}>
          <div className={styles.headline}>Unlock new activities by making a Squishie!</div>
          <p className={styles.copy}>Go to the “Create” page to add a Squishie to the Squishiedex. This will unlock new activities that will show up here! Pro Tip: Rares give you something extra special!</p>
        </div>
      </div>
  )
}

UnlockPromotion.propTypes = {
  className: PropTypes.any,
};

UnlockPromotion.defaultProps = {
  className: null
};

export default UnlockPromotion;