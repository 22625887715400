import React from 'react';
import styles from './CollectionAll.module.scss';
import {
  filterSquishiesByOwned,
  getBiomeByFamily,
  getFamilies,
  getSquishiesByFamilyId
} from '../utils/collection';
import ContentContainer from '../components/ContentContainer';
import { useOutlet, useOutletContext, useParams } from 'react-router-dom';
import { useSite } from '../contexts/SiteContext';
import SquishieList from '../components/SquishieList';
import DefinitionPill from '../components/DefinitionPill';
import SquishieListEmpty from '../components/SquishieListEmpty';
import { motion, AnimatePresence } from 'framer-motion';
import { defaultMotionProps, modalMotionVariants } from '../utils/motion';

const families = getFamilies();

function CollectionAll() {
  const { showOwned } = useOutletContext();
  const { collection } = useSite();
  const element = useOutlet();
  const { squishieSlug } = useParams();

  return (
      <motion.div className={styles.root} variants={modalMotionVariants} {...defaultMotionProps} >
        <ContentContainer type="max">
          <div className={styles.families}>
            {families.map((family) => {
              const squishies = (showOwned) ? filterSquishiesByOwned(getSquishiesByFamilyId(family.id), collection)
                  : getSquishiesByFamilyId(family.id);

              const biome = getBiomeByFamily(family);

              return (
                  <div key={family.id} className={styles.family}>
                    <div className={styles.family__header}>
                      <h3 className={styles.family__title}>{family.name}</h3>
                      <DefinitionPill label="Habitat" value={biome.name} uppercase={true}/>
                    </div>
                    <ContentContainer type="wide" pageMargins={false}>
                      <div className={styles.family__squishies}>
                        <AnimatePresence mode="wait">
                          {squishies.length > 0 && <SquishieList squishies={squishies} all={true}/>}
                          {squishies.length === 0 && <SquishieListEmpty />}
                        </AnimatePresence>
                      </div>
                    </ContentContainer>
                  </div>
              )
            })}
          </div>
        </ContentContainer>

        <AnimatePresence mode="wait">
          {element && React.cloneElement(element, { key: squishieSlug })}
        </AnimatePresence>
      </motion.div>
  );
}

export default CollectionAll;