import PropTypes from 'prop-types';
import styles from './DefinitionPill.module.scss';
import React from 'react';

function DefinitionPill({label, value, uppercase, theme, relativeSize, icon}) {
  return (
      <div className={styles.root} data-uppercase={uppercase} data-theme={theme} data-icon={!!icon} data-relative-size={relativeSize}>
        <div className={styles.outer}>
          <div className={styles.inner}>
            {icon && <div className={styles.icon}><img src={icon} alt="" /></div>}
            <div className={styles.copy}>
              {label && (
                  <>
                    <div className={styles.label}>{label}</div>
                    <div className={styles.separator}>:</div>
                  </>
              )}
              <div className={styles.value}>{value}</div>
            </div>
          </div>
        </div>
      </div>
  )
}

DefinitionPill.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  uppercase: PropTypes.bool,
  theme: PropTypes.oneOf(['blue']),
  relativeSize: PropTypes.bool,
  icon: PropTypes.string,
};

DefinitionPill.defaultProps = {
  label: null,
  uppercase: false,
  theme: 'blue',
  relativeSize: false,
  icon: null,
};

export default React.memo(DefinitionPill);