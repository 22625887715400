import React from 'react';
import styles from './ContentContainer.module.scss';
import { PropTypes } from 'prop-types';

function ContentContainer({children, type, pageMargins, noMarginOverflow}) {
  return (
      <div
          className={styles.root}
          data-type={type}
          data-page-margins={pageMargins}
          data-no-margin-overflow={noMarginOverflow}
      >{children}</div>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['max', 'wide', 'compact', 'fill']).isRequired,
  pageMargins: PropTypes.bool,
  noMarginOverflow: PropTypes.bool,
};

ContentContainer.defaultProps = {
  pageMargins: true,
  noMarginOverflow: false
};
export default ContentContainer;