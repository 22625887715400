import { cjs } from "./lib/createjs-extended";
import BaseCanvas from "./BaseCanvas";
import { SetFrame } from "./CJSTools";

export default class SpriteAnimation extends cjs.Sprite {
    /**
     * Constructor
     * @param {Object} animDef - animation definition
     */
    constructor (animDef) {
        // initialize null frame just so this exists
        super(BaseCanvas.Cache.get(animDef.sheet), "");
        // determine frame definition type
        this._determineDefFrames(animDef);
        // set actual first frame
        SetFrame(this, this.frames[0], false);
        // properties
        this.targetFPS = animDef.fps;
        this.curFrame = 0;
        this.timeOnFrame = 0;
        this._calcFrameTime();
        this.autoCenter = false;
        this.isPlaying = true;
        this.isLooping = true;
        this.customRegsPercent = null;
        this.customRegsPixel = null;
        // events
        this.onAnimationFinish = null;
    }

    /**
     * Sets new animation for object and starts playing
     * @param {Object} animDef - animation definition, given animation must have same sheet ID
     */
    playAnimation(animDef) {
        this._determineDefFrames(animDef);
        this.targetFPS = animDef.fps;
        this.curFrame = 0;
        this.timeOnFrame = 0;
        this._calcFrameTime();
        this._updateCurFrame();
        this.isPlaying = true;
    }

    /**
     * Set animation to specific frame
     * @param {Number} frame - frame number
     */
    setFrame(frame) {
        if (frame >= 0 && frame < this.frames.length) {
            this.curFrame = frame;
            this.timeOnFrame = 0;
            this._updateCurFrame();
        }
    }

    /**
     * Set animation to frame at percentage in animation
     * @param {Number} value - percentage in animation length
     */
    setFramePercent(value) {
        // clamp and map to range
        value = Math.min(Math.max(0, value), 1);
        this.setFrame(Math.floor(value * (this.frames.length - 1)));
    }

    /**
     * Sets auto center on frame update
     * @param {Bool} state - if center enabled
     */
    setAutoCenter(state) {
        this.autoCenter = state;
        if (state) {
            this.center();
        }
    }

    /**
     * Set custom registration point on frame update, overrides auto center
     * @param {Point} point - offset point
     * @param {Bool} isPercent - if offset is relative percentage rather than pixel values
     */
    setCustomRegs(point, isPercent) {
        if (isPercent) {
            this.customRegsPixel = null;
            this.customRegsPercent = point;
        } else {
            this.customRegsPercent = null;
            this.customRegsPixel = point;
        }
    }

    /**
     * Determine animation definition type and get frames from it
     * @param {Object} animDef - animation definition
     */
    _determineDefFrames(animDef) {
        // determine definition type
        if (typeof animDef.firstFrame !== "undefined" && typeof animDef.lastFrame !== "undefined") {
            // defined range to construct
            this.frames = [];
            const zeroPad = (typeof animDef.zeroPad === "number" ? animDef.zeroPad : 4);
            for (let i = animDef.firstFrame; i <= animDef.lastFrame; ++i) {
                this.frames.push(animDef.prefix + ("0000" + i).slice(-zeroPad));
            }
        } else {
            // static frame list
            this.frames = animDef.frames;
        }
    }

    /** calculate frame length on current FPS */
    _calcFrameTime() {
        this.frameLength = 1000 / this.targetFPS;
    }

    /** updates display to current frame and adjusts position */
    _updateCurFrame() {
        SetFrame(this, this.frames[this.curFrame], this.autoCenter);
        if (this.customRegsPercent) {
            this.regs(this.customRegsPercent.x, this.customRegsPercent.y);
        } else if (this.customRegsPixel) {
            this.set({ regX: this.customRegsPixel.x, regY: this.customRegsPixel.y });
        }
    }

    /**
     * Update sprite with tick event
     * @param {Event} event - tick event
     */
    _tick(event) {
        // reject tick events without functional delta
        if (!event.delta) return;
        // update frame display
        if (this.isPlaying) {
            this.timeOnFrame += event.delta;
            if (this.timeOnFrame >= this.frameLength) {
                this.timeOnFrame = 0;
                ++this.curFrame;
                // check end of animation
                if (this.curFrame >= this.frames.length) {
                    if (this.isLooping) {
                        // loop to beginning
                        this.curFrame = 0;
                    } else {
                        // stop playing if not looping
                        this.isPlaying = false;
                        this.curFrame = this.frames.length - 1;
                    }
                    // check event
                    if (this.onAnimationFinish) {
                        this.onAnimationFinish(this);
                    }
                }
                // update frame visual
                this._updateCurFrame();
            }
        }
    }
}