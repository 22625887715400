import styles from './ModalBackground.module.scss';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { forwardRef } from 'react';

// function focusableElements(element) {
//     const query = [
//         'a[href]:not([tabindex="-1"])',
//         'area[href]:not([tabindex="-1"])',
//         'input:not([disabled]):not([tabindex="-1"])',
//         'select:not([disabled]):not([tabindex="-1"])',
//         'textarea:not([disabled]):not([tabindex="-1"])',
//         'button:not([disabled]):not([tabindex="-1"])',
//         'video:not([tabindex="-1"])',
//         '[tabindex="0"]'
//     ];
//
//     const elements = Array.from(element.querySelectorAll(query.join(',')));
//
//     return elements.filter((el) => {
//       return window.getComputedStyle(el, null).display !== 'none';
//     });
// }

const ModalBackground = forwardRef(function ModalBackground({ className, children, close, accessible, style }, ref) {
  // const [previousFocus, setPreviousFocus] = useState();
  //
  // useEffect(() => {
  //   if (!accessible) return () => {};
  //   setPreviousFocus(document.activeElement);
  //
  //   return () => {
  //     if (previousFocus) {
  //       previousFocus.focus();
  //     }
  //   }
  // }, [accessible, previousFocus]);

  // useEffect(() => {
  //   if (!accessible) return () => {};
  //
  //   function keyListener(e) {
  //     if (e.keyCode === 27) {
  //       close();
  //     }
  //   }
  //
  //   document.addEventListener("keydown", keyListener);
  //
  //   return () => {
  //     document.removeEventListener('keydown', keyListener);
  //   }
  // }, [close, accessible])

  // useEffect(() => {
  //   if (!ref?.current) return;
  //   if (!accessible) return () => {};
  //
  //   function keyListener(e) {
  //     if(e.keyCode === 9) {
  //       const elements = focusableElements(ref.current);
  //
  //       if (elements.length <= 1) {
  //         e.preventDefault();
  //         return;
  //       }
  //
  //       if (e.shiftKey) {
  //         if (elements[0] === document.activeElement) {
  //           e.preventDefault();
  //           elements[elements.length - 1].focus();
  //         }
  //       } else {
  //         if (elements[elements.length - 1] === document.activeElement) {
  //           e.preventDefault();
  //           elements[0].focus();
  //         }
  //       }
  //     }
  //   }
  //
  //   const elements = focusableElements(ref.current);
  //   if (elements.length > 0) {
  //     elements[0].focus();
  //   }
  //
  //   document.addEventListener('keydown', keyListener);
  //
  //   return () => {
  //       document.removeEventListener('keydown', keyListener);
  //   }
  // }, [ref, accessible]);

  function handleClick(e) {
    if(e.target === ref.current) {
      close();
    }
  }

  return (
      <div className={classNames(styles.root, className)} style={style} ref={ref} onClick={handleClick}>{children}</div>
  )
});

ModalBackground.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  close: PropTypes.func,
  accessible: PropTypes.bool,
  styles: PropTypes.any,
};

ModalBackground.defaultProps = {
  className: null,
  children: null,
  close: () => {},
  accessible: true,
  style: null
}

export default ModalBackground;