import { useMemo } from 'react';
import styles from './Create.module.scss';
import classNames from 'classnames';
import { useSite } from '../contexts/SiteContext';
import CreateForm from '../components/CreateForm';
import CreateBuild from '../components/CreateBuild';
import CreateIntro from '../components/CreateIntro';
import { useEffect, useState } from 'react';
import ContentContainer from '../components/ContentContainer';
import ActivityList from '../components/ActivityList';
import { getActivitiesByFamily, getFamilyById, getSquishieById, isSquishieRare } from '../utils/collection';
import { playSound } from '../utils/sound';

function Create() {
  const {
    queue,
    openQueue,
    initQueue,
    startQueue,
    clearQueue,
    completeQueue
  } = useSite();
  const [page, setPage] = useState('intro');
  const [rootPadding, setRootPadding] = useState(0);

  const squishie = useMemo(() => {
    return getSquishieById(queue.id) ?? {};
  }, [queue.id]);

  const family = useMemo(() => {
    return getFamilyById(squishie.family) ?? {};
  }, [squishie.family]);

  const activities = useMemo(() => {
    return family.id ? getActivitiesByFamily(family) : [];
  }, [family]);

  useEffect(() => {
    if (queue.id) {
      setPage('build');
    }
  }, [queue, setPage]);

  function handleIntroNext() {
    setPage('form');
    playSound('site-click-1');
  }

  function handleFormBack() {
    setPage('intro');
    playSound('site-click-1');
  }

  function handleFormNext(squishie) {
    initQueue(squishie.id);
    playSound('site-click-1');
  }

  function handleBuildBack() {
    clearQueue();
    setPage('form');
    playSound('site-click-1');
  }

  function handleBuildStart() {
    startQueue(Date.now() + parseInt(process.env.REACT_APP_CREATE_DURATION));
    playSound('site-timer-start');
  }

  function handleBuildFinish() {
    completeQueue();
    playSound('site-click-1');
  }

  function handleBuildReset() {
    clearQueue();
    setPage('intro');
  }

  function handleBuildOpen() {
    openQueue();
  }

  // Necessary for intro in order to have white wave appear half under the video player
  const rootStyle = useMemo(() => {
    return rootPadding ? {paddingBottom: rootPadding + 60} : {paddingBottom: (page === 'build') ? 0 : 60 };
  }, [rootPadding, page]);

  return (
      <div className={classNames(styles.root, 'header-offset-fill')} style={rootStyle}>
        <div className={styles.page}>
          <div className={styles.content}>
            {page === 'intro' && <CreateIntro next={handleIntroNext} setRootPadding={setRootPadding}/>}
            {page === 'form' && <CreateForm back={handleFormBack} next={handleFormNext}/>}
            {page === 'build' && <CreateBuild
                back={handleBuildBack}
                start={handleBuildStart}
                finish={handleBuildFinish}
                reset={handleBuildReset}
                queue={queue}
                open={handleBuildOpen}
            />}
          </div>
          {page === 'build' && (
              <div className={styles.activities}>
                <ContentContainer type="fill">
                  <h3 className={styles.activities__header}>Activities!</h3>
                </ContentContainer>
                <div className={styles.activities__content}>
                  <ContentContainer type="compact">
                    <ActivityList
                        activities={activities}
                        unlockFamily={true}
                        offsetRare={isSquishieRare(squishie) ? 1 : 0}
                        className={styles.activities__list}
                        lockedMessage={2}
                    />
                  </ContentContainer>
                </div>
              </div>
          )}
        </div>
      </div>
  );
}

export default Create;