import React from "react";
import styles from "./ButtonNav.module.scss";
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';

function ButtonNav({ to, children, Icon, isActiveOverride, onMouseEnter, onClick }) {
  const linkExclude = ['http', 'https', '.com']
  const LinkType = (link) => {
    if (linkExclude.some((e) => link.includes(e))) {
      return (
        <a href={link} target="_blank" rel="noreferrer" className={styles.container} data-active="false" onMouseEnter={onMouseEnter} onClick={onClick}>
          <div className={styles.content}>
            <span className={styles.icon}>
              <Icon />
            </span>
            <span className={styles.text}>{children}</span>
          </div>
        </a>
      )
    } else {
      return (
        <NavLink to={to} className={styles.root} onMouseEnter={onMouseEnter} onClick={onClick}>
          {({ isActive }) => {
            return (
              <>
                <div className={styles.container} data-active={isActiveOverride(isActive)}>
                  <div className={styles.content}>
                    <span className={styles.icon}>
                      <Icon />
                    </span>
                    <span className={styles.text}>{children}</span>
                  </div>
                </div>
              </>
            );
          }}
        </NavLink>
      )
    }
  }
  return (
    <>
      {LinkType(to)}
    </>
  );
}

ButtonNav.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  Icon: PropTypes.any.isRequired,
  isActiveOverride: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onClick: PropTypes.func,
};

ButtonNav.defaultProps = {
  isActiveOverride: (isActive) => isActive,
  onMouseEnter: () => {},
  onClick: () => {}
};

export default ButtonNav;
