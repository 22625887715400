import ModalBackground from './ModalBackground';
import ModalWindow from './ModalWindow';
import styles from './PrompModal.module.scss';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { ReactComponent as CloseIcon } from "../assets/x.svg";
import classNames from 'classnames';
import { defaultMotionProps, modalMotionVariants } from '../utils/motion';
import { motion } from 'framer-motion';

function PromptModal({children, zIndex, title, header, close, buttons, buttonsClassName}) {
  const modalBackgroundElement = useRef();

  return (
      <motion.div
          {...defaultMotionProps}
          variants={modalMotionVariants}
          className={styles.root}
          style={{zIndex: zIndex}}
      >
        <ModalBackground ref={modalBackgroundElement} close={close}>
          {close && (
              <button className={classNames(styles.close, styles['close--overlay'])} onClick={close}>
                <CloseIcon/>
              </button>
          )}
          <ModalWindow grow close={close}>
            <div className={styles.window}>
              {close && (
                  <button className={classNames(styles.close, styles['close--window'])} onClick={close}>
                    <CloseIcon/>
                  </button>
              )}
              <div className={styles.content}>
                {header && (<div className={styles.header}>{header}</div>)}
                <div className={styles.title}>{title}</div>
                {children && (
                    <div className={styles.description}>
                      {children}
                    </div>
                )}
                {buttons && (
                    <div className={classNames(styles.buttons, buttonsClassName)}>
                      {buttons}
                    </div>
                )}
              </div>
            </div>
          </ModalWindow>
        </ModalBackground>
      </motion.div>
  );
}

PromptModal.propTypes = {
  children: PropTypes.any.isRequired,
  zIndex: PropTypes.number,
  title: PropTypes.any.isRequired,
  close: PropTypes.func,
  buttons: PropTypes.any,
  header: PropTypes.any,
  buttonsClassName: PropTypes.any,
};

PromptModal.defaultProps = {
  zIndex: 9000,
  header: null,
  close: null,
  children: null,
  buttons: null,
  buttonsClassName: null
};

export default PromptModal;