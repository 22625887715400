import styles from './FullModal.module.scss'
import { useWindowSize } from '@react-hook/window-size';
import React, { useEffect, useRef } from 'react';
import { defaultMotionProps, modalMotionVariants, modalWindowMotionVariants } from '../utils/motion';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from "../assets/x.svg";
import { scaleContent } from '../utils/accessibility';
import ModalBackground from '../components/ModalBackground';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

function FullModal({ close, children, zIndex }) {
  const [width, height] = useWindowSize();
  const modalBackgroundElement = useRef();
  const contentElement = useRef();

  useEffect(() => {
    scaleContent(modalBackgroundElement.current, contentElement.current);
  }, [width, height, modalBackgroundElement, contentElement]);

  return (
    <motion.div variants={modalMotionVariants} className={styles.root} {...defaultMotionProps} style={{zIndex: zIndex}}>
      <ModalBackground className={styles.background} ref={modalBackgroundElement} close={close}>
        <div className={styles.content} ref={contentElement}>
          <motion.div {...modalWindowMotionVariants} onAnimationEnd={ () => {} }>
            {children}
            {close && (
                <button className={classNames(styles.close, styles['close--content'])} onClick={close}>
                  <CloseIcon/>
                </button>
            )}
          </motion.div>
        </div>
        {close && (
            <button className={classNames(styles.close, styles['close--overlay'])} onClick={close}>
              <CloseIcon/>
            </button>
        )}
      </ModalBackground>
    </motion.div>
  )
}

FullModal.propTypes = {
  close: PropTypes.func,
  children: PropTypes.any,
  zIndex: PropTypes.number,
};

FullModal.defaultProps = {
  close: null,
  children: null,
  zIndex: 9000
}

export default FullModal;